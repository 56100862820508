import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../shared/appUIFramework/appBackend/useCompany';
import { bindHardware, IConnectDeviceModel } from '../../../shared/appUIFramework/appBackend/useHardwareSummary';
import { closePopup, showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import LoadingBubbles from '../../../assets/loading-bubbles.svg';
import './ConnectDevicePopup.scss';
import AppSystemErrorPopup from '../../../shared/appUIFramework/appGenericErrorPopup/AppSystemErrorPopup';

export default function ConnectDevicePopup({ siteId }: { siteId: string }) {
  const { t } = useTranslation();
  const companyInfo = useCompany();
  const { register, handleSubmit, formState: { errors, isSubmitting, isValid } } = useForm<IConnectDeviceModel>({
    mode: 'onChange',
    defaultValues: {
      siteGuid: siteId,
    } as Partial<IConnectDeviceModel>,
  });
  const closeWithTrue = () => closePopup(true);
  const closeWithFalse = () => closePopup(false);

  const connectDevice = async (data: IConnectDeviceModel) => {
    try {
      const result = await bindHardware({
        ...data,
        companyId: companyInfo?.id!,
      });
      if (result === 'success') {
        closeWithTrue();
      } else {
        closeWithFalse();
        await showPopup(<AppSystemErrorPopup />);
      }
    } catch {
      closeWithFalse();
      await showPopup(<AppSystemErrorPopup />);
    }
  };

  return (
    <AppPopupLayout
      headerTitle="SiteAdd_ConnectToSite"
      extraClass="app-popup-connect"
      popupBody={(
        <>
          {isSubmitting && (
            <div className="app-connect-device-popup-loading-wrapper">
              <img
                className="app-show-loading-image"
                src={LoadingBubbles}
                alt="Loading..."
              />
              <span className="app-font-20 app-uppercase app-weight-600 app-text-align-center app-pt-10">
                {t('Popup_SystemConnecting')}
                <br />
                {t('ThisCanTake60Seconds')}
              </span>
            </div>
          )}
          <div className={`app-popup-info app-pt-30 ${isSubmitting ? 'app-invisible' : ''}`}>
            {t('SiteRegistrationDialog_Content')}
          </div>
          <form onSubmit={handleSubmit(connectDevice)} className="app-popup-form">
            <div className={`app-popup-form-controls  ${isSubmitting ? 'app-invisible' : ''}`}>
              <div className="app-popup-form-control" aria-invalid={!!errors.panelSerial}>
                <div className="app-popup-form-control-label">
                  <span>{t('Table_SerialNumber')}</span>
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    {...register('panelSerial', { required: true })}
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
                {errors.panelSerial && (
                  <span
                    className="app-popup-form-error"
                  >
                    {t('SiteRegistrationDialog_SerialNumberRequired')}
                  </span>
                )}
              </div>
              <div className="app-popup-form-control" aria-invalid={!!errors.engineerCode}>
                <div className="app-popup-form-control-label">
                  <span>{t('SiteRegistrationDialog_ActivationCode')}</span>
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    {...register('engineerCode', { required: true })}
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
                {errors.engineerCode && (
                  <span
                    className="app-popup-form-error"
                  >
                    {t('SiteRegistrationDialog_ActivationCodeRequired')}
                  </span>
                )}
              </div>
            </div>
            <div className="app-popup-buttons">
              <button
                type="button"
                onClick={closeWithFalse}
                className={`app-secondary-button app-popup-left-button ${isSubmitting ? 'app-invisible' : ''}`}
              >
                {t('Form_Cancel')}
              </button>
              <button
                type="submit"
                disabled={!isValid || isSubmitting}
                className={`app-primary-button app-popup-right-button ${isSubmitting ? 'app-invisible' : ''}`}
              >
                {t('SiteAdd_Connect')}
              </button>
            </div>
          </form>
        </>
      )}
    />
  );
}
