export enum AdminUserRoleNumbers {
  PaymentAdmin = 10,
  SystemAdmin = 11,
  SiteAdmin = 12,
}

export enum AdminUserRole {
  PaymentAdmin = 'PaymentAdmin',
  SystemAdmin = 'SystemAdmin',
  SiteAdmin = 'SiteAdmin',
}

export interface ISystemAdminInfo {
  systemId: string;
  systemName: string;
}

export interface IProfile {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  createDate?: string;
  userRole: AdminUserRole;
  systems?: ISystemAdminInfo[]
  termsAndConditionsAcceptedAt?: string;
  companyId?: string;
  internalAppsCustomerReference?: string;
  branchOfficeId?: string;
  locale?: string;
  impersonationTriggeredById?: string;
}

export function getUserRoleTranslationKey(role: AdminUserRole): string {
  const map: Record<AdminUserRole, string> = {
    [AdminUserRole.PaymentAdmin]: 'PaymentAdmin',
    [AdminUserRole.SystemAdmin]: 'SystemAdmin',
    [AdminUserRole.SiteAdmin]: 'SiteAdmin',
  };

  return map[role] || '';
}
