import { useTranslation } from 'react-i18next';
import { ReactComponent as DeletePersonIcon } from '../../assets/icons/Delete Person.svg';
import { closePopup } from '../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';

export default function ProfileDeleteConfirmationPopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="Profile_DeleteUser"
      popupBody={(
        <>
          <DeletePersonIcon className="app-w-86 app-h-163" />
          <span className="app-popup-info">{t('Popup_AreYouSureYouWishToDeleteUser')}</span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
          <button type="button" onClick={confirm} className="app-warn-button app-popup-right-button">{t('Table_Delete')}</button>
        </>
      )}
    />
  );
}
