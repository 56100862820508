import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { B2CLangCode } from '../../constants/B2CLangCode';

export default function BaseNotificationContent({
  title,
  description,
  linkPrefix,
  linkText,
  createdAt,
}:{
  title: string,
  description: string,
  linkPrefix?: string,
  linkText?: string,
  createdAt: string,
}) {
  const { i18n } = useTranslation();
  const locale = i18n.language as B2CLangCode;
  const formattedCreatedAt = DateTime.fromISO(createdAt)
    .setLocale(locale)
    .toFormat('LLLL dd');

  return (
    <div>
      <div className="app-notification-title">{ title }</div>
      <div className="app-notification-description">
        <span>{description}</span>
        {!linkPrefix && linkText && (
          <span>
            <span>
              .&nbsp;
            </span>
            <span className="app-notification-link">
              {linkText}
            </span>
          </span>
        )}
      </div>
      {linkPrefix && (
      <div>
        <span className="app-uppercase">
          {linkPrefix}
          .&nbsp;
        </span>
        {linkText && <span className="app-notification-link">{linkText}</span>}
      </div>
      )}
      <div className="app-notification-created-at">{formattedCreatedAt}</div>
    </div>
  );
}
