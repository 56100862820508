import {
  MouseEvent, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { useAccountActivity } from '../../shared/appUIFramework/appBackend/useAccountActivity';
import { useAccountCharges, useCustomerPricing } from '../../shared/appUIFramework/appBackend/useAccountCharges';
import {
  fillNotImplementedValues,
  getInvoice,
  getInvoiceStatusOptionsForUrlParameter,
  IInvoice,
  IInvoiceFilter,
  InvoicePaymentMethodFilterOptions,
  InvoicePaymentMethodFilterOptionsType,
  InvoiceStatusFilterOptions,
  InvoiceStatusFilterOptionsType,
  useInvoicePaymentMethodOptions,
  useInvoiceStatusOptions,
  useInvoicesTotalNumber,
} from '../../shared/appUIFramework/appBackend/useInvoices';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import AppTablePagination from '../../shared/appUIFramework/components/AppTablePagination/AppTablePagination';
import AccountActivityBarchart, { BarColor } from '../home/components/AccountActivityBarchart';
import PaymentHeader from './components/PaymentHeader';
import styles from './PaymentOverview.module.scss';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download icon.svg';
import { getInvoiceStatusColorClassName, useFetchAndDownloadInvoice } from './invoice.helper';
import { handleHttpRequestError } from '../../shared/appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';
import { togglePaymentScrollClass, useAddPaddingLeftWhenScrollIsVisible } from '../../styles';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { DateFormats, useFormatDate } from '../../shared/appUIFramework/formatters/formatDate';
import { AppFilterIcon } from '../../shared/appUIFramework/components/SortAndFilterIcons';
import { IGridQueryParams } from '../systems/models/IGridQueryParams';
import { getTotalPages } from '../../shared/appUIFramework/dataTransformers/getTotalPages';
import { useClaims } from '../../shared/backend/auth/claims';
import { AccountCharges } from '../../shared/appUIFramework/components/AppAccountChargers';
import { Costs } from '../../shared/appUIFramework/components/AppCosts';
import { Urls } from '../../shared/backend/urls';
import { getUrlWithQueryParams } from '../../shared/backend/http/http';
import { PAGE_SIZE, useInfiniteWithPagination } from '../../shared/appUIFramework/hooks/useInfiniteWithPagination';
import { isNotRegisteredInstaller, isRegisteredInstaller, useInternalAppsCompanyDetails } from '../../shared/appUIFramework/appBackend/useKeyContact';
import VatRegisteredResellerCosts from '../../shared/appUIFramework/components/VatRegisteredResellerCosts';
import { InstallerStatusTranslationKeys } from '../admin/AdminCompanyDetails';
import { useAppTableState } from '../../shared/appUIFramework/components/AppTablePage';

export function getListOfYearsFromDate(startYear: number) {
  const numberOfYearsFromStart = new Date().getFullYear() - startYear;
  const currentYear = new Date().getFullYear();
  const years = [currentYear.toString()];
  if (numberOfYearsFromStart > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= numberOfYearsFromStart; i++) {
      const nextYear = currentYear - i;
      years.push(nextYear.toString());
    }
  }

  return years;
}

function AccountActivity({
  activity, startYear, year, setYear,
}: { activity: number[], startYear: number, year: string, setYear: (value: string) => void }) {
  const { t } = useTranslation();
  const accountActivityPerYearOptions = getListOfYearsFromDate(startYear);

  return (
    <div className={`app-paper ${styles.activityPaper}`}>
      <div className="app-d-flex app-justify-content-between app-align-items-center">
        <div className="app-paper-title">
          {t('Home_AccountActivity')}
        </div>
        <div className="app-d-flex app-justify-content-between">
          <AppPopupMenu
            options={accountActivityPerYearOptions}
            selectedOption={year}
            onOptionSelected={(option) => setYear(option)}
            render={() => (
              <div className={`${styles.activitySelect} app-d-flex app-align-items-center`}>
                <span className="app-mr-9">{year}</span>
                <ExpandIcon />
              </div>
            )}
          />
        </div>
      </div>
      <AccountActivityBarchart
        title={t('Home_AccountActivity_Systems')}
        barColor={BarColor.Blue}
        data={activity}
      />
    </div>
  );
}

function Invoices() {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState<IInvoiceFilter & IGridQueryParams>({
    paidByMethod: InvoicePaymentMethodFilterOptions.All,
    invoiceStatusIds: InvoiceStatusFilterOptions.All,
  });

  const queryParamsParsed = {
    ...queryParams,
    paidByMethod: queryParams.paidByMethod === InvoicePaymentMethodFilterOptions.All ? undefined : queryParams.paidByMethod,
    invoiceStatusIds: queryParams.invoiceStatusIds === InvoiceStatusFilterOptions.All ? undefined : queryParams.invoiceStatusIds,
  };

  const invoicesRowsRef = useRef<HTMLDivElement | null>(null);

  const { customerReference } = useClaims();
  const invoicesUrl = getUrlWithQueryParams(Urls.Invoices(customerReference), {
    ...queryParamsParsed,
    invoiceStatusIds: getInvoiceStatusOptionsForUrlParameter(queryParamsParsed.invoiceStatusIds),
  });
  const invoicesTotalNumber = useInvoicesTotalNumber(queryParamsParsed);
  const totalPages = getTotalPages(PAGE_SIZE, invoicesTotalNumber);
  const {
    items: invoicesReal, reset, page, setPage,
  } = useInfiniteWithPagination<IInvoice>(invoicesUrl, totalPages, {
    scrollRef: invoicesRowsRef,
  });
  const invoices = invoicesReal?.map(fillNotImplementedValues()) || [];

  const { options: statuses, getOptionLabel: getStatusLabel } = useInvoiceStatusOptions();
  const { options: types, getOptionLabel: getTypeLabel } = useInvoicePaymentMethodOptions();
  const [, setLocation] = useLocation();
  const dateColRef = useRef<HTMLDivElement>(null);
  const invoicesHeaderRowRef = useRef<HTMLDivElement | null>(null);
  const scrollStylesApplied = useAddPaddingLeftWhenScrollIsVisible(invoicesRowsRef, invoices != null);
  useEffect(() => {
    if (!invoicesHeaderRowRef.current) {
      return;
    }

    togglePaymentScrollClass(scrollStylesApplied, invoicesHeaderRowRef.current);
  }, [scrollStylesApplied]);

  const { paidByMethod, invoiceStatusIds } = queryParams;
  const setType = (newType: InvoicePaymentMethodFilterOptionsType) => {
    reset();
    setQueryParams({ ...queryParams, paidByMethod: newType });
  };
  const setStatus = (newStatus: InvoiceStatusFilterOptionsType) => {
    reset();
    setQueryParams({ ...queryParams, invoiceStatusIds: newStatus });
  };

  const fetchAndDownloadInvoice = useFetchAndDownloadInvoice(async (invoiceId) => {
    try {
      return getInvoice(customerReference, invoiceId);
    } catch (error) {
      await handleHttpRequestError(error);
      return null;
    }
  });

  const downloadPdf = async (invoiceId: number, e: MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    await fetchAndDownloadInvoice(invoiceId);
  };

  function goToInvoice(invoiceId: number) {
    setLocation(`/payment/overview/${invoiceId}`);
  }

  const formatDate = useFormatDate();

  useAppTableState('invoices', queryParams, queryParams, setQueryParams);

  return (

    <div className={styles.invoiceContainer}>
      <div className={styles.invoiceHeader}>
        <div>
          {t('Invoices')}
        </div>
      </div>
      <div className="app-table">
        <div ref={invoicesHeaderRowRef} className={`app-table-header-row app-justify-content-between ${styles.invoiceTableHeader}`}>
          <div>
            {t('InvoiceNo')}
          </div>
          <div ref={dateColRef}>
            {t('Date')}
          </div>
          <div>
            {t('Total')}
          </div>
          <div className={`app-table-header-row-with-sort ${styles.typeColumn}`}>
            <span className="app-mr-9 app-nowrap">
              {t('Type')}
            </span>
            <AppPopupMenu
              selectedOption={paidByMethod}
              onOptionSelected={(option) => setType(option)}
              options={types}
              getOptionLabel={getTypeLabel}
              render={() => (<AppFilterIcon applied={paidByMethod !== InvoicePaymentMethodFilterOptions.All} />)}
            />
          </div>
          <div className="app-table-header-row-with-sort">
            <span className="app-mr-9 app-nowrap">
              {t('Status')}
            </span>
            <AppPopupMenu
              selectedOption={invoiceStatusIds}
              onOptionSelected={(option) => setStatus(option)}
              options={statuses}
              getOptionLabel={getStatusLabel}
              render={() => (<AppFilterIcon applied={invoiceStatusIds !== InvoiceStatusFilterOptions.All} />)}
            />
          </div>
          <div />
        </div>
        <div ref={invoicesRowsRef} className={`app-flex-vertical-scrollable ${styles.invoicesList}`}>
          {invoices && invoices.map((invoice) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
            <div
              onClick={() => goToInvoice(invoice.invoiceId)}
              role="row"
              className={`app-table-content-row app-d-flex app-align-items-center app-justify-content-between ${styles.invoice}`}
              key={invoice.invoiceId}
            >
              <div className="app-ellipsis">{invoice.invoiceId}</div>
              <div className="app-table-content-cell">
                {formatDate(invoice.invoiceDate, DateFormats.LongDate)}
              </div>
              <div className="app-table-content-cell-mobile">
                {formatDate(invoice.invoiceDate, DateFormats.LongDateMobile)}
              </div>
              <div>{formatMoney(invoice.currencyCodeId, invoice.Totals.Subtotal)}</div>
              <div className={`app-uppercase ${styles.typeColumn}`}>{getTypeLabel(invoice.InvoicePaymentMethodId)}</div>
              <div className={`app-uppercase app-weight-600 app-payment-overview-status-text ${getInvoiceStatusColorClassName(invoice.invoiceStatusId)}`}>
                {getStatusLabel(invoice.invoiceStatusId)}
              </div>
              <div><DownloadIcon onClick={(e) => downloadPdf(invoice.invoiceId, e)} /></div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.paginationContainer}>
        <div
          className="app-w-50pcnt app-mt-26 app-d-flex app-align-items-center app-justify-content-center"
        >
          <AppTablePagination
            activePage={page}
            totalPages={totalPages}
            onPageSelect={setPage}
          />
        </div>
      </div>
    </div>
  );
}

export default function PaymentOverview() {
  const currentYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(currentYear);
  const { activity, startYear } = useAccountActivity(year, 'sites');
  const { charges } = useAccountCharges();
  const { costs } = useCustomerPricing();
  const { data: internalAppsCompany } = useInternalAppsCompanyDetails();

  const isLoading = !activity || !charges;

  return (
    <>
      <PaymentHeader activeTab="overview" />
      <div className="app-content app-py-42 app-px-60">
        <AppShowLoading showLoading={isLoading}>
          <div className={styles.outerContainer}>
            <div className={styles.layoutContainer}>
              <div className={styles.layoutContainerItem}>
                <div className={styles.chartsContainer}>
                  <div className="app-paper app-flex-1">
                    <AccountCharges
                      accountCharges={charges!}
                      circleTopKey="AccountCharges"
                      circleBottomKey="Home_EstimatedNextBill_A_Month"
                      headerKey="Home_EstimatedNextBill"
                      showGrowth
                    />
                  </div>
                  <div className="app-paper app-flex-1">
                    {internalAppsCompany
                      && isNotRegisteredInstaller(internalAppsCompany)
                      && <Costs currencyCode={costs?.currency} value={costs?.value || 0} headerKey="ViewSiteCosts_Title" footerKey="PerAppUser" />}
                    {internalAppsCompany
                      && isRegisteredInstaller(internalAppsCompany)
                      && (
                        <VatRegisteredResellerCosts
                          rrp={costs?.rrp || 0}
                          discountPercent={internalAppsCompany.loyaltyDiscount * 100}
                          partnerTranslationKey={InstallerStatusTranslationKeys[internalAppsCompany.installerLoyaltyTier]}
                          currencyCode={costs?.currency}
                          costs={costs?.value || 0}
                        />
                      )}
                  </div>
                </div>
                <AccountActivity startYear={startYear} activity={activity!} year={year} setYear={setYear} />
              </div>
              <div className={styles.layoutContainerItem}>
                <Invoices />
              </div>
            </div>
          </div>
        </AppShowLoading>
      </div>
    </>
  );
}
