import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function DeleteSiteProcessingPopup() {
  return (
    <AppPopupLayout
      headerTitle="DeleteSystem_DeleteASystem"
      extraClass="app-popup-connecting"
      popupBody={(
        <>
          <span className="app-mt-22" />
          <AppShowLoading showLoading inline />
        </>
      )}
    />
  );
}
