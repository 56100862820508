import { UserPermission } from './userPermission';

export const CanNavigateToHomePermissions = [
  UserPermission.CanViewNextEstimatedBill,
  UserPermission.CanViewTotalDevices,
  UserPermission.CanViewTotalSites,
];

export const CanNavigateToSitesPermissions = [
  UserPermission.CanViewSites,
  UserPermission.CanViewSitesForSiteSubset,
];

export const CanNavigateToCreateSitePermissions = [
  UserPermission.CanCreateSite,
];

export const CanNavigateToEditSitePermissions = [
  UserPermission.CanEditSite,
  UserPermission.CanEditSiteForSiteSubset,
];

export const CanNavigateToDeleteSitePermissions = [
  UserPermission.CanDeleteSite,
];

export const CanNavigateToEntryUsersPermissions = [
  UserPermission.CanViewEntryUsers,
  UserPermission.CanViewEntryUsersForSiteSubset,
  UserPermission.CanViewEntryUsersObfuscated,
];

export const CanNavigateToCreateEntryUserPermissions = [
  UserPermission.CanCreateEntryUser,
  UserPermission.CanCreateEntryUserForSiteSubset,
];

export const CanNavigateToEditEntryUserPermissions = [
  UserPermission.CanEditEntryUser,
  UserPermission.CanEditEntryUserForSiteSubset,
];

export const CanNavigateToDeleteEntryUserPermissions = [
  UserPermission.CanDeleteEntryUser,
  UserPermission.CanDeleteEntryUserForSiteSubset,
];

export const CanBoundHardware = [
  UserPermission.CanBoundHardware,
  UserPermission.CanBoundHardwareForSiteSubset,
];

export const CanNavigateToHardwarePermissions = [
  UserPermission.CanViewHardware,
  UserPermission.CanViewHardwareForSiteSubset,
  ...CanBoundHardware,
];

export const CanNavigateToSiteCostsPermissions = [
  UserPermission.CanViewSiteCosts,
  UserPermission.CanViewSiteCostsForSiteSubset,
];

export const CanEditSiteDetailsPermissions = [
  UserPermission.CanEditSiteDetails,
  UserPermission.CanEditSiteDetailsForSiteSubset,
];

export const CanNavigateToSiteDetailsPermissions = [
  UserPermission.CanViewSiteDetails,
  UserPermission.CanViewSiteDetailsForSiteSubset,
  ...CanEditSiteDetailsPermissions,
];

export const CanNavigateToSitePermissions = [
  ...CanNavigateToCreateSitePermissions,
  ...CanNavigateToEditSitePermissions,
  ...CanNavigateToDeleteSitePermissions,
  ...CanNavigateToEntryUsersPermissions,
  ...CanNavigateToCreateEntryUserPermissions,
  ...CanNavigateToEditEntryUserPermissions,
  ...CanNavigateToDeleteEntryUserPermissions,
  ...CanNavigateToHardwarePermissions,
  ...CanNavigateToSiteCostsPermissions,
  ...CanNavigateToSiteDetailsPermissions,
];

export const CanNavigateToPaymentPermissions = [
  UserPermission.CanViewPaymentOverview,
  UserPermission.CanMakePayments,
  UserPermission.CanViewTermsAndConditions,
];

export const CanNavigateToPaymentOverviewPermissions = [
  UserPermission.CanViewPaymentOverview,
];

export const CanNavigateToMakePaymentPermissions = [
  UserPermission.CanMakePayments,
];

export const CanNavigateToAdminPermissions = [
  UserPermission.CanViewAdmins,
  UserPermission.CanViewCompanyDetails,
  UserPermission.CanViewAdminsOfLowerRoles,
];

export const CanNavigateToCreateAdminPermissions = [
  UserPermission.CanCreateAdmins,
  UserPermission.CanCreateAdminsOfLowerRoles,
];

export const CanNavigateToEditAdminPermissions = [
  UserPermission.CanEditAdmins,
  UserPermission.CanEditAdminsOfLowerRoles,
];

export const CanNavigateToDeleteAdminPermissions = [
  UserPermission.CanDeleteAdmins,
  UserPermission.CanDeleteAdminsOfLowerRoles,
];

export const CanNavigateToCompanyDetailsPermissions = [
  UserPermission.CanViewCompanyDetails,
];

export const CanViewAdminDocuments = [...CanNavigateToCompanyDetailsPermissions, ...CanNavigateToMakePaymentPermissions];

export const CanNavigateToAdminsPermissions = [
  ...CanNavigateToCreateAdminPermissions,
  ...CanNavigateToEditAdminPermissions,
  ...CanNavigateToDeleteAdminPermissions,
  ...CanNavigateToCompanyDetailsPermissions,
];

export const CanDeleteAdmins = [
  UserPermission.CanDeleteAdmins,
  UserPermission.CanDeleteAdminsOfLowerRoles,
];
