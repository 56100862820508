import { ReactNode } from 'react';
import { Link, useRoute } from 'wouter';
import { applyFunctionalityLimitedStyles } from '../appUIFramework/appBackend/useAccountInfo';

export function AppNavigationMenuLink({
  to,
  childRoutes,
  children,
  disabled,
}: { to: string, childRoutes?: string[], children: ReactNode, disabled: boolean }) {
  const [match] = useRoute(to);
  const onImplicitChildPage = window.location.href.includes(to) && to !== '/';
  const onExplicitChildPage = childRoutes && childRoutes.includes(window.location.pathname);
  const isActive = match || onImplicitChildPage || onExplicitChildPage;
  return (
    <div>
      {
        disabled
          ? (
            <span
              className="app-mp-bold-16 app-px-30 app-py-12 app-navigation-link app-hover-disabled"
              {...applyFunctionalityLimitedStyles(true)}
            >
              {children}
            </span>
          )
          : (
            <Link
              to={to}
              className={`app-mp-bold-16 app-px-30 app-py-12 app-navigation-link ${isActive ? 'app-navigation-link-active' : ''}`}
            >
              {children}
            </Link>
          )
      }
    </div>
  );
}
