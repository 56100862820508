import { useTranslation } from 'react-i18next';

export function UserStatusTooltipBody() {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
      >
        {t('Tooltip_UserStatusTitle')}
      </div>
      <div className="app-mt-28">
        {t('Tooltip_UserStatusBody')}
      </div>
      <div className="app-mt-28">
        <span
          className="app-text-bold"
        >
          {t('Tooltip_UserStatusBody_Unknown_Heading')}
        </span>
        {t('Tooltip_UserStatusBody_Unknown_Body')}
      </div>
      <div className="app-mt-28">
        <span
          className="app-text-bold"
        >
          {t('Tooltip_UserStatusBody_Pending_Heading')}
        </span>
        {t('Tooltip_UserStatusBody_Pending_Body')}
        <br />
        {t('Tooltip_UserStatusBody_Pending_Body_2')}
      </div>
      <div className="app-mt-28">
        <span
          className="app-text-bold"
        >
          {t('Tooltip_UserStatusBody_Paired_Heading')}
        </span>
        {t('Tooltip_UserStatusBody_Paired_Body')}
      </div>
      <div className="app-mt-28 app-mb-26">
        <span
          className="app-text-bold"
        >
          {t('Tooltip_UserStatusBody_Unpaired_Heading')}
        </span>
        {t('Tooltip_UserStatusBody_Unpaired_Body')}
      </div>
    </>
  );
}

export function UserLicenseTooltipBody() {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
      >
        {t('Tooltip_UserLicenceTitle')}
      </div>
      <div className="app-mt-28 ">
        {t('Tooltip_UserLicenceBody_WhenActive')}
      </div>
      <div className="app-mt-28 app-mb-26">
        {t('Tooltip_UserLicenceBody_WhenInactive')}
      </div>
    </>
  );
}

export function UserIdTooltipBody() {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
      >
        {t('Table_Group')}
      </div>
      <div className="app-mt-28">
        {t('Tooltip_NewMobileUserGroup')}
      </div>
      <div className="app-mt-28 app-mb-17">
        {t('Tooltip_NewMobileUserGroup_Condition')}
      </div>
    </>
  );
}

export function UserGroupTooltipBody() {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
      >
        {t('Table_Group')}
      </div>
      <div className="app-mt-28">
        {t('Tooltip_NewMobileUserGroup')}
      </div>
      <div className="app-mt-28 app-mb-17">
        {t('Tooltip_NewMobileUserGroup_Condition')}
      </div>
    </>
  );
}
