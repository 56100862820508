import { KeyedMutator } from 'swr';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { Urls } from '../../backend/urls';
import { httpGetJson, httpGetString, httpPost } from '../../backend/http/http';
import { useClaims } from '../../backend/auth/claims';
import { countryList, VatEvidenceType } from '../constants/Countries';
import { InternalAppsError } from '../../backend/errors/internalAppsError';

export enum AccountType {
  RegisteredInstaller = 'Registered Installer', // Installer that is registered with Paxton rewards
  Installer = 'Installer', // Installer not signed up to Paxton rewards
  SystemManager = 'System manager', // Email is in contacts but branch is not of type Installer
  Unkonwn = 'Unknown', // Email is not in contacts
}

export function getAccountType(customerType: string): AccountType {
  switch (customerType) {
    case 'Registered Installer':
      return AccountType.RegisteredInstaller;
    case 'Installer':
      return AccountType.Installer;
    case 'System manager':
      return AccountType.SystemManager;
    default:
      return AccountType.Unkonwn;
  }
}

export enum InstallerStatus {
  InReview = 0,
  Partnered,
  Silver,
  Gold,
  Platinum
}

export interface IInternalAppsCompanyAddress {
  companyName: string,
  address1: string,
  address2: string,
  city: string,
  postCode: string,
  country: string,
  countryId: number,
}

export interface InternalAppsCompany {
  customerReference: string,
  address: IInternalAppsCompanyAddress,
  typeOfCustomer: string,
  cardType: string;
  maskedCardNumber: string;
  installerLoyaltyTier: InstallerStatus,
  loyaltyDiscount: number,
  registrationDate: string,
  telephone: string,
  branchContact: {
    fullName: string,
    position: string,
    email: string,
    telephone: string,
    errorMessage: string,
    errorId: number
  },
  ErrorMessage: string,
  ErrorId: number,
  taxReference: string,
  paymentTermsDays: number,
}

interface ITypeOfCustomerDto {
  typeOfCustomer: string;
}

export async function isAccountExistsInAD(email: string): Promise<boolean> {
  try {
    const resp = await httpGetString(
      Urls.B2CConnectorIsEmailExistsInAD(email),
      { unauthorized: true },
    );

    return resp === 'True';
  } catch (e) {
    throw new InternalAppsError(e);
  }
}

export async function getAccountTypeFromEmail(email: string): Promise<AccountType> {
  try {
    const response = await httpPost(
      Urls.InternalAppsCutomerType,
      JSON.stringify({ email }),
      {
        unauthorized: true,
      },
    );
    const typeOfCustomerDto = await response.json() as ITypeOfCustomerDto;

    return typeOfCustomerDto.typeOfCustomer as AccountType;
  } catch (e) {
    throw new InternalAppsError(e);
  }
}

export async function getInternalAppsCompanyDetails(customerReference: string) {
  try {
    return await httpGetJson<InternalAppsCompany>(Urls.InternalAppsCustomerDetails(customerReference));
  } catch (e) {
    throw new InternalAppsError(e);
  }
}

export function useInternalAppsCompanyDetails(): { data: InternalAppsCompany | undefined, mutate: KeyedMutator<InternalAppsCompany> } {
  const { customerReference } = useClaims();
  const swrKey = Urls.InternalAppsCustomerDetails(customerReference);
  const { data, mutate } = useSWRAndHandleErrors<InternalAppsCompany>(swrKey, () => getInternalAppsCompanyDetails(customerReference));
  return { data, mutate };
}

export function isSystemManager(data: InternalAppsCompany) {
  return data.typeOfCustomer === AccountType.SystemManager;
}

export function isRegisteredInstaller(data: InternalAppsCompany) {
  return data.typeOfCustomer === AccountType.RegisteredInstaller;
}

export function isNotRegisteredInstaller(data: InternalAppsCompany) {
  return data.typeOfCustomer !== AccountType.RegisteredInstaller;
}

export function isVatRegisteredResellerCompany(data: InternalAppsCompany) {
  return isSystemManager(data);
}

export function isManualPaymentAllowed(data: InternalAppsCompany) {
  return !isVatRegisteredResellerCompany(data);
}

export function isTaxMethodUsedInCompany(internalAppsCompany: InternalAppsCompany, evidenceType: VatEvidenceType) {
  const countryVatEvidence = countryList.find((country) => country.countryId === internalAppsCompany.address.countryId)?.vatEvidence;
  return countryVatEvidence === evidenceType;
}
