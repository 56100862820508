import { httpPost } from '../http/http';
import { Urls } from '../urls';
import {
  LocalStorageKey,
  useLocalStorageJsonValue,
} from '../../jsUtils/localStorageService';
import {
  msalInstance,
} from './auth';
import { getUserIdFromClaims } from './claims';
import { PASSWORD_RESET_AUTHORITY } from './msal.config';

export enum PasswordChangeStatus {
  Unchanged = 0, // password not changed
  ChangedViaForgot = 1, // use change password via forgot password button during sign in
  ChangedViaChange = 2, // use change password via changed password button after sign in
}

const envPostPasswordChangeUrl = process.env.REACT_APP_B2C_POST_PASSWORD_CHANGE_REDIRECT!;

export interface IPasswordReset {
  uid: string | undefined;
  state: string | undefined;
}
export async function sendChangePasswordEmail(userId: string) {
  try {
    await httpPost(Urls.AdminUserSendPasswordUpdatedEmail(userId));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function handleResetPasswordPage() {
  const passwordReset = useLocalStorageJsonValue<IPasswordReset>(LocalStorageKey.PasswordReset);

  if (window.location.href.startsWith(`${envPostPasswordChangeUrl}#state`) && passwordReset.value.uid) {
    passwordReset.setValue({ ...passwordReset.value, state: window.location.href });
  }
}

export function getPasswordChangeStatus(idTokenClaims: any) {
  const passwordReset = useLocalStorageJsonValue<IPasswordReset>(LocalStorageKey.PasswordReset);

  if (passwordReset.value?.uid && passwordReset.value?.state) {
    passwordReset.resetValue();
    return PasswordChangeStatus.ChangedViaChange;
  }
  passwordReset.resetValue();

  if (!idTokenClaims.extension_CompanyId && idTokenClaims.isForgotPassword) {
    return PasswordChangeStatus.ChangedViaForgot;
  }

  return PasswordChangeStatus.Unchanged;
}

export async function goToChangePassword() {
  const userId = getUserIdFromClaims();

  if (userId) {
    const changePasswordUid = useLocalStorageJsonValue<IPasswordReset>(LocalStorageKey.PasswordReset);

    changePasswordUid.setValue({ uid: userId, state: undefined });
    await msalInstance.loginRedirect({
      scopes: ['openid'],
      authority: PASSWORD_RESET_AUTHORITY,
      redirectUri: envPostPasswordChangeUrl,
    });
  }
}
