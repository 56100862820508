import {
  useWorldpayDataForAutomaticPayments,
} from '../../../shared/appUIFramework/appBackend/useWorldpay';
import { useClaims } from '../../../shared/backend/auth/claims';
import WorldpayPopup from './WorldpayPopup';
import AppSystemErrorPopup from '../../../shared/appUIFramework/appGenericErrorPopup/AppSystemErrorPopup';

export default function AutomaticPaymentsPopup() {
  const { customerReference } = useClaims();
  const { worldpayData, isValidating } = useWorldpayDataForAutomaticPayments(customerReference);

  if ((!customerReference || !worldpayData) && !isValidating) {
    return <AppSystemErrorPopup />;
  }

  return <WorldpayPopup worldpayData={worldpayData} titleT="SetupAutomaticPayments" />;
}
