import useSWR, { SWRHook } from 'swr';
import { logoutWithError } from '../../../backend/auth/auth';
import { FailedToGetTokenError } from '../../../backend/errors/failedToGetTokenError';
import { NotSuccessResponseError } from '../../../backend/errors/notSuccessResponseError';
import { TimeoutError } from '../../../backend/errors/timeoutError';
import { setLocationTo } from '../../appForm/useAppUnsavedChangesLocation';
import { InternalServerError } from '../../../backend/errors/internalServerError';
import { StatusCodesEnum } from '../../constants/StatusCodesEnum';

type AddErrorHandlingType = <T>(action: (...args: any) => T, handleError: (error: any) => any) => (...args: any) => Promise<T | undefined> | T;

const AccessForbiddenCode = StatusCodesEnum.Forbidden;

export type ApiErrorType = NotSuccessResponseError | FailedToGetTokenError | TimeoutError | InternalServerError | unknown;

export function handleUnknownError(error: unknown) {
  // eslint-disable-next-line no-console
  console.log(error);
}

export async function handleHttpRequestError(error: ApiErrorType) {
  if (error instanceof FailedToGetTokenError) {
    await logoutWithError(StatusCodesEnum.Unauthorized);
  } if (error instanceof NotSuccessResponseError && error.response.status === AccessForbiddenCode) {
    setLocationTo(`/error/${AccessForbiddenCode}`);
  } else {
    handleUnknownError(error);
  }
}

export const addErrorHandler: AddErrorHandlingType = <T = any> (action: (...args: any) => T, handleError: (error: any) => any) => async (...args: any) => {
  try {
    const result = action(...args);
    if ((result as unknown as Promise<any>).then) {
      return await result as unknown as Promise<T>;
    }
    return result;
  } catch (error) {
    await handleError(error);
    return undefined;
  }
};

export const useSWRAndHandleErrors: SWRHook = (...args: any[]) => {
  const newArgs = [...args];

  if (typeof args[1] === 'function') {
    newArgs[1] = addErrorHandler(args[1], handleHttpRequestError);
  } else if (args[1]?.fetcher) {
    newArgs[1] = { ...args[1], fetcher: addErrorHandler(args[1].fetcher, handleHttpRequestError) };
  } else if (args[2]?.fetcher) {
    newArgs[2] = { ...args[2], fetcher: addErrorHandler(args[2].fetcher, handleHttpRequestError) };
  }

  return useSWR(newArgs[0], newArgs[1], {
    revalidateIfStale: false,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
    revalidateOnMount: true,
    ...newArgs[2],
  });
};

export function useAppSwr(...args: any[]) {
  return useSWR(args[0], args[1], args[2]);
}
