import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import EntryMonitor from '../../assets/Entry Monitor sign in image.png';
import PaxtonLogoSvg from '../../assets/MyPaxton Logo.svg';
import { B2CLangCode } from '../../shared/appUIFramework/constants/B2CLangCode';
import { goToLoginPage } from '../../shared/backend/auth/auth';
import { LanguageSelect } from './components/LanguageSelect';
import { useAppLocalisation } from '../../shared/i18n/config';
import { AppTableState } from '../../shared/appUIFramework/components/AppTablePage';

export const Welcome = () => {
  const { t } = useTranslation();
  const { lang: language } = useAppLocalisation();

  const redirectToLogin = () => goToLoginPage(language as B2CLangCode);
  const [, setLocation] = useLocation();
  useEffect(() => {
    // if welcome page is shown clear msal cache, sometimes we had issues with outdated cache
    sessionStorage.clear();

    // clean up all settings saved for the user session
    AppTableState.cleanUp();

    // redirect to home page, because other pages are not valid as redirect uri for b2c
    setLocation('/');
  }, []);

  const redirectToSignUp = () => setLocation('/signup/region');

  return (
    <div className="app-d-flex app-welcome">
      <LanguageSelect />
      <img
        className="app-w-50pcnt app-h-100vh app-fit-cover"
        src={EntryMonitor}
        alt="login"
      />
      <div className="app-d-flex app-flex-column app-align-items-center app-justify-content-center app-w-50pcnt">
        <div className="app-mb-32 app-welcome-to">{ t('Welcome_Welcome') }</div>
        <img
          className="app-mb-40 app-welcome-logo"
          src={PaxtonLogoSvg}
          alt="logo"
        />
        <div className="app-font-21-35 app-text-align-center app-mb-40">
          <div className="app-weight-600">
            {t('AlreadyRegistered')}
            &nbsp;
            <span className="app-color-primary-green app-weight-600">
              {t('PaxtonInstaller?')}
            </span>
          </div>
          <div className="app-mt-10 app-font-20-35">{t('UseExistingCredentials')}</div>
        </div>
        <button
          type="button"
          onClick={redirectToLogin}
          className="app-primary-button app-mw-373"
        >
          { t('Welcome_SignInInstaller') }
        </button>
        <button
          type="button"
          onClick={redirectToSignUp}
          className="app-secondary-button app-mt-22"
        >
          { t('Welcome_SignUpInstaller') }
        </button>
      </div>
    </div>
  );
};
