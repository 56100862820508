import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from '../../assets/company-details/Email.svg';
import { ReactComponent as NameIcon } from '../../assets/company-details/User.svg';
import { ReactComponent as PositionIcon } from '../../assets/company-details/position.svg';
import { ReactComponent as MobileIcon } from '../../assets/company-details/Mobile.svg';
import { ReactComponent as AddressIcon } from '../../assets/company-details/Location.svg';
import { ReactComponent as PhoneIcon } from '../../assets/company-details/Phone.svg';
import { ReactComponent as CompanyNameIcon } from '../../assets/company-details/UserBuilding.svg';
import { ReactComponent as CompanyIcon } from '../../assets/company-details/Building.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/Tick Valid icon.svg';
import SilverMedal from '../../assets/company-details/SilverPartner-medallion.svg';
import GoldMedal from '../../assets/company-details/GoldPartner-medallion.svg';
import PlatinumMedal from '../../assets/company-details/PlatinumPartner-medallion.svg';
import PartnerMedal from '../../assets/company-details/Partner-medallion.svg';
import SilverMedalLg from '../../assets/company-details/SilverPartner-medallionLg.svg';
import GoldMedalLg from '../../assets/company-details/GoldPartner-medallionLg.svg';
import PlatinumMedalLg from '../../assets/company-details/PlatinumPartner-medallionLg.svg';
import PartnerMedalLg from '../../assets/company-details/Partner-medallionLg.svg';
import {
  InstallerStatus,
  InternalAppsCompany,
  isTaxMethodUsedInCompany, isVatRegisteredResellerCompany,
  useInternalAppsCompanyDetails,
} from '../../shared/appUIFramework/appBackend/useKeyContact';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import '../systems/SystemTabsSystemDetails.scss';
import './AdminCompanyDetails.scss';
import AdminShell from './components/AdminShell';
import { formatSystemAddress } from '../../shared/appUIFramework/formatters/formatSiteAddress';
import { formatPercent } from '../../shared/appUIFramework/formatters/formatPercent';
import { VatEvidenceType } from '../../shared/appUIFramework/constants/Countries';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import { AccountStatusUIState, useAccountInfo } from '../../shared/appUIFramework/appBackend/useAccountInfo';
import { B2CLangCode } from '../../shared/appUIFramework/constants/B2CLangCode';
import { useAppLocalisation } from '../../shared/i18n/config';

const paxtonInfoLinksMap: Record<B2CLangCode, string> = {
  [B2CLangCode.English]: 'https://www.paxton-access.com/install-paxton/resources/paxton-rewards/',
  [B2CLangCode.French]: 'https://www.paxton-access.com/fr/installer-paxton/ressources/paxton-rewards/',
  [B2CLangCode.German]: 'https://www.paxton-access.com/de/montage-von-paxton-produkten/ressourcenmaterial/paxton-rewards/',
  [B2CLangCode.EnglishUS]: 'https://www.paxton-access.com/us/install-paxton/resources/paxton-rewards/',
  [B2CLangCode.Swedish]: 'https://www.paxton-access.com/install-paxton/resources/paxton-rewards/',
  [B2CLangCode.Norwegian]: 'https://www.paxton-access.com/install-paxton/resources/paxton-rewards/',
  [B2CLangCode.Danish]: 'https://www.paxton-access.com/us/install-paxton/resources/paxton-rewards/',
  [B2CLangCode.SpanishLatinAmerica]: 'https://www.paxton-access.com/install-paxton/resources/paxton-rewards/',
  [B2CLangCode.Netherlands]: 'https://www.paxton-access.com/install-paxton/resources/paxton-rewards/',
};

export const InstallerStatusMedals: Record<InstallerStatus, any> = {
  [InstallerStatus.InReview]: <></>,
  [InstallerStatus.Partnered]: <img src={PartnerMedal} alt="PartnerMedal" />,
  [InstallerStatus.Silver]: <img src={SilverMedal} alt="SilverMedal" />,
  [InstallerStatus.Gold]: <img src={GoldMedal} alt="GoldMedal" />,
  [InstallerStatus.Platinum]: <img src={PlatinumMedal} alt="PlatinumMedal" />,
};

export const InstallerStatusMedalsLg: Record<InstallerStatus, any> = {
  [InstallerStatus.InReview]: <></>,
  [InstallerStatus.Partnered]: <img src={PartnerMedalLg} alt="PartnerMedal" />,
  [InstallerStatus.Silver]: <img src={SilverMedalLg} alt="SilverMedal" />,
  [InstallerStatus.Gold]: <img src={GoldMedalLg} alt="GoldMedal" />,
  [InstallerStatus.Platinum]: <img src={PlatinumMedalLg} alt="PlatinumMedal" />,
};

export type TypeInstallerStatusTranslationKey = 'InstallerStatus_Tier_Gold'
  | 'InstallerStatus_Tier_Platinum'
  | 'InstallerStatus_Tier_Partnered'
  | 'InstallerStatus_Tier_Silver'
  | 'InstallerStatus_Tier_InReview';

export const InstallerStatusTranslationKeys: Record<InstallerStatus, TypeInstallerStatusTranslationKey> = {
  [InstallerStatus.InReview]: 'InstallerStatus_Tier_InReview',
  [InstallerStatus.Partnered]: 'InstallerStatus_Tier_Partnered',
  [InstallerStatus.Silver]: 'InstallerStatus_Tier_Silver',
  [InstallerStatus.Gold]: 'InstallerStatus_Tier_Gold',
  [InstallerStatus.Platinum]: 'InstallerStatus_Tier_Platinum',
};

export const InstallerBenefitsTranslationKeys: Record<InstallerStatus, string> = {
  [InstallerStatus.InReview]: 'InstallerStatusBenefits_InReview',
  [InstallerStatus.Partnered]: 'InstallerStatusBenefits_Partnered',
  [InstallerStatus.Silver]: 'InstallerStatusBenefits_Silver',
  [InstallerStatus.Gold]: 'InstallerStatusBenefits_Gold',
  [InstallerStatus.Platinum]: 'InstallerStatusBenefits_Platinum',
};

function SystemDetailsPaper({ title, children }: { title?: string | undefined, children: ReactNode }) {
  return (
    <div className="app-system-details-paper">
      {
        title && (
          <div className="app-system-details-paper-title">
            {title}
          </div>
        )
      }
      <div className="app-system-details-paper-content app-h-100pcnt">
        {children}
      </div>
    </div>
  );
}

function SystemDetailsPaperItem({ icon, content }: { icon: ReactNode, content: ReactNode }) {
  return (
    <div
      className="app-system-details-paper-content-item"
    >
      <div
        className="app-system-details-paper-content-item-icon"
      >
        {icon}
      </div>
      <div className="app-system-details-paper-content-item-content">
        {content}
      </div>
    </div>
  );
}

function ParterTier({ tier, percent }: { tier: InstallerStatus, percent: number }) {
  const { t } = useTranslation();
  return (
    <div className="app-d-flex app-gap-20">
      <div>
        <div className="app-w-160 app-font-20 app-color-primary-black app-weight-600">
          {t(InstallerStatusTranslationKeys[tier])}
        </div>
        <div className="app-font-40 app-color-primary-green app-weight-600">
          {percent}
          %
        </div>
      </div>
      <div className="app-company-details-medallion">
        {InstallerStatusMedals[tier]}
      </div>
    </div>
  );
}

function InstallerStatusCardContent({ applicationStatus, percent }: { applicationStatus: InstallerStatus, percent: string }) {
  const { t } = useTranslation();
  const { lang } = useAppLocalisation();

  return (
    <SystemDetailsPaper>
      <div className="app-d-flex app-flex-column app-h-100pcnt">
        <div className="app-d-flex app-gap-48 app-mb-44">
          <div className="app-d-flex app-flex-column app-flex-1 app-company-details-partner-info-column app-container-inline-size">
            <div className="app-font-20 app-weight-600 app-uppercase app-mb-32 app-color-primary-black">{t('PaxtonRewards')}</div>
            <div className="app-font-20 app-weight-600 app-color-primary-green app-mb-12 ">
              {t(`${InstallerStatusTranslationKeys[applicationStatus]}`)}
            </div>
            <div className="app-font-20 app-lh-28 app-mb-60 app-color-primary-black2">
              {t('ThisQualifiesYourAccountForPercentDiscount', { percent })}
            </div>
            <div className="app-font-20 app-weight-600 app-uppercase app-mb-32 app-color-primary-black">
              {t('PartnerTiers')}
            </div>
            <div className="app-d-flex app-container-hidden-500">
              <div className="app-d-flex app-flex-column app-w-50pcnt app-gap-50">
                <ParterTier tier={InstallerStatus.Partnered} percent={15} />
                <ParterTier tier={InstallerStatus.Gold} percent={35} />
              </div>
              <div className="app-d-flex app-flex-column app-w-50pcnt app-gap-50">
                <ParterTier tier={InstallerStatus.Silver} percent={25} />
                <ParterTier tier={InstallerStatus.Platinum} percent={45} />
              </div>
            </div>
            <div className="app-d-flex app-flex-column app-gap-30 app-container-visible-500-flex">
              <div className="app-d-flex app-w-50pcnt app-gap-50">
                <ParterTier tier={InstallerStatus.Partnered} percent={15} />
                <ParterTier tier={InstallerStatus.Silver} percent={25} />
              </div>
              <div className="app-d-flex app-w-50pcnt app-gap-50">
                <ParterTier tier={InstallerStatus.Gold} percent={35} />
                <ParterTier tier={InstallerStatus.Platinum} percent={45} />
              </div>
            </div>
          </div>
          <div>
            {InstallerStatusMedalsLg[applicationStatus]}
          </div>
        </div>
        <div className="app-flex-1 app-d-flex app-flex-column app-justify-content-around">
          <div className="app-font-20 app-lh-28 app-color-primary-black2 app-mb-20">
            {t('MoreInformationAboutYourCurrentPointsTotalAndTheAmount')}
          </div>
          <div className="app-font-20 app-lh-28 app-color-primary-black2">
            <span>{t('ForMoreInformationAboutPaxtonRewards')}</span>
            &nbsp;
            <span>
              <a className="app-link " href={paxtonInfoLinksMap[lang]}>{t('PaxtonWebsite')}</a>
              .
            </span>
          </div>
        </div>
      </div>
    </SystemDetailsPaper>
  );
}

function CompanyInfoContentCard({ company, titleTranslationKey = 'CompanyInfo' }: { company: InternalAppsCompany, titleTranslationKey?: string }) {
  const { t } = useTranslation();
  const vatDetails = useCompany();

  return (
    <SystemDetailsPaper title={t(titleTranslationKey)}>
      <div className="app-d-flex app-justify-content-between">
        <div>
          <SystemDetailsPaperItem
            icon={<CompanyNameIcon />}
            content={company.address.companyName}
          />
          <SystemDetailsPaperItem
            icon={<CompanyIcon />}
            content={company.typeOfCustomer}
          />
          <SystemDetailsPaperItem
            icon={<AddressIcon />}
            content={formatSystemAddress({
              address1: company.address.address1,
              address2: company.address.address2,
              city: company.address.city,
              state: company.address.country,
              postCode: company.address.postCode,
              countryCode: company.address.country,
            })}
          />
          <SystemDetailsPaperItem
            icon={<PhoneIcon />}
            content={company.telephone}
          />
          {isTaxMethodUsedInCompany(company, VatEvidenceType.VatNo) && (
            <SystemDetailsPaperItem
              icon={<CheckIcon className="app-contact-check-icon" />}
              content={vatDetails?.vatNumber}
            />
          )}
        </div>
      </div>
    </SystemDetailsPaper>
  );
}

export default function AdminCompanyDetails() {
  const { t } = useTranslation();
  const { data: company } = useInternalAppsCompanyDetails();
  const { accountInfo: { accountStatus } } = useAccountInfo();
  const statusCardIncluded = company && !isVatRegisteredResellerCompany(company);

  return (
    <AdminShell activeTab="company-details">
      <div className="app-content app-px-30 app-h-100pcnt" aria-disabled={accountStatus === AccountStatusUIState.Limited}>
        <AppShowLoading showLoading={!company}>
          {company && (
            <>
              <div className="app-mb-46 app-system-details-papers">
                <div className="app-d-flex app-flex-column app-gap-30">
                  <SystemDetailsPaper title={t('KeyContact')}>
                    <SystemDetailsPaperItem
                      icon={<NameIcon />}
                      content={company.branchContact.fullName}
                    />
                    <SystemDetailsPaperItem
                      icon={<EmailIcon />}
                      content={company.branchContact.email}
                    />
                    <SystemDetailsPaperItem
                      icon={<MobileIcon />}
                      content={company.branchContact.telephone}
                    />
                    <SystemDetailsPaperItem
                      icon={<PositionIcon />}
                      content={company.branchContact.position}
                    />
                  </SystemDetailsPaper>
                  {statusCardIncluded && (<CompanyInfoContentCard company={company} />)}
                </div>
                {statusCardIncluded ? (
                  <InstallerStatusCardContent
                    applicationStatus={company.installerLoyaltyTier}
                    percent={formatPercent(company.loyaltyDiscount * 100, true)}
                  />
                ) : (<CompanyInfoContentCard titleTranslationKey="OrganizationInfo" company={company} />)}
              </div>
              <div className="app-flex-1" />
              <div className="app-admin-company-details-footer-text">
                {t('CompanyInformation_Footer')}
              </div>
            </>
          )}
        </AppShowLoading>
      </div>
    </AdminShell>
  );
}
