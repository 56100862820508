export enum UserPermission {
  CanViewNextEstimatedBill = 1,
  CanViewTotalDevices = 2,
  CanViewTotalSites = 3,

  CanViewSites = 10,
  CanEditSite = 11,
  CanDeleteSite = 12,
  CanCreateSite = 13,
  CanViewSitesForSiteSubset = 14,
  CanEditSiteForSiteSubset = 15,

  CanViewEntryUsers = 20,
  CanEditEntryUser = 22,
  CanCreateEntryUser = 23,
  CanDeleteEntryUser = 24,
  CanViewHardware = 25,
  CanBoundHardware = 26,
  CanViewSiteCosts = 27,
  CanViewSiteDetails = 28,
  CanEditSiteDetails = 29,
  CanViewEntryUsersForSiteSubset = 30,
  CanEditEntryUserForSiteSubset = 31,
  CanCreateEntryUserForSiteSubset = 32,
  CanDeleteEntryUserForSiteSubset = 33,
  CanViewHardwareForSiteSubset = 34,
  CanBoundHardwareForSiteSubset = 35,
  CanViewSiteCostsForSiteSubset = 36,
  CanViewSiteDetailsForSiteSubset = 37,
  CanEditSiteDetailsForSiteSubset = 38,
  CanViewEntryUsersObfuscated = 39,

  CanViewPaymentOverview = 50,
  CanMakePayments = 51,
  CanViewTermsAndConditions = 52,

  CanViewAdmins = 60,
  CanEditAdmins = 61,
  CanCreateAdmins = 62,
  CanDeleteAdmins = 63,
  CanViewCompanyDetails = 64,
  CanViewAdminsOfLowerRoles = 65,
  CanEditAdminsOfLowerRoles = 66,
  CanCreateAdminsOfLowerRoles = 67,
  CanDeleteAdminsOfLowerRoles = 68,
}
