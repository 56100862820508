import { KeyedMutator } from 'swr';
import { UseFormReset } from 'react-hook-form';
import { IHardwareDevice } from '../models/IHardware';
import { isAnotherUserWithSameCallIdAndGroup } from './entryUserForm.monitors';
import { showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import NewMobileUserMonitorAlreadyAssignedConfirmationPopup
  from './NewMobileUserMonitorAlreadyAssignedConfirmationPopup';
import { IUserData } from '../models/IUserData';
import { createEntryUser, reinstateUser, updateEntryUser } from '../../../shared/appUIFramework/appBackend/useEntryUsers';
import NewUserEmailConfirmationPopup from '../../../shared/appUIFramework/appForm/NewUserEmailConfirmationPopup';
import { handleFormError } from '../../../shared/appUIFramework/appForm/appSubmitAndHandleErrors';
import ReinstateEntryUserConfirmationPopup from './ReinstateEntryUserConfirmationPopup';
import { AddressBookSource } from '../../../shared/appUIFramework/appBackend/useAddressBook';

async function confirmSaveOfCallId(devices: IHardwareDevice[], callId: string, group: string, isNew: boolean) {
  const monitorIdUsed = isAnotherUserWithSameCallIdAndGroup(devices, callId, group, isNew);

  return !monitorIdUsed || showPopup(<NewMobileUserMonitorAlreadyAssignedConfirmationPopup id={callId} />);
}

export function getEntryUserFormSubmitHandler({
  isNew,
  companyId,
  siteId,
  devices,
  refreshExistingUser,
  addressBookSource,
}: {
  isNew: boolean,
  companyId?: string,
  siteId?: string,
  devices?: IHardwareDevice[],
  refreshExistingUser?: KeyedMutator<IUserData>,
  addressBookSource?: AddressBookSource,
}) {
  return async (formData: IUserData) => {
    if (!await confirmSaveOfCallId(devices || [], formData.siteInfo.callId, formData.siteInfo.groupId, isNew)) {
      return true;
    }

    if (!companyId || !siteId) {
      throw new Error('companyId and siteId are required');
    }

    if (isNew) {
      const userData = await createEntryUser(formData, companyId, siteId);
      if (userData) {
        await showPopup(<NewUserEmailConfirmationPopup email={userData.email} showStatusText />);
      } else {
        throw new Error('Error while creating user');
      }
    } else {
      const requestPayload = {
        ...formData,
        addressBookSource,
      };
      const userData = await updateEntryUser(requestPayload, companyId, siteId);
      if (refreshExistingUser) {
        await refreshExistingUser(userData);
      }
    }

    return false;
  };
}

export function getEntryUserReinstateHandler({
  updateEntryUserOnUi,
  getOriginalValues,
  siteId,
  resetFormValues,
  goBackOnError,
}:{
  updateEntryUserOnUi: KeyedMutator<IUserData>,
  getOriginalValues: () => IUserData,
  siteId: string,
  resetFormValues: UseFormReset<IUserData>
  goBackOnError: () => void
}) {
  return async () => {
    const shouldReinstate = await showPopup(<ReinstateEntryUserConfirmationPopup />);
    if (!shouldReinstate) {
      return;
    }
    const originalValues = getOriginalValues();
    await updateEntryUserOnUi(async () => {
      try {
        await reinstateUser(originalValues.id, siteId);
        const updatedData = await updateEntryUserOnUi(() => undefined);
        resetFormValues(updatedData);
        return updatedData;
      } catch (error) {
        await handleFormError(error, { reset: resetFormValues, goBack: goBackOnError, mutate: updateEntryUserOnUi });
        return originalValues;
      }
    }, {
      // @ts-ignore
      optimisticData: () => undefined,
      revalidate: false,
    });
  };
}
