export function clamp(value: number, range: { min?: number; max?: number; }) {
  const minVal = range.min ?? Number.NEGATIVE_INFINITY;
  const maxVal = range.max ?? Number.POSITIVE_INFINITY;
  if (value < minVal) {
    return minVal;
  }
  if (value > maxVal) {
    return maxVal;
  }
  return value;
}
