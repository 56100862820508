import { useEffect, useState } from 'react';
import { handleHttpRequestError } from './errorHandling/useSWRAndHandleErrors';
import { Urls } from '../../backend/urls';
import { wsGet } from '../../backend/websockets/websockets';
import { IHardwareDevice, IHardwareRequest } from '../../../routes/systems/models/IHardware';
import { getUrlWithQueryParams, httpGetJson } from '../../backend/http/http';
import { IUserSiteInfo } from '../../../routes/systems/models/IUserData';
import { ISiteInfo } from '../../../routes/systems/models/ISiteInfo';

export enum AddressBookSource {
  Cloud = 'cloud',
  Hardware = 'hardware',
}

async function getAddressBookFromCloud(siteInfo: ISiteInfo | undefined) {
  const cloudAddressBook = await httpGetJson<IUserSiteInfo[]>(Urls.UsersAddressBook(siteInfo?.id)!);
  return cloudAddressBook.map((p) => ({
    deviceId: p.callId,
    deviceName: p.callName,
    group: p.groupId,
    deviceType: 'mobile',
  } as IHardwareDevice));
}

async function getAddressBookFromHardware(siteId: string | undefined, siteInfo: ISiteInfo, onStoredDataReceived: (storedData: IHardwareDevice[]) => void) {
  const url = getUrlWithQueryParams(Urls.HardwareSummary(siteId)!, { companyId: siteInfo?.companyId, useStoredData: true });
  const { hardwareSummary: hardwareDevices } = await wsGet<IHardwareRequest>(async () => {
    const storedDevices = await httpGetJson<IHardwareDevice[]>(url);
    onStoredDataReceived(storedDevices);
  }, Urls.HardwareNegotiate(siteId));
  return hardwareDevices;
}

function mergeHardwareDevicesWithCloudDevices(hardwareDevices: IHardwareDevice[], cloudDevices: IHardwareDevice[]) {
  const cloudDevicesMissingInHardware = cloudDevices.filter((cloudDevice) => !hardwareDevices.find((p) => p.deviceId === cloudDevice.deviceId));
  return [...hardwareDevices, ...cloudDevicesMissingInHardware];
}

const addressBookCache: { devices?: IHardwareDevice[] | undefined } = {
  devices: undefined,
};

// the function is called on the site page opened, to preload address book and store it in the cache
// when the user opens the entry user form, the cache is used to get the address book
// at the same time cache is being invalidated in background
// and once invalidated, it would be updated on UI
// that is to get the best speed per EA-4357
// per product management, users will adding users often, so it should be no extra delays on entry user form load
// the receiving address book either from cloud or hardware some times takes a lot of time, so it is better to preload it
export function useAddressBook(siteInfo?: ISiteInfo): {
  devices?: IHardwareDevice[],
  source?: AddressBookSource
} {
  const [cloudDevices, setCloudDevices] = useState<IHardwareDevice[] | undefined>(undefined);
  useEffect(() => {
    if (siteInfo) {
      getAddressBookFromCloud(siteInfo)
        .then((newDevices) => setCloudDevices(newDevices))
        .catch(handleHttpRequestError);
    }
  }, [siteInfo]);

  const [hardwareDevices, setHardwareDevices] = useState<IHardwareDevice[] | undefined>(undefined);
  useEffect(() => {
    if (siteInfo && siteInfo.id) {
      getAddressBookFromHardware(siteInfo.id, siteInfo, (storedDevices) => setHardwareDevices(storedDevices))
        .then((newDevices) => setHardwareDevices(newDevices))
        .catch(handleHttpRequestError);
    }
  }, [siteInfo]);

  const [devices, setDevices] = useState<IHardwareDevice[] | undefined>(undefined);
  const [source, setSource] = useState<AddressBookSource | undefined>(undefined);
  useEffect(() => {
    if (!hardwareDevices && !cloudDevices) {
      setDevices(undefined);
    }

    const newDevices = mergeHardwareDevicesWithCloudDevices(hardwareDevices || [], cloudDevices || []);
    setDevices(newDevices);
    setSource(hardwareDevices ? AddressBookSource.Hardware : AddressBookSource.Cloud);

    addressBookCache.devices = newDevices;
  }, [cloudDevices, hardwareDevices]);

  return !devices || !siteInfo
    ? addressBookCache
    : { devices, source };
}
