import { useState } from 'react';

export function useBooleanState(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    set: () => setValue(true),
    reset: () => setValue(false),
    toggle: () => setValue(!value),
  };
}
