import { IWorldpayData } from '../../../shared/appUIFramework/appBackend/useWorldpay';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import WorldpayIframe from './WorldpayIframe';

export default function WorldpayPopup({ worldpayData, titleT }: { worldpayData?: IWorldpayData, titleT: string; }) {
  const closeWithFalse = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle={titleT}
      extraClass="app-popup-worldpay"
      onHeaderClose={closeWithFalse}
      popupBody={(
        <WorldpayIframe onSuccess={closePopup} worldpayData={worldpayData} />
      )}
    />
  );
}
