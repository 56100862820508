import { showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { createAdmin, updateAdmin } from '../../../shared/appUIFramework/appBackend/useAdminsInfinite';
import { AdminFormType } from './AdminForm';
import NewUserEmailConfirmationPopup from '../../../shared/appUIFramework/appForm/NewUserEmailConfirmationPopup';
import { NotSuccessResponseError } from '../../../shared/backend/errors/notSuccessResponseError';
import { StatusCodesEnum } from '../../../shared/appUIFramework/constants/StatusCodesEnum';
import ChangePaymentAdminFailurePopup from './ChangePaymentAdminFailurePopup';
import { IProfile, ISystemAdminInfo } from '../../../shared/appUIFramework/appBackend/useProfileDetails';
import { useClaims } from '../../../shared/backend/auth/claims';
import CreatePaymentAdminFailurePopup from './CreatePaymentAdminFailurePopup';

async function createAdminFlow(data: IProfile, goBack: () => void) {
  try {
    await createAdmin(data);
    return showPopup(<NewUserEmailConfirmationPopup email={data.email} />);
  } catch (error) {
    if (error instanceof NotSuccessResponseError && error.response.status === StatusCodesEnum.Conflict) {
      const backToForm = await showPopup(<CreatePaymentAdminFailurePopup />);
      if (!backToForm) {
        goBack();
      }
      return true; // submit aborted, utilized by appSubmitAndHandleErrors
    }
    throw error; // allow all defaults handlers work
  }
}

async function updateAdminFlow(data: IProfile, goBack: () => void) {
  try {
    return await updateAdmin(data);
  } catch (error) {
    if (error instanceof NotSuccessResponseError && error.response.status === StatusCodesEnum.Conflict) {
      const backToForm = await showPopup(<ChangePaymentAdminFailurePopup />);
      if (!backToForm) {
        goBack();
      }
      return true; // submit aborted, utilized by appSubmitAndHandleErrors
    }
    throw error; // allow all defaults handlers work
  }
}

export function getAdminUserFormSubmitHandler({ isNew, goBack }: { isNew: boolean, goBack: () => void }) {
  return async (data: AdminFormType) => {
    const claims = useClaims();

    const extendedData = {
      ...data,
      // validation should not allow to call save with any undefined system
      systems: data.systems as ISystemAdminInfo[],
      companyId: isNew ? claims.companyId : data.companyId,
      // validation should not allow to call save with any undefined system
      branchOfficeId: isNew ? claims.branchOfficeId : data.branchOfficeId,
      internalAppsCustomerReference: isNew ? claims.customerReference : data.internalAppsCustomerReference,
      locale: isNew ? claims.locale : data.locale,
    } as IProfile;

    return isNew ? createAdminFlow(extendedData, goBack) : updateAdminFlow(extendedData, goBack);
  };
}
