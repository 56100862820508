import { getAccessToken } from '../auth/auth';
import { FailedToGetTokenError } from '../errors/failedToGetTokenError';
import { NotSuccessResponseError } from '../errors/notSuccessResponseError';
import { TimeoutError } from '../errors/timeoutError';
import { InternalServerError } from '../errors/internalServerError';

// eslint-disable-next-line no-undef
type EntryRequestInit = RequestInit & { unauthorized?: boolean };

// eslint-disable-next-line no-undef
async function appFetch(input: RequestInfo, init?: EntryRequestInit) {
  const getEntryAccessToken = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      throw new FailedToGetTokenError();
    }
    return accessToken;
  };
  const accessToken = init?.unauthorized ? undefined : await getEntryAccessToken();
  try {
    const entryHeaders = init?.unauthorized ? undefined : {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
    };
    const response = await fetch(input, {
      ...init,
      headers: {
        ...entryHeaders,
        ...init?.headers,
      },
    });

    if (!response || !response.ok) {
      throw new NotSuccessResponseError(response);
    }

    return response;
  } catch (error: any) {
    const getErrorFromNonSuccessResponseError = (err: NotSuccessResponseError) => (err.response.status >= 500 ? new InternalServerError(err.response) : err);

    if (error instanceof TypeError) {
      // This is to catch if the service is off and the preflight fails in appFetch and throws out to here
      if (error.message === 'Failed to fetch') {
        throw new InternalServerError(error);
      }
    } else if (error instanceof NotSuccessResponseError) {
      throw getErrorFromNonSuccessResponseError(error);
    } else if ((error as Response).status) {
      throw new NotSuccessResponseError(error as Response);
    } else if (error instanceof DOMException) { // could be thrown by websocket processing pushUrl
      throw new TimeoutError(error);
    }

    throw error;
  }
}

export function getUrlWithQueryParams(url: string, queryParams?: any): string {
  if (!queryParams || !url) {
    return url;
  }

  const queryString = Object.keys(queryParams)
    .map((key) => (queryParams[key] != null ? `${key}=${encodeURIComponent(queryParams[key])}` : undefined))
    .filter((p) => p)
    .join('&');
  const hasQueryParams = url.includes('?');
  const separator = hasQueryParams ? '&' : '?';
  return `${url}${separator}${queryString || ''}`;
}

// eslint-disable-next-line no-undef
export async function httpGet(url: string, options?: EntryRequestInit & { queryParams?: any, unauthorized?: boolean }): Promise<Response> {
  return appFetch(getUrlWithQueryParams(url, options?.queryParams), options);
}

// eslint-disable-next-line no-undef
export async function httpGetJson<TResult>(url: string, options?: EntryRequestInit & { queryParams?: any }): Promise<TResult> {
  const response = await httpGet(url, options);

  return response.json();
}

export async function httpGetJsonOrDefault<TResult>(url: string, defaultValue: TResult, options?: Request & {queryParams: any}): Promise<TResult> {
  try {
    const res = await httpGetJson<TResult>(url, options);
    return res;
  } catch (error) {
    return defaultValue;
  }
}

// eslint-disable-next-line no-undef
export async function httpGetString(url: string, options?: EntryRequestInit & { queryParams?: any }): Promise<string> {
  const response = await httpGet(url, options);

  return response.text();
}

type FormBodyType = any;

// eslint-disable-next-line no-undef
export async function httpPost(url: string, body?: string | FormData, options?: EntryRequestInit) {
  return appFetch(url, {
    method: 'POST',
    body,
    ...options,
  });
}

// eslint-disable-next-line no-undef
export async function httpPostUrlFormEncoded(url: string, body?: FormBodyType, options?: EntryRequestInit & { unauthorized?: boolean }) {
  const payload = body || {};

  const encodedItems = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in payload) {
    if (payload[key] == null) {
      delete payload[key];
    } else {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(payload[key]);
      encodedItems.push(`${encodedKey}=${encodedValue}`);
    }
  }

  return appFetch(url, {
    method: 'POST',
    body: encodedItems.join('&'),
    ...options,
  });
}

// eslint-disable-next-line no-undef
export async function httpPostJson<TResult>(url: string, body?: FormBodyType): Promise<TResult> {
  const response = await httpPost(url, JSON.stringify(body));
  return response.json();
}

export async function httpPut(url: string, body: any) {
  return appFetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export async function httpDelete(url: string): Promise<Response> {
  return appFetch(url, {
    method: 'DELETE',
  });
}
