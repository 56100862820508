import {
  ReactNode, RefObject, useEffect, useRef,
} from 'react';

export default function AppOutsideListener({ onClick, inputRef, children }:{onClick: (e: Event) => unknown, inputRef?: RefObject<HTMLDivElement>, children?: ReactNode}) {
  const componentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const ref = inputRef || componentRef;
    const handleClickOutSide = (e: Event) => {
      const clickOnElementThatNotChild = ref.current
        && !ref.current.contains(e.target as Node);
      if (clickOnElementThatNotChild) {
        onClick(e);
      }
    };
    window.addEventListener('click', handleClickOutSide);

    return () => window.removeEventListener('click', handleClickOutSide);
  }, [onClick]);
  return inputRef ? <>{children}</> : (
    <div ref={componentRef}>
      {children}
    </div>
  );
}
