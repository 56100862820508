import { useTranslation } from 'react-i18next';
import { useLocation, useRoute } from 'wouter';
import {
  ReactComponent as BackwardBreadCrumpIcon,
} from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AdminForm from './components/AdminForm';
import { AccountStatusUIState, useAccountInfo } from '../../shared/appUIFramework/appBackend/useAccountInfo';

export default function AdminPeopleDetails() {
  const { t } = useTranslation();
  const [, params] = useRoute('/admin/people/:adminId');
  const { accountInfo: { accountStatus } } = useAccountInfo();
  const [, setLocation] = useLocation();

  const goToPeople = () => {
    setLocation('/admin/people');
  };
  return (
    <>
      <AppContentToolbar>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <span className="app-toolbar-breadcrumb app-hover-pointer" onClick={goToPeople}>{ t('People_Title') }</span>
        <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb">{ t('InstallerDetails') }</span>
      </AppContentToolbar>
      <AdminForm disabled={accountStatus === AccountStatusUIState.Limited} adminId={params?.adminId} />
    </>
  );
}
