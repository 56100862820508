import { useTranslation } from 'react-i18next';
import { ReactComponent as ReinstateUserIcon } from '../../../assets/icons/Text field icons/Name Icon V2.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';

export default function ReinstateEntryUserConfirmationPopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);
  return (
    <AppPopupLayout
      extraClass="app-popup-connect"
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
          <button type="button" onClick={confirm} className="app-primary-button app-popup-right-button">{t('Popup_Yes')}</button>
        </>
            )}
      headerTitle={t('Popup_ReinstateUser')}
      popupBody={(
        <>
          <ReinstateUserIcon width={125} height={135} />
          <span className="app-font-20 app-uppercase app-weight-600">
            { t('Popup_ReinstateUser') }
          </span>
          <span className="app-font-20 app-text-align-center ">
            {`${t('Popup_AreYouSureReinstateUser')}`}
          </span>
        </>
            )}
    />
  );
}
