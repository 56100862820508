import { ISite } from '../../../routes/systems/models/ISiteInfo';
import { IAdminUserGridItem } from '../appBackend/useAdminsInfinite';
import { AdminUserRole } from '../appBackend/useProfileDetails';
import { hasAnyOfPermissions } from './useAppPermissions';
import { UserPermission } from './userPermission';

function canManageAdmin(admin: IAdminUserGridItem, canManage: UserPermission, canManageOfLowerRoles: UserPermission) {
  const canManageAnyAdmin = () => hasAnyOfPermissions(canManage);
  const canManageSystemOrSiteAdmin = () => hasAnyOfPermissions(canManageOfLowerRoles);
  const currentAdminIsSystemOrSiteAdmin = () => {
    const { userRole } = admin;
    return userRole === AdminUserRole.SiteAdmin
      || userRole === AdminUserRole.SystemAdmin;
  };
  return canManageAnyAdmin()
    || (canManageSystemOrSiteAdmin() && currentAdminIsSystemOrSiteAdmin());
}

export function canDeleteAdminUser(admin: IAdminUserGridItem): boolean {
  return canManageAdmin(admin, UserPermission.CanDeleteAdmins, UserPermission.CanDeleteAdminsOfLowerRoles);
}

export function canEditAdminUser(admin: IAdminUserGridItem): boolean {
  return canManageAdmin(admin, UserPermission.CanEditAdmins, UserPermission.CanEditAdminsOfLowerRoles);
}

export function hasAccessToSite(
  site: ISite,
  siteSubset: string[],
  basePermissions: UserPermission[],
  siteSubsetPermissions: UserPermission[],
): boolean {
  const canManageAnySite = () => hasAnyOfPermissions(...basePermissions);
  const canManageSiteSubset = () => hasAnyOfPermissions(...siteSubsetPermissions);
  const currentSiteIsSiteSubset = () => siteSubset.includes(site.id);
  return canManageAnySite()
    || (canManageSiteSubset() && currentSiteIsSiteSubset());
}

export function canViewSite(site: ISite, siteSubset: string[]): boolean {
  return hasAccessToSite(
    site,
    siteSubset,
    [UserPermission.CanViewSites],
    [UserPermission.CanViewSitesForSiteSubset],
  );
}

export function canEditSite(site: ISite, siteSubset: string[]): boolean {
  return hasAccessToSite(site, siteSubset, [UserPermission.CanEditSite], [UserPermission.CanEditSiteForSiteSubset]);
}

export function canDeleteSite(site: ISite, siteSubset: string[]): boolean {
  return hasAccessToSite(
    site,
    siteSubset,
    [UserPermission.CanDeleteSite],
    [],
  );
}

export function canCreateSite(site: ISite, siteSubset: string[]): boolean {
  return hasAccessToSite(
    site,
    siteSubset,
    [UserPermission.CanCreateSite],
    [],
  );
}
