import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';
import { useLocation, useRoute } from 'wouter';
import SystemTabsHeader from './components/SystemTabsHeader';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { IAccountCharges, useCustomerPricing } from '../../shared/appUIFramework/appBackend/useAccountCharges';
import './SystemTabsCosts.scss';
import { AccountCharges } from '../../shared/appUIFramework/components/AppAccountChargers';
import { Costs } from '../../shared/appUIFramework/components/AppCosts';
import styles from '../payment/PaymentOverview.module.scss';
import AppTablePagination from '../../shared/appUIFramework/components/AppTablePagination/AppTablePagination';
import { getTotalPages } from '../../shared/appUIFramework/dataTransformers/getTotalPages';
import {
  useSiteCostsHistoryInfinite,
  useSiteCostsHistoryTotalNumber,
} from '../../shared/appUIFramework/appBackend/useSiteCostHistory';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { CurrencyCodeString, getTotalUnitsPrice } from '../../shared/appUIFramework/appBackend/useInvoices';
import { roundAsCurrencyOnUI } from '../../shared/jsUtils/roundToDecimals';
import { ISiteInfo } from './models/ISiteInfo';
import { useSiteInfo } from '../../shared/appUIFramework/appBackend/useSiteInfo';
import { PAGE_SIZE } from '../../shared/appUIFramework/hooks/useInfiniteWithPagination';
import { isScrollVisible } from '../../styles';

function getMonth(idx: number): string {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx);

  const locale = 'en-us';
  return objDate.toLocaleString(locale, { month: 'long' });
}

function getChargesForSiteCosts(site: ISiteInfo): IAccountCharges {
  const { costs } = useCustomerPricing();
  return {
    growth: 0,
    monthlyCharge: getTotalUnitsPrice(roundAsCurrencyOnUI(costs?.value ?? 0), site?.registeredAppUsers),
    currencyCode: costs?.currency ?? CurrencyCodeString.GBP,
  };
}

export default function SystemTabsCosts() {
  const [, params] = useRoute('/systems/:siteId/costs');
  const [, setLocation] = useLocation();

  const { siteInfo } = useSiteInfo(params?.siteId || '');
  const charges = getChargesForSiteCosts(siteInfo!);
  const { t } = useTranslation();

  const historyHeaderRowRef = useRef<HTMLDivElement | null>(null);
  const historyRowsRef = useRef<HTMLDivElement | null>(null);
  const siteHistoryTotalNumber = useSiteCostsHistoryTotalNumber({});
  const totalPages = getTotalPages(PAGE_SIZE, siteHistoryTotalNumber);
  const {
    items: siteHistory, page, setPage, loading: siteHistoryLoading,
  } = useSiteCostsHistoryInfinite(siteInfo?.id, totalPages, {
    scrollRef: historyRowsRef,
  });

  const goToInvoice = (invoiceId: string) => {
    setLocation(`/payment/overview/${invoiceId}`);
  };

  const isLoading = siteHistoryLoading || !charges || !siteHistory;

  useEffect(() => {
    if (!historyRowsRef.current) {
      return;
    }

    if (isScrollVisible(historyRowsRef.current)) {
      historyRowsRef.current.style.paddingRight = '17px';
      historyHeaderRowRef.current!.style.paddingRight = '45px';
    }
  }, [isLoading, siteHistory]);

  return (
    <>
      <SystemTabsHeader activeTab="costs" />
      <div className="app-content">
        <div className="site-costs-container app-d-flex app-h-100pcnt">
          <AppShowLoading showLoading={isLoading}>
            <div className="site-costs-left app-pb-33 app-flex-1">
              <div className="app-paper">
                <div className="app-paper-title">
                  {t('ViewSiteCost_SiteCharges')}
                </div>
                <div className="app-d-flex">
                  <div className="app-flex-1">
                    <AccountCharges
                      accountCharges={charges!}
                      circleTopKey="ViewSiteCost_SiteCharges"
                      circleBottomKey="Home_EstimatedNextBill_A_Month"
                      showGrowth={false}
                    />
                  </div>
                  <div className="app-flex-1 app-pt-10 app-pl-20">
                    <Costs value={siteInfo?.registeredAppUsers || 0} footerKey="ViewSiteCost_ActiveUsers" />
                  </div>
                </div>
              </div>
            </div>
            <div className="site-costs-right app-pb-33 app-flex-1">
              <div className={styles.invoiceContainer}>
                <div className={styles.invoiceHeader}>
                  <div>
                    {t('ViewSiteCost_History')}
                  </div>
                </div>
                <div className="app-table">
                  <div
                    ref={historyHeaderRowRef}
                    className="app-table-header-row costsTableHeader costs"
                  >
                    <div>
                      {t('ViewSiteCost_Month')}
                    </div>
                    <div>
                      {t('ViewSiteCost_Charges')}
                    </div>
                    <div>
                      {t('ViewSiteCost_ActiveUsers')}
                    </div>
                    <div />
                  </div>
                  <div
                    ref={historyRowsRef}
                    className={`app-flex-vertical-scrollable ${styles.invoicesList}`}
                  >
                    {siteHistory && siteHistory.map((item) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
                      <div
                        className="app-table-content-row app-d-flex app-align-items-center app-justify-content-between costs"
                        key={item.id}
                        role="row"
                        onClick={() => goToInvoice(item.id)}
                      >
                        <div>
                          {getMonth(item.invoiceMonth)}
                          {' '}
                          {item.invoiceYear}
                        </div>
                        <div>{formatMoney(item.currencyCode, item.chargesAmount)}</div>
                        <div>{item.activeUsers}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.paginationContainer}>
                <div
                  className="app-w-50pcnt app-mt-26 app-d-flex app-align-items-center app-justify-content-center"
                >
                  <AppTablePagination
                    activePage={page}
                    totalPages={totalPages}
                    onPageSelect={setPage}
                  />
                </div>
              </div>
            </div>
          </AppShowLoading>
        </div>
      </div>
    </>
  );
}
