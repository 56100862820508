import { useTranslation } from 'react-i18next';
import { ReactComponent as SystemSaveError } from '../../assets/icons/System Save Error.svg';
import { closePopup } from '../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';

export default function ProfileDeleteErrorPopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);

  return (
    <AppPopupLayout
      extraClass="app-popup-delete-user"
      headerTitle="DeleteUser_DeleteAUser"
      onHeaderClose={cancel}
      popupBody={(
        <>
          <SystemSaveError />
          <span className="app-font-20 app-uppercase app-weight-600">{ t('Popup_SystemError') }</span>
          <span className="app-font-20">{ t('Popup_ThereWasAnErrorWithDeletingUserPleaseTryAgain')}</span>
        </>
      )}
    />
  );
}
