import { useTranslation } from 'react-i18next';
import { B2CLangCode } from '../../shared/appUIFramework/constants/B2CLangCode';
import { goToLoginPage } from '../../shared/backend/auth/auth';
import SignUpPersonalInfoLayout from './components/SignUpPersonalInfoLayout';
import './SignUpVatInstallerSuccess.scss';
import { ReactComponent as PlainIcon } from '../../assets/EmailSent.svg';

export default function SignUpVatInstallerSuccess() {
  const { t, i18n } = useTranslation();
  const goToLogin = () => goToLoginPage(i18n.language as B2CLangCode, true);

  return (
    <SignUpPersonalInfoLayout hideTitle currentStep={4}>
      <div className="app-signup-success">
        <PlainIcon />
        <span>{t('ThankYouForSignInUp')}</span>
        <button onClick={goToLogin} type="button" className="app-button app-primary-button">{t('BackToLogin')}</button>
      </div>
    </SignUpPersonalInfoLayout>
  );
}
