import { KeyedMutator } from 'swr';
import { IUserData } from '../../../routes/systems/models/IUserData';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { httpGetJson } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useCompany } from './useCompany';

export function useEntryUser(siteId?: string, entryUserId?: string): { entryUser?: IUserData, mutate?: KeyedMutator<IUserData>} {
  if (!entryUserId) {
    return { entryUser: undefined, mutate: undefined };
  }
  const company = useCompany();

  const { data, mutate } = useSWRAndHandleErrors<IUserData>(Urls.User(entryUserId, company?.id, siteId), httpGetJson);
  return { entryUser: data, mutate };
}
