import { useTranslation } from 'react-i18next';
import styles from '../../../routes/payment/PaymentOverview.module.scss';
import CostsIcon from '../../../assets/icons/Homescreen icons/Entry user app icon.svg';
import { formatMoney } from '../formatters/formatMoney';
import { CurrencyCodeString } from '../appBackend/useInvoices';

export function Costs(
  {
    value,
    headerKey,
    footerKey,
    currencyCode,
  } : {
    value: number,
    headerKey?: string,
    footerKey?: string,
    currencyCode?: CurrencyCodeString
  },
) {
  const { t } = useTranslation();

  return (
    <div className="app-h-100pcnt app-min-w-200">
      {
        headerKey && (
          <div className="app-paper-title">
              {t(headerKey)}
          </div>
        )
      }
      <div className="app-paper-body app-h-100pcnt">
        <div className={styles.costsContainer}>
          <div className="app-position-relative">
            <img src={CostsIcon} alt="costs icon" />
            <div className={styles.costsTextAmount}>{ currencyCode ? formatMoney(currencyCode, value) : value }</div>
          </div>
          {
            footerKey && (
              <div className="app-paper-footer app-pt-16">
                <span className="app-paper-footer-text app-uppercase">
                  {t(footerKey)}
                </span>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
