import { useState } from 'react';
import { useTranslation } from 'react-i18next';

enum MoreOptions {
  BulkDelete = 'BulkDelete'
}

export function useMoreOptions(): {options: MoreOptions[], getOptionLabel: (option: MoreOptions) => string} {
  const { t } = useTranslation();
  const [moreOptionsLabels] = useState<Record<MoreOptions, string>>({
    [MoreOptions.BulkDelete]: t('Table_BulkDelete'),
  });

  const options = Object.keys(moreOptionsLabels) as MoreOptions[];
  const getOptionLabel = (option: MoreOptions) => moreOptionsLabels[option];

  return { options, getOptionLabel };
}
