import { NotSuccessResponseError } from '../../../backend/errors/notSuccessResponseError';
import { showPopup } from '../../popup/AppPopup';
import ProfileCanNotDeletePopup from '../../../../routes/profile/ProfileCanNotDeletePopup';
import ProfileDeleteErrorPopup from '../../../../routes/profile/ProfileDeleteErrorPopup';
import { ApiErrorType } from './useSWRAndHandleErrors';

export async function handleAdminUserDeleteError(error: ApiErrorType, headerKey: string, message: string) {
  if (error instanceof NotSuccessResponseError && error.response.status === 400) {
    await showPopup(<ProfileCanNotDeletePopup message={message} headerKey={headerKey} />);
  } else {
    await showPopup(<ProfileDeleteErrorPopup />);
  }
}
