import { useLocation } from 'wouter';
import { useUnsavedPopup } from '../../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import { showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import SignUpUnsavedChangesPopup from '../components/SignUpUnsavedChangesPopup';
import { useSignUpState } from './useSignupState';

export const SignUpVatInstallerUnsavedChangesKey = 'SignUpVatInstaller';

export function useCancelVatInstallerSignUp() {
  const { init } = useSignUpState();
  const [, setLocation] = useLocation();
  useUnsavedPopup(SignUpVatInstallerUnsavedChangesKey, true);

  return {
    cancelVatInstallerSignUp: async () => {
      const shouldCancel = await showPopup(<SignUpUnsavedChangesPopup />);
      if (shouldCancel === true) {
        init();
        setLocation('/welcome');
      }
    },
  };
}
