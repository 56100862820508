import { useAppSwr } from '../appBackend/errorHandling/useSWRAndHandleErrors';
import { B2CLangCode } from './B2CLangCode';

export enum VatEvidenceType {
  None,
  VatNo,
  TaxCertificate,
}

export enum CountryCode {
  DE = 'DE',
  FR = 'FR',
  GB = 'GB',
  NL = 'NL',
  US = 'US',
  IRL = 'IE',
  BE = 'BE',
  LUX = 'LU',
  ZA = 'ZA',
  CAN = 'CA',
  SWE = 'SE',
  NOR = 'NO',
  DNK = 'DK',
}

interface ICountryConfiguration {
    countryId: number; // value required for internal apps during sign up
    countryCode: CountryCode; // country code or iso code
    mobileCodes: string[]; // should be array, because some countries in future may have multiple mobile codes
    name: string; // country name in english, e.g. United Kingdom
    vatEvidence?: VatEvidenceType;
    defaultLanguage: B2CLangCode;
}

const countries: ICountryConfiguration[] = [
  {
    countryId: 55,
    countryCode: CountryCode.DE,
    mobileCodes: ['49'],
    name: 'Germany',
    vatEvidence: VatEvidenceType.None,
    defaultLanguage: B2CLangCode.German,
  },
  {
    countryId: 73,
    countryCode: CountryCode.FR,
    mobileCodes: ['33'],
    name: 'France',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.French,
  },
  {
    countryId: 75,
    countryCode: CountryCode.GB,
    mobileCodes: ['44'],
    name: 'United Kingdom',
    vatEvidence: VatEvidenceType.None,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 163,
    countryCode: CountryCode.NL,
    mobileCodes: ['31'],
    name: 'Netherlands',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.Netherlands,
  },
  {
    countryId: 228,
    countryCode: CountryCode.US,
    mobileCodes: ['1'],
    name: 'United States',
    vatEvidence: VatEvidenceType.TaxCertificate,
    defaultLanguage: B2CLangCode.EnglishUS,
  },
  {
    countryId: 100,
    countryCode: CountryCode.IRL,
    mobileCodes: ['353'],
    name: 'Ireland',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 21,
    countryCode: CountryCode.BE,
    mobileCodes: ['32'],
    name: 'Belgium',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.French,
  },
  {
    countryId: 132,
    countryCode: CountryCode.LUX,
    mobileCodes: ['352'],
    name: 'Luxembourg',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 242,
    countryCode: CountryCode.ZA,
    mobileCodes: ['27'],
    name: 'South Africa',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 37,
    countryCode: CountryCode.CAN,
    mobileCodes: ['1'],
    name: 'Canada',
    vatEvidence: VatEvidenceType.TaxCertificate,
    defaultLanguage: B2CLangCode.EnglishUS,
  },
  {
    countryId: 194,
    countryCode: CountryCode.SWE,
    mobileCodes: ['46'],
    name: 'Sweden',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.Swedish,
  },
  {
    countryId: 164,
    countryCode: CountryCode.NOR,
    mobileCodes: ['47'],
    name: 'Norway',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
  {
    countryId: 57,
    countryCode: CountryCode.DNK,
    mobileCodes: ['45'],
    name: 'Denmark',
    vatEvidence: VatEvidenceType.VatNo,
    defaultLanguage: B2CLangCode.English,
  },
];

export const countryList = countries
  .sort((a, b) => a.name.localeCompare(b.name));
export const countryCodes = countryList.map(({ countryCode }) => countryCode);
export const countryNames = countryList.map(({ name }) => name);

export function isCountryCode(value: string) {
  return countryCodes.includes(value as CountryCode);
}

export function getCountryCodesByMobileCode(mobileCode: string): string[] {
  if (!mobileCode.startsWith('+')) {
    return [];
  }

  const codeWithoutPlus = mobileCode.replace('+', '');
  const countriesWithMobileCode = countryList.filter((countryInfo) => countryInfo.mobileCodes.includes(codeWithoutPlus));
  return countriesWithMobileCode.map((p) => p.countryCode);
}
export const mobileCodes = countryList.flatMap((country) => country.mobileCodes.map((p) => `+${p}`))
  .sort((a, b) => a.localeCompare(b));

export const listOfMobileCodesWithCountryCodeForDropdown = () => {
  const flattenedListOfCodesForDropdown = mobileCodes.flatMap((mobileCode) => getCountryCodesByMobileCode(mobileCode).map((countryCode) => ({
    value: countryCode,
    label: mobileCode,
  })));

  return flattenedListOfCodesForDropdown.filter((value, index) => flattenedListOfCodesForDropdown.findIndex((code) => code.value === value.value) === index);
};

export const listOfCountryNamesForDropdown = () => countryList.map(({ name, countryCode }) => ({
  value: countryCode,
  label: name,
}));

export function getMobileCodeByCountryCode(countryCode?: CountryCode): string {
  if (!countryCode) {
    return '';
  }

  const country = countryList.find((countryInfo) => countryInfo.countryCode === countryCode);
  return `+${country?.mobileCodes[0]}` || '';
}

export function getCountryName(countryCode?: CountryCode): string | undefined {
  return countryList.find((country) => country.countryCode === countryCode)?.name;
}

export function getCountryCode(countryName?: string): CountryCode | undefined {
  return countryList.find((country) => country.name === countryName)?.countryCode;
}

export function getCountryLanguageByCode(countryCode?: CountryCode): B2CLangCode | undefined {
  return countryList.find((country) => country.countryCode === countryCode)?.defaultLanguage;
}

export function getCountryLanguageByCountryId(countryId?: number): string | undefined {
  return countryList.find((country) => country.countryId === countryId)?.defaultLanguage;
}

export function getCountryCodeByCountryId(countryId?: number): CountryCode | undefined {
  return countryList.find((country) => country.countryId === countryId)?.countryCode;
}

export function getCountryCodeByCountryName(countryName?: string): CountryCode | undefined {
  return countryList.find((country) => country.name === countryName)?.countryCode;
}

export function getCountryId(countryCode?: CountryCode): number | undefined {
  return countryList.find((country) => country.countryCode === countryCode)?.countryId;
}

export function getCountryCodeForMobileCodeInForm(
  mobileCode: string,
  filteredMobileCodes: string[],
  index: number,
) {
  const codes = getCountryCodesByMobileCode(mobileCode);
  const mobileCodeDuplicates = filteredMobileCodes.filter((c) => c === mobileCode);
  const firstDuplicateIndexInFilteredArray = filteredMobileCodes.indexOf(mobileCodeDuplicates[0]);
  const isMobileCodeSharedBetweenCountries = mobileCodeDuplicates.length > 1;
  return isMobileCodeSharedBetweenCountries
    ? codes[index! - firstDuplicateIndexInFilteredArray]
    : codes[0];
}

function getRegisteredCountryCodeOrDefault(countryCode: CountryCode) {
  const countryCodeRegistered = countryCodes.some((value) => countryCode === value);

  return countryCodeRegistered ? countryCode : CountryCode.GB;
}

export function useDefaultCountryCode(countryCode?: CountryCode) {
  // TODO: to save time the external api is used, when have time make sense to replace it with our implementation
  const { data: countryInfo } = useAppSwr('https://hutils.loxal.net/whois', async (url: string) => {
    try {
      if (countryCode) {
        return { countryIso: countryCode };
      }

      const response = await fetch(url, { method: 'GET' });
      return response.json();
    } catch {
      // TODO: add some kind of server logging
      return '';
    }
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return countryInfo
    ? getRegisteredCountryCodeOrDefault(countryInfo?.countryIso)
    : undefined;
}
