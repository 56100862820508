import { RegistrationStatus } from '../constants/RegistrationStatus';

export function formatRegistrationStatus(registrationStatus: RegistrationStatus) {
  switch (registrationStatus) {
    case RegistrationStatus.Unknown:
      return 'Unknown';
    case RegistrationStatus.Registered:
      return 'Paired';
    case RegistrationStatus.Pending:
      return 'User_RegistrationPending';
    case RegistrationStatus.Unregistered:
      return 'Unpaired';
    default:
      return '-';
  }
}
