import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailSentIcon } from '../../../assets/EmailSent.svg';
import { AppPopupLayout } from '../popup/AppPopupLayout';
import './NewUserEmailConfirmationPopup.scss';
import { closePopup } from '../popup/AppPopup';

export default function NewUserEmailConfirmationPopup({ email, confirmationText, showStatusText }:
{ email: string,
  confirmationText?: string,
  showStatusText?: boolean
}) {
  const { t } = useTranslation();
  return (
    <AppPopupLayout
      extraClass="app-popup-connect"
      onHeaderClose={closePopup}
      headerTitle={!confirmationText ? 'AddUser_EmailConfirmation' : undefined}
      popupBody={(
        <>
          <EmailSentIcon />
          {confirmationText && (
          <span className="app-font-20 app-uppercase app-weight-600">
            {confirmationText}
          </span>
          )}
          { showStatusText && (
            <span className="app-font-20 app-text-align-center">
              <span className="app-font-20 app-weight-600">{t('AddUser_PleaseNote')}</span>
                {t('AddUser_EmailConfirmationStatusPendingNote')}
            </span>
          )}
          <span className="app-font-20 app-text-align-center ">
            {t('AddUser_EmailSentSuccessfully')}
            <div className="app-font-20 app-text-align-center app-new-user-popup-email">{email}</div>
          </span>
        </>
      )}
    />
  );
}
