import PaymentHeader from './components/PaymentHeader';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useTermsAndConditions } from '../../shared/appUIFramework/appBackend/useTermsAndConditions';
import styles from './PaymentTermsAndConditions.module.scss';

export default function PaymentTermsAndConditions() {
  const termsAndConditionsHtml = useTermsAndConditions();

  return (
    <>
      <PaymentHeader activeTab="terms" />
      <div className="app-content app-content-with-tabs">
        <AppShowLoading showLoading={termsAndConditionsHtml === null}>
          { termsAndConditionsHtml && (
          <>
            <div className={`app-paper app-tcs-and-cs ${styles.paymentTermsContainer} ${styles.paperSquare} `}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: termsAndConditionsHtml || '' }} />
            </div>
          </>
          )}
        </AppShowLoading>
      </div>
    </>
  );
}
