import { useTranslation } from 'react-i18next';
import { ReactComponent as DisablePaymentSuccessIcon } from '../../../assets/icons/Automatic payments disabled.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function DisableAutomaticPaymentsSuccessPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      headerTitle="AutomaticPayments"
      extraClass="app-popup-connect"
      onHeaderClose={closePopup}
      popupBody={(
        <>
          <DisablePaymentSuccessIcon className="app-mt-22 app-w-133 app-h-118" />
          <span className="app-font-20 app-uppercase app-weight-600">
            {t('AutomaticPaymentsDisabled')}
          </span>
          <span className="app-popup-info">
            {t('DisablingAutomaticPaymentsSuccess')}
          </span>
        </>
      )}
    />
  );
}
