import { useRoute } from 'wouter';
import { ReactComponentElement } from 'react';
import { StatusCodesEnum } from '../shared/appUIFramework/constants/StatusCodesEnum';
import { ReactComponent as ErrorRobot403Graphic } from '../assets/errorGraphics/Error_Robot_403.svg';
import { ReactComponent as ErrorRobot404Graphic } from '../assets/errorGraphics/Error_Robot_404.svg';
import { ReactComponent as ErrorRobot401Graphic } from '../assets/errorGraphics/Error_Robot_401.svg';
import { ReactComponent as ErrorRobot503Graphic } from '../assets/errorGraphics/Error_Robot_503.svg';
import { ReactComponent as ErrorRobot500Graphic } from '../assets/errorGraphics/Error_Robot_500.svg';
import ErrorPage from '../shared/appUIFramework/components/ErrorPage';

interface IErrorPageData {
  errorCode: string;
  errorMessage: string;
  errorGraphic: ReactComponentElement<any>
}

const StatusCodePages: Record<StatusCodesEnum | any, IErrorPageData> = {
  [StatusCodesEnum.BadRequest]: {
    errorGraphic: <ErrorRobot500Graphic />,
    errorCode: 'Errors_500',
    errorMessage: 'Errors_500Message',
  },
  [StatusCodesEnum.Unauthorized]: {
    errorGraphic: <ErrorRobot401Graphic />,
    errorCode: 'Errors_401',
    errorMessage: 'Errors_401Message',
  },
  [StatusCodesEnum.NotFound]: {
    errorGraphic: <ErrorRobot404Graphic />,
    errorCode: 'Errors_404',
    errorMessage: 'Errors_404Message',
  },
  [StatusCodesEnum.RequestTimeout]: {
    errorGraphic: <ErrorRobot500Graphic />,
    errorCode: 'Errors_500',
    errorMessage: 'Errors_500Message',
  },
  [StatusCodesEnum.ServerErrorInternal]: {
    errorGraphic: <ErrorRobot500Graphic />,
    errorCode: 'Errors_500',
    errorMessage: 'Errors_500Message',
  },
  [StatusCodesEnum.ServerErrorBadGateway]: {
    errorGraphic: <ErrorRobot500Graphic />,
    errorCode: 'Errors_500',
    errorMessage: 'Errors_500Message',
  },
  [StatusCodesEnum.ServerErrorServiceUnavailable]: {
    errorGraphic: <ErrorRobot503Graphic />,
    errorCode: 'Errors_503',
    errorMessage: 'Errors_503Message',
  },
  [StatusCodesEnum.ServerErrorGatewayTimeout]: {
    errorGraphic: <ErrorRobot500Graphic />,
    errorCode: 'Errors_500',
    errorMessage: 'Errors_500Message',
  },
  [StatusCodesEnum.Forbidden]: {
    errorGraphic: <ErrorRobot403Graphic />,
    errorCode: 'Errors_403',
    errorMessage: 'Errors_403Message',
  },
};

export default function AppErrorPage() {
  const [, params] = useRoute('/error/:code');
  const code = params?.code ?? StatusCodesEnum.NotFound;
  const displayDataForError = StatusCodePages[code];
  return (
    <ErrorPage errorCode={displayDataForError.errorCode} errorMessage={displayDataForError.errorMessage} errorGraphic={displayDataForError.errorGraphic} />
  );
}
