import { useTranslation } from 'react-i18next';
import { useLocation, useRoute } from 'wouter';
import {
  ReactComponent as BackwardBreadCrumpIcon,
} from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import { useSiteInfo } from '../../shared/appUIFramework/appBackend/useSiteInfo';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import SystemForm from './components/SystemForm';
import { useAddressBook } from '../../shared/appUIFramework/appBackend/useAddressBook';

export default function System() {
  const { t } = useTranslation();

  const [, params] = useRoute('/systems/:siteId');
  const siteId = params?.siteId;
  const { siteInfo } = useSiteInfo(params?.siteId || '');

  const [, setLocation] = useLocation();
  const goToSystemsSystemDetailsPage = () => setLocation(`/systems/${params?.siteId}/system-details`);
  const goToSite = () => {
    if (siteId) {
      setLocation(`/systems/${siteId}/entry-users`);
    }
  };
  // preload address book for entry user form
  useAddressBook(siteInfo);

  return (
    <>
      <AppContentToolbar>
        <AppShowLoading
          inline
          showLoading={!siteInfo}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <span className="app-toolbar-breadcrumb app-hover-pointer" onClick={goToSite}>{ siteInfo?.siteName }</span>
          <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
          <span className="app-toolbar-breadcrumb">{ t('SiteAdd_EditSystemDetails') }</span>
        </AppShowLoading>
      </AppContentToolbar>
      <SystemForm siteId={params?.siteId} goBack={goToSystemsSystemDetailsPage} />
    </>
  );
}
