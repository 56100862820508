import { useEffect } from 'react';
import { KeyedMutator, useSWRConfig } from 'swr';
import { httpGetJson } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';

export interface IWorldpayData {
  AccessKey: string;
  ProfileId: string;
  TransactionUuid: string;
  SignedFieldname: string;
  UnsignedFieldname: string;
  MerchantDefinedData1: string;
  MerchantDefinedData6: string;
  ConsumerId: string;
  SignedDateTime: string;
  Locale: string;
  TransactionType: string;
  ReferenceNumber: string;
  Amount: number;
  Currency: string;
  Signature: string;
  SkipBinLookup: string;
  IframeUrl: string
}

export function useWorldpayDataForOneTimePayment(invoiceId: number): { worldpayData: IWorldpayData | undefined } {
  const url = Urls.GetWorldpayDataForOneTimePayment(invoiceId);
  const { data } = useSWRAndHandleErrors<IWorldpayData>(url, httpGetJson, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
  });

  const { cache } = useSWRConfig();
  useEffect(() => () => {
    cache.delete(url);
  }, []);

  return {
    worldpayData: data,
  };
}

export function useWorldpayDataForAutomaticPayments(customerReference: string): {
  worldpayData: IWorldpayData | undefined;
  mutateWorldpayData: KeyedMutator<IWorldpayData>,
  isValidating: boolean
} {
  const url = Urls.GetWorldpayDataForAutomaticPayment(customerReference);
  const { data, mutate, isValidating } = useSWRAndHandleErrors<IWorldpayData>(url, httpGetJson, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
    revalidateOnMount: true,
  });

  const { cache } = useSWRConfig();
  useEffect(() => () => {
    cache.delete(url);
  }, []);

  return {
    worldpayData: data,
    mutateWorldpayData: mutate,
    isValidating,
  };
}

export interface IAutoPaymentEventData {
  reqCardNumber: string;
  cardType: string;
}

export function useOnWorldpayOperationResult(
  onSuccess: (autoPaymentEventData: IAutoPaymentEventData) => Promise<void> | void,
  onFailure: () => Promise<void> | void,
) {
  useEffect(() => {
    const listener = async (event: MessageEvent) => {
      if (event.data?.type === 'worldpayOperation') {
        if (event.data.data.isSuccess === true) {
          await onSuccess(event.data?.data || {
            reqCardNumber: '',
            cardType: '',
          });
        } else {
          await onFailure();
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onSuccess]);
}
