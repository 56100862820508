import { useEffect } from 'react';
import { useCurrentAdmin } from './appBackend/useAdminsInfinite';
import { useLogout } from './hooks/useLogout';

const envImpersonationLogoutSeconds = +(process.env.REACT_APP_IMPERSONATION_LOGOUT_SECONDS || 0);
const defaultMinutes = 30;
const secondsInMinute = 60;
const msInSeconds = 1000;
const IMPERSONATION_LOGOUT_MS = envImpersonationLogoutSeconds
  ? envImpersonationLogoutSeconds * msInSeconds
  : defaultMinutes * secondsInMinute * msInSeconds;

export function useLogoutOnImpersonationTimeout() {
  const { admin: currentAdmin } = useCurrentAdmin();
  const logout = useLogout();

  useEffect(() => {
    const isDataLoaded = !!currentAdmin;
    if (!isDataLoaded) {
      return () => {};
    }

    const isImpersonating = !!currentAdmin?.impersonationTriggeredById;
    if (!isImpersonating) {
      return () => {};
    }

    if (!currentAdmin.createDate) {
      throw new Error('createDate is not defined');
    }

    const createDateMs = new Date(currentAdmin.createDate).getTime();
    const shouldLogoutAt = createDateMs + IMPERSONATION_LOGOUT_MS;
    const nowMs = new Date().getTime();
    const msLeftToLogout = shouldLogoutAt - nowMs;
    if (msLeftToLogout <= 0) {
      logout();
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      logout();
    }, msLeftToLogout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentAdmin]);
}
