import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Text field icons/Email  icon.svg';
import { closePopup } from '../popup/AppPopup';
import { AppPopupLayout } from '../popup/AppPopupLayout';

export default function AppRequestFailedPopup() {
  const { t } = useTranslation();
  const goBack = () => closePopup(true);
  const discardChanges = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="Popup_Error"
      popupBody={(
        <>
          <EmailIcon className="app-w-160 app-h-128" />
          <span className="app-d-inline-block app-mw-500">{ t('Popup_SomethingWentWrong') }</span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={discardChanges} className="app-secondary-button app-popup-left-button">
            { t('Popup_DiscardChanges') }
          </button>
          <button type="button" onClick={goBack} className="app-primary-button app-popup-right-button">
            { t('Popup_GoBack') }
          </button>
        </>
      )}
    />
  );
}
