import styles from './AppTablePagination.module.scss';
import { ReactComponent as PreviousArrowActiveIcon } from '../../../../assets/icons/Previous Arrow active.svg';
import { ReactComponent as PreviousArrowDisabledIcon } from '../../../../assets/icons/Previous arrow disabled.svg';
import { ReactComponent as NextArrowActiveIcon } from '../../../../assets/icons/Next Arrow active.svg';
import { ReactComponent as NextArrowDisabledIcon } from '../../../../assets/icons/Next arrow disabled.svg';
import callIfEnterKeyPressed from '../../../jsUtils/callIfEnterKeyPressed';

const MIN_PAGE_INDEX = 0;
const MAX_PAGES_WITHOUT_DOTS = 4;
const VALUES_SHOWN_BETWEEN_DOTS = 3;

export default function AppTablePagination({
  totalPages,
  activePage,
  onPageSelect,
}: { totalPages: number, activePage: number, onPageSelect: (page: number) => void }) {
  const pagesCount = totalPages > 0 ? totalPages : 1;
  const maxPageIndexValue = pagesCount - 1;
  const activePageIndex = activePage - 1;
  const setPreviousPage = () => {
    if (activePageIndex > MIN_PAGE_INDEX) {
      onPageSelect(activePageIndex - 1 + 1);
    }
  };
  const setNextPage = () => {
    if (activePageIndex < maxPageIndexValue) {
      onPageSelect(activePageIndex + 1 + 1);
    }
  };

  const disableLeftArrow = activePageIndex === MIN_PAGE_INDEX || totalPages === 1;
  const disableRightArrow = activePageIndex === maxPageIndexValue || totalPages === 1;

  const renderPageIndex = (pageIndex: number) => (pageIndex > -1 && pageIndex < pagesCount ? (
    <div
      key={pageIndex}
      tabIndex={0}
      role="button"
      className={`${styles.appTablePaginationPage} ${pageIndex === activePageIndex || totalPages === 1
        ? styles.appTablePaginationPageActive
        : ''} ${totalPages === 1 ? styles.appTablePaginationPageSingle : ''}`}
      onClick={() => onPageSelect(pageIndex + 1)}
      onKeyDown={callIfEnterKeyPressed(() => onPageSelect(pageIndex + 1))}
    >
      {pageIndex + 1}
    </div>
  ) : <></>);

  const renderPages = (...pageIndexes: number[]) => pageIndexes.map(renderPageIndex);

  const showDots = pagesCount > MAX_PAGES_WITHOUT_DOTS;
  const showDotsAfterFirstPage = showDots && activePageIndex >= VALUES_SHOWN_BETWEEN_DOTS;
  const showDotsBeforeLastPage = showDots && pagesCount - activePageIndex > VALUES_SHOWN_BETWEEN_DOTS - 1;

  return (
    <div className={styles.appTablePagination}>
      <div>
        {disableLeftArrow
          ? <PreviousArrowDisabledIcon className={styles.appTablePaginationDisabled} />
          : <PreviousArrowActiveIcon onClick={setPreviousPage} />}
      </div>
      {!showDots && renderPages(...Array(pagesCount).keys())}
      {showDotsAfterFirstPage && renderPageIndex(0)}
      {showDotsAfterFirstPage && <div className={styles.appTablePaginationPage}>...</div>}
      {!showDotsAfterFirstPage && showDotsBeforeLastPage && renderPages(0, 1, 2)}
      {showDotsAfterFirstPage && showDotsBeforeLastPage && renderPages(activePageIndex - 1, activePageIndex, activePageIndex + 1)}
      {!showDotsBeforeLastPage && showDotsAfterFirstPage && renderPages(pagesCount - 3, pagesCount - 2, pagesCount - 1)}
      {showDotsBeforeLastPage && <div className={styles.appTablePaginationPage}>...</div>}
      {showDotsBeforeLastPage && renderPageIndex(maxPageIndexValue)}
      <div>
        {disableRightArrow
          ? <NextArrowDisabledIcon className={styles.appTablePaginationDisabled} />
          : <NextArrowActiveIcon onClick={setNextPage} />}
      </div>
    </div>
  );
}
