import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as EmailIcon } from '../../assets/icons/Text field icons/Email  icon.svg';
import { ReactComponent as NameIcon } from '../../assets/icons/Text field icons/Name Icon V2.svg';
import AccessIcon from '../../assets/icons/Access level.svg';
import {
  deleteAdmin,
  useCurrentAdmin,
} from '../../shared/appUIFramework/appBackend/useAdminsInfinite';
import {
  getUserRoleTranslationKey,
  IProfile,
} from '../../shared/appUIFramework/appBackend/useProfileDetails';
import { appSubmitAndHandleErrors } from '../../shared/appUIFramework/appForm/appSubmitAndHandleErrors';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useLogout } from '../../shared/appUIFramework/hooks/useLogout';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import ProfileDeleteConfirmationPopup from './ProfileDeleteConfirmationPopup';
import ProfileDeleteSuccessPopup from './ProfileDeleteSuccessPopup';
import './Profile.scss';
import {
  handleAdminUserDeleteError,
} from '../../shared/appUIFramework/appBackend/errorHandling/handleAdminUserDeleteError';
import {
  NAME_MAX_LENGTH, NO_SPECIAL_CHARACTERS_PATTERN,
} from '../../shared/appUIFramework/constants/constants';
import { useUnsavedPopup } from '../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import { getAdminUserFormSubmitHandler } from '../admin/components/AdminForm.methods';

type TypeAdminUserFormModel = IProfile & {
  translatedRole: string;
}

export default function Profile() {
  const { t } = useTranslation();
  const handleLogout = useLogout();
  const {
    register, handleSubmit, reset, formState: {
      isSubmitting, errors, isValid: isFormValid, isDirty,
    },
  } = useForm<TypeAdminUserFormModel>({
    mode: 'onChange',
  });

  const { admin: profile, mutate } = useCurrentAdmin();
  useEffect(() => {
    if (profile) {
      reset({
        ...profile,
        translatedRole: t(getUserRoleTranslationKey(profile.userRole)) as any,
      });
    }
  }, [profile]);

  const deleteProfile = async () => {
    const shouldDelete = await showPopup(<ProfileDeleteConfirmationPopup />);
    if (shouldDelete) {
      try {
        if (!profile?.id) {
          // eslint-disable-next-line no-console
          console.error('Profile id is not defined');
          return;
        }
        await deleteAdmin(profile.id);
        await showPopup(<ProfileDeleteSuccessPopup userName={`${profile?.firstName} ${profile?.lastName}`} />);
        await handleLogout();
      } catch (error) {
        await handleAdminUserDeleteError(error, 'DeleteUser_DeleteAUser_PaymentAdmin', t('YouMustCreateAnotherPaymentAdminToDeleteYourAccount'));
      }
    }
  };

  useUnsavedPopup('profileUnsavedPopup', isDirty);

  const [, setLocation] = useLocation();
  const goHome = () => setLocation('/');

  return (
    <>
      <AppContentToolbar>
        <div className="app-toolbar-breadcrumb">
          <span>{t('Toolbar_Profile')}</span>
        </div>
      </AppContentToolbar>
      <div className="app-content app-py-42 app-pl-60 app-pr-169">
        <AppShowLoading showLoading={!profile || isSubmitting}>
          <form
            onSubmit={appSubmitAndHandleErrors({
              submit: getAdminUserFormSubmitHandler({ isNew: false, goBack: () => {} }), handleSubmit, reset, goBack: () => { }, mutate,
            })}
            className="app-d-flex-vertical-100"
          >
            <div className="app-flex-grow-1 app-profile-form-inner">
              <div className="app-form-section-title">{t('Profile_Details')}</div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.firstName}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  <span>{t('SiteAdd_FirstName')}</span>
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <NameIcon />
                  </div>
                  <input
                    {...register('firstName', {
                      maxLength: NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      required: true,
                    })}
                    type="text"
                    className="app-form-input"
                    placeholder={t('SiteAdd_FirstName')}
                  />
                </div>
                {errors.firstName && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.firstName.type === 'required' && t('Form_FieldRequired')}
                    {errors.firstName.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.firstName.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: NAME_MAX_LENGTH })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.lastName}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('SiteAdd_LastName')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <NameIcon />
                  </div>
                  <input
                    {...register('lastName', {
                      required: true,
                      maxLength: NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                    })}
                    type="text"
                    className="app-form-input"
                    placeholder={t('SiteAdd_LastName')}
                  />
                </div>
                {errors.lastName && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.lastName.type === 'required' && t('Form_FieldRequired')}
                    {errors.lastName.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.lastName.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: NAME_MAX_LENGTH })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('SiteAdd_EmailAddress')}
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <EmailIcon />
                  </div>
                  <input
                    {...register('email')}
                    disabled
                    type="text"
                    className="app-form-input"
                    placeholder={t('SiteAdd_EmailAddress')}
                  />
                </div>
              </div>
              <div className="app-form-control">
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('Profile_AccessLevel')}
                </div>
                <div
                  className="app-form-control-input"
                >
                  <div className="app-form-control-prefix">
                    <img src={AccessIcon} alt="form icon" />
                  </div>
                  <input
                    {...register('translatedRole')}
                    disabled
                    type="text"
                    className="app-form-input"
                  />
                </div>
              </div>
              <div className="app-form-control-actions">
                <button
                  type="button"
                  className="app-warn-button"
                  onClick={deleteProfile}
                >
                  {t('Profile_DeleteUser')}
                </button>
              </div>
            </div>
            <div className="app-form-control-actions">
              <button
                onClick={goHome}
                className="app-secondary-button"
                type="button"
              >
                {t('Form_Cancel')}
              </button>
              <button
                disabled={!isFormValid || isSubmitting || !isDirty}
                className="app-primary-button"
                type="submit"
              >
                {t('Form_Save')}
              </button>
            </div>
          </form>
        </AppShowLoading>
      </div>
    </>
  );
}
