import { useCallback, useState } from 'react';
import SearchIcon from '../../../assets/icons/Text field icons/Search icon.svg';
import CloseIcon from '../../../assets/icons/Text field icons/Close icon.svg';
import { debounce } from '../../jsUtils/debounce';
import { SEARCH_DEBOUNCE_TIME_MS } from '../constants/constants';

export default function AppSearch({ onSearch, placeholder }: { onSearch: (search: string) => any, placeholder: string }) {
  const [searchValue, setSearchValue] = useState('');

  const triggerSearch = useCallback(debounce((search: string) => {
    onSearch(search);
  }, SEARCH_DEBOUNCE_TIME_MS), [setSearchValue, onSearch]);

  const resetSearch = useCallback(() => {
    setSearchValue('');
    triggerSearch('');
  }, [setSearchValue, triggerSearch]);

  return (
    <div className="app-search">
      <img src={SearchIcon} alt="Search" className="app-search-search-icon" />
      <input
        type="text"
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          triggerSearch(e.target.value);
        }}
        className="app-input-text app-input-text-search"
        placeholder={placeholder}
      />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role,jsx-a11y/click-events-have-key-events */ }
      {searchValue && <img src={CloseIcon} alt="Clear" role="button" className="app-search-clear-icon" onClick={resetSearch} />}
    </div>
  );
}
