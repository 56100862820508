/* eslint-disable react/destructuring-assignment */
import {
  ReactNode,
  useRef,
  useState,
  ReactElement,
  MutableRefObject,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getUrlWithQueryParams } from '../../backend/http/http';
import { AccountStatusUIState, applyFunctionalityLimitedStyles, useAccountInfo } from '../appBackend/useAccountInfo';
import { AppPermissionComponentGuard } from '../appPermisions/AppPermisionGuard';
import { UserPermission } from '../appPermisions/userPermission';
import { getTotalPages } from '../dataTransformers/getTotalPages';
import { useBulkDelete } from '../hooks/useBulkDelete';
import { useInfiniteWithPagination } from '../hooks/useInfiniteWithPagination';
import { useMoreOptions } from '../hooks/useMoreOptions';
import { useShouldUseMobileColumns } from '../hooks/useShouldUseMobileColumns';
import { showPopup } from '../popup/AppPopup';
import AppPopupMenu from './AppPopupMenu';
import AppSearch from './AppSearch';
import AppTooltip, { AppTooltipPosition } from './AppTooltip';
import { AppFilterIcon, AppSortIcon } from './SortAndFilterIcons';
import MoreOptionsIcon from '../../../assets/icons/Button-Control icons/More options button.svg';
import AppTablePagination from './AppTablePagination/AppTablePagination';
import AppShowLoading from './AppShowLoading';
import AppDeleteButton from './AppDeleteButton';
import { isScrollVisible } from '../../../styles';
import { ApiErrorType } from '../appBackend/errorHandling/useSWRAndHandleErrors';

interface IQueryParamConfig<TQueryParams> {
  key: keyof TQueryParams;
  values: Array<[value: string | number | undefined, label: string | ReactElement]>;
  type: 'sort' | 'filter';
}

export interface IColumn<TModel, TQueryParams> {
  title: string;
  queryParamSetting?: IQueryParamConfig<TQueryParams>;
  tooltip?: ReactNode;
  isHiddenOnMobile?: boolean;
  getValue?: (item: TModel) => string;
  getIcon?: (item: TModel) => ReactNode;
  getValueClass?: (item: TModel) => string;
}

export interface IAppTablePageProps<TModel, TQueryParams, TTotals> {
  normalModeActions: ReactElement;
  inTabsShell: boolean;
  searchText: string;
  table: {
    columns: IColumn<TModel, TQueryParams>[];
    goToItem: (item: TModel) => void;
    query: {
      itemsUrl?: string;
      initialQueryParams: TQueryParams;
      useTotals?: (queryParams: TQueryParams) => {
        totals?: TTotals,
        totalCount: number,
        reduceTotals: (item: TModel) => Promise<TTotals | undefined>;
        getTotalValues: (totals: TTotals) => Array<{ label: string, value: number }>;
      };
    },
    remove: {
      removeItemFromBackend: (itemId: string) => Promise<void>;
      bulkDelete?: {
        ConfirmationPopup: () => ReactElement;
        SuccessPopup: () => ReactElement;
        ErrorPopup: () => ReactElement;
      },
      singleDelete: {
        ConfirmationPopup: (props: { item: TModel }) => ReactElement;
        SuccessPopup: (props: { item: TModel }) => ReactElement;
        ErrorPopup?: (props: { item: TModel, error?: any }) => ReactElement;
        CustomErrorHandle?: (error: ApiErrorType) => void;
      },
      requiredPermissions?: UserPermission[];
      customPermissionAction?: (item?: TModel) => boolean;
    };
  },
}

export class AppTableState<TQueryParams> {
  private queryParamsState: TQueryParams | null = null;
  private static LocalStorageKeyPrefix = 'InstallerPortal.AppTableState.';

  get queryParams() {
    return this.queryParamsState || this.initialQueryParams;
  }

  constructor(
    public readonly tableId: string,
    public readonly initialQueryParams: TQueryParams,
  ) {
  }

  setQueryParams(value: TQueryParams) {
    this.queryParamsState = value;
  }

  load() {
    const saved = window.localStorage.getItem(this.localStorageKey);
    if (saved) {
      this.setQueryParams(JSON.parse(saved));
    }
  }

  save() {
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.queryParams || {}));
  }

  private get localStorageKey() {
    return `${AppTableState.LocalStorageKeyPrefix}${this.tableId}`;
  }

  static cleanUp() {
    const keys = Object.keys(window.localStorage);
    keys.forEach((key) => {
      if (key.startsWith(AppTableState.LocalStorageKeyPrefix)) {
        window.localStorage.removeItem(key);
      }
    });
  }
}

export function useAppTableState<TQueryParams>(tableId: string, defaultSort: TQueryParams, queryParams: TQueryParams, setQueryParams: (params: TQueryParams) => void) {
  const [appTableState, setAppTableState] = useState<AppTableState<TQueryParams> | null>(null);

  useEffect(() => {
    const newState = new AppTableState<TQueryParams>(tableId, defaultSort);
    newState.load();
    setAppTableState(newState);
    setQueryParams({ ...newState.queryParams });
  }, [tableId]);

  useEffect(() => {
    if (appTableState) {
      const toSave = { ...queryParams } as any;
      delete toSave.search;
      appTableState.setQueryParams(toSave);
      appTableState.save();
    }
  }, [appTableState, queryParams]);

  return appTableState;
}

const PAGE_SIZE = 10;

function AppTableHeaderColumn<TModel extends { id: string }, TQueryParams>(
  {
    column,
    queryParams,
    setQueryParams,
    sortQueryParamKeys,
    reset,
  }: {
    column: IColumn<TModel, TQueryParams>,
    queryParams: TQueryParams,
    setQueryParams: (queryParams: TQueryParams) => void,
    sortQueryParamKeys: (keyof TQueryParams)[],
    reset: () => void,
  },
) {
  const needOptionsUi = column.queryParamSetting !== undefined;
  const optionsClassColumn = needOptionsUi ? 'app-table-header-row-with-sort' : '';
  const optionsClassNameTitle = needOptionsUi ? 'app-pr-10' : '';

  const needTooltip = column.tooltip !== undefined;
  const tooltipTriggerRef = useRef<HTMLDivElement>(null);
  const tooltipClassNameTitle = needTooltip ? 'app-text-tooltip-trigger' : '';

  const needToHideOnMobile = column.isHiddenOnMobile === true;
  const hideOnMobileClassNameTitle = needToHideOnMobile ? 'app-table-hide-column-mobile' : '';

  const classNameTitle = `${optionsClassNameTitle} ${tooltipClassNameTitle} ${hideOnMobileClassNameTitle}`;

  return (
    <div className={optionsClassColumn}>
      {column.tooltip && (
        <AppTooltip
          position={AppTooltipPosition.Bottom}
          hideTooltipIcon
          tooltipTriggerElement={tooltipTriggerRef}
        >
          {column.tooltip}
        </AppTooltip>
      )}
      <span ref={tooltipTriggerRef} className={classNameTitle}>
        {column.title}
      </span>
      {column.queryParamSetting && (
        <AppPopupMenu
          options={column.queryParamSetting.values.map((p) => p[0]?.toString() || 'undefined')}
          selectedOption={queryParams[column.queryParamSetting.key] || column.queryParamSetting.values.map((p) => p[0])[0]}
          onOptionSelected={(option) => {
            if (!column.queryParamSetting) {
              return;
            }

            reset();
            const newParams = {
              ...queryParams,
              [column.queryParamSetting.key]: option === 'undefined' ? undefined : option,
            };

            if (column.queryParamSetting.type === 'sort') {
              sortQueryParamKeys.forEach((key) => {
                if (key !== column.queryParamSetting?.key) {
                  delete newParams[key];
                }
              });
            }

            setQueryParams(newParams);
          }}
          getOptionLabel={(option) => {
            if (!column.queryParamSetting) return '';
            const value = column.queryParamSetting.values.find((p) => ((p[0]?.toString() || 'undefined') === option));
            return value ? value[1] : '';
          }}
          render={() => {
            if (!column.queryParamSetting) {
              return <></>;
            }

            if (column.queryParamSetting.type === 'filter') {
              return (
                <AppFilterIcon
                  applied={queryParams[column.queryParamSetting.key] != null}
                />
              );
            }

            if (column.queryParamSetting.type === 'sort') {
              return (
                <AppSortIcon
                  applied={queryParams[column.queryParamSetting.key] != null}
                />
              );
            }

            return <></>;
          }}
        />
      )}
    </div>
  );
}

function AppTableHeader<TModel extends { id: string }, TQueryParams>(
  {
    columns,
    bulkDeleteOn,
    bulkDeleteItemsLength,
    turnOnBulkDelete,
    canDeletePermissions,
    canDeleteCustomAction,
    queryParams,
    setQueryParams,
    reset,
    tableHeaderRef,
  }: {
    columns: IColumn<TModel, TQueryParams>[],
    bulkDeleteOn: boolean,
    bulkDeleteItemsLength: number,
    turnOnBulkDelete: () => void,
    canDeletePermissions: UserPermission[],
    canDeleteCustomAction?: () => boolean,
    queryParams: TQueryParams,
    setQueryParams: (queryParams: TQueryParams) => void,
    reset: () => void,
    tableHeaderRef: MutableRefObject<HTMLDivElement | null>,
  },
) {
  const { t } = useTranslation();
  const { options: moreOptions, getOptionLabel: getMoreOptionLabel } = useMoreOptions();
  const { accountInfo: { accountStatus } } = useAccountInfo();
  const isAccountFunctionalityLimited = accountStatus === AccountStatusUIState.Limited;
  const shouldUseMobileColumns = useShouldUseMobileColumns();
  const columnsToShow = shouldUseMobileColumns ? columns.filter((column) => !column.isHiddenOnMobile) : columns;
  const columnsToShowCount = columnsToShow.length;
  const sortKeys = columns
    .filter((column) => column.queryParamSetting?.type === 'sort')
    .map((column) => column.queryParamSetting?.key) as (keyof TQueryParams)[];

  return (
    <div ref={tableHeaderRef} className={`app-table-header-row app-table-${columnsToShowCount + 1}-cols`}>
      {bulkDeleteOn && (
        <th>
          <div className="app-table-bulk-delete-totals">
            {bulkDeleteItemsLength}
            &nbsp;
            {t('Table_BulkDeleteTotals')}
          </div>
        </th>
      )}
      {!bulkDeleteOn && (
        <>
          {columnsToShow.map((column) => (
            <AppTableHeaderColumn
              key={column.title}
              column={column}
              queryParams={queryParams}
              sortQueryParamKeys={sortKeys}
              setQueryParams={setQueryParams}
              reset={reset}
            />
          ))}
          <div className="app-table-header-row-with-sort app-table-bulk-delete">
            <AppPermissionComponentGuard
              permissions={canDeletePermissions}
              customAction={canDeleteCustomAction}
            >
              <div className="app-m-auto">
                <AppPopupMenu
                  options={moreOptions}
                  getOptionLabel={getMoreOptionLabel as any}
                  onOptionSelected={turnOnBulkDelete}
                  disabled={isAccountFunctionalityLimited}
                  render={() => (
                    <div
                      className="app-d-flex app-fit-content"
                      {...applyFunctionalityLimitedStyles(isAccountFunctionalityLimited)}
                    >
                      <img
                        src={MoreOptionsIcon}
                        alt="More"
                      />
                    </div>
                  )}
                />
              </div>
            </AppPermissionComponentGuard>
          </div>
        </>
      )}
    </div>
  );
}

function AppTableValueCell<TModel extends { id: string }, TQueryParams>(
  {
    item,
    column,
  }:
    {
      item: TModel,
      column: IColumn<TModel, TQueryParams>,
    },
) {
  const isTextOnly = column.getIcon === undefined;
  const textClassName = isTextOnly ? 'app-ellipsis' : '';
  return (
    <div className="app-d-flex app-align-items-center app-gap-20 app-min-w-1 app-flex-basis-90">
      <span className={`${textClassName} ${column.getValueClass ? column.getValueClass(item) : ''}`}>
        {column.getValue ? column.getValue(item) : ''}
      </span>
      {column.getIcon && column.getIcon(item)}
    </div>
  );
}

function AppTableBody<TModel extends { id: string }, TQueryParams>(
  {
    inTabsShell,
    columns,
    items,
    onRowClick,
    bulkDeleteOn,
    itemsForBulkDelete,
    toggleBulkDeleteItem,
    singleDelete,
    canDeletePermissions,
    canDeleteCustomAction,
    loading,
    tableBodyRef,
  }: {
    inTabsShell?: boolean;
    columns: IColumn<TModel, TQueryParams>[],
    items: TModel[],
    onRowClick?: (item: TModel) => void,
    bulkDeleteOn: boolean,
    itemsForBulkDelete: { id: string }[],
    toggleBulkDeleteItem: (item: TModel) => void,
    singleDelete: (item: TModel) => void,
    canDeletePermissions: UserPermission[],
    canDeleteCustomAction?: (item: TModel) => boolean,
    loading: boolean,
    tableBodyRef: MutableRefObject<HTMLDivElement | null>,
  },
) {
  const shouldUseMobileColumns = useShouldUseMobileColumns();
  const columnsToShow = shouldUseMobileColumns ? columns.filter((column) => !column.isHiddenOnMobile) : columns;
  const columnsToShowCount = columnsToShow.length;
  return (
    <div ref={tableBodyRef} className={`app-flex-vertical-scrollable ${inTabsShell ? 'app-mh-calc-150' : 'app-mh-calc-50'}`}>
      <AppShowLoading showLoading={loading}>
        {items.map((item) => (
          <div
            tabIndex={0}
            role="row"
            onClick={() => onRowClick && onRowClick(item)}
            onKeyDown={() => onRowClick && onRowClick(item)}
            className={`app-table-content-row app-d-flex app-align-items-center app-table-${columnsToShowCount + 1}-cols`}
            key={item.id}
          >
            {columnsToShow.map((column) => (
              <AppTableValueCell
                key={column.title}
                item={item}
                column={column}
              />
            ))}
            <AppPermissionComponentGuard
              customAction={canDeleteCustomAction ? () => canDeleteCustomAction(item) : undefined}
              permissions={canDeletePermissions}
            >
              <AppDeleteButton
                deleteAction={() => singleDelete(item)}
                bulkDeleteOn={bulkDeleteOn}
                bulkDeleteAction={() => toggleBulkDeleteItem(item)}
                bulkDeleteItemSelected={itemsForBulkDelete.includes(item)}
              />
            </AppPermissionComponentGuard>
          </div>
        ))}
      </AppShowLoading>
    </div>
  );
}

function AppTableFooter(
  {
    page,
    setPage,
    totalPages,
    totals,
  }: {
    page: number,
    setPage: (page: number) => void,
    totalPages: number,
    totals: Array<{ label: string, value: number }>,
  },
) {
  return (
    <div className="app-content-footer app-pt-30">
      <div className="app-d-flex app-align-items-center app-w-100pcnt">
        <div
          className="app-content-footer-pagination app-flex-grow-1 app-d-flex app-align-items-center app-justify-content-center"
        >
          <AppTablePagination
            activePage={page}
            onPageSelect={setPage}
            totalPages={totalPages}
          />
        </div>
        <div>
          <div className="app-table-total app-d-flex app-flex-column app-justify-content-end">
            {totals.map((total) => (
              <div key={total.label} className="app-table-total">
                <div className="app-table-total-label">
                  {total.label}
                  :
                </div>
                <div className="app-table-total-value">
                  {total.value}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function AppTablePage<TModel extends { id: string }, TQueryParams, TTotals>(props: IAppTablePageProps<TModel, TQueryParams, TTotals>) {
  const {
    table: {
      query: {
        itemsUrl,
        useTotals,
        initialQueryParams,
      },
    },
  } = props;
  const [queryParams, setQueryParams] = useState<TQueryParams>(initialQueryParams);
  const url = itemsUrl ? getUrlWithQueryParams(itemsUrl, queryParams) : undefined;
  const totals = useTotals ? useTotals(queryParams) : undefined;
  const totalPages = getTotalPages(PAGE_SIZE, totals?.totalCount);

  const tableHeaderRef = useRef<HTMLDivElement | null>(null);
  const tableBodyRef = useRef<HTMLDivElement | null>(null);

  const {
    items, reset, removeItem, loading, page, setPage,
  } = useInfiniteWithPagination<TModel>(url, totalPages, { scrollRef: tableBodyRef });

  const singleDelete = async (item: TModel) => {
    const {
      table: {
        remove: {
          removeItemFromBackend,
          singleDelete: {
            ConfirmationPopup,
            SuccessPopup,
            ErrorPopup,
            CustomErrorHandle,
          },
        },
      },
    } = props;

    const shouldRemove = await showPopup(<ConfirmationPopup item={item} />);
    if (!shouldRemove) {
      return;
    }

    try {
      await removeItemFromBackend(item.id);
      removeItem(items!.findIndex((u) => u.id === item.id));
      await totals!.reduceTotals(item);
      await showPopup(<SuccessPopup item={item} />);
    } catch (e) {
      if (CustomErrorHandle) {
        CustomErrorHandle(e);
      } else {
        showPopup(ErrorPopup);
      }
    }
  };

  const searchItems = (search: string) => {
    reset();
    setQueryParams({
      ...queryParams,
      search,
    });
  };

  const [bulkDeleteInProgress, setBulkDeleteInProgress] = useState(false);
  const {
    bulkDeleteOn,
    turnOffBulkDelete,
    turnOnBulkDelete,
    itemsForBulkDelete,
    bulkDeleteDisabled,
    toggleBulkDeleteItem,
  } = useBulkDelete<{ id: string }>();

  const bulkDelete = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (!props.table.remove.bulkDelete) {
      return;
    }

    const {
      table: {
        remove: {
          removeItemFromBackend,
          bulkDelete: {
            ConfirmationPopup,
            SuccessPopup,
            ErrorPopup,
          },
        },
      },
    } = props;

    const shouldBulkDelete = await showPopup(<ConfirmationPopup />);
    if (shouldBulkDelete === true) {
      setBulkDeleteInProgress(true);
      try {
        await Promise.all(itemsForBulkDelete.map((p) => removeItemFromBackend(p.id)));
        await showPopup(<SuccessPopup />);
      } catch {
        showPopup(<ErrorPopup />);
      } finally {
        setBulkDeleteInProgress(false);
      }
      turnOffBulkDelete();
      reset();
      setQueryParams({ ...queryParams });
    }
  };

  const goToItemOrToggleBulkDelete = (item: TModel, isBulkDeleteOn: boolean) => {
    if (!isBulkDeleteOn) {
      // eslint-disable-next-line react/destructuring-assignment
      props.table.goToItem(item);
      return;
    }
    toggleBulkDeleteItem(item);
  };

  const { t } = useTranslation();

  const tableToolbar = bulkDeleteOn ? (
    <div className="app-d-flex app-justify-content-end app-w-100pcnt">
      <button
        type="button"
        onClick={turnOffBulkDelete}
        className="app-secondary-button app-mr-29"
      >
        {t('Form_Cancel')}
      </button>
      <button
        type="button"
        disabled={bulkDeleteDisabled}
        onClick={bulkDelete}
        className="app-primary-button"
      >
        {t('Table_Delete')}
      </button>
    </div>
  )
    : (
      <>
        <AppSearch
          onSearch={searchItems}
          placeholder={props.searchText}
        />
        {props.normalModeActions}
      </>
    );

  useEffect(() => {
    if (!tableBodyRef.current) {
      return;
    }

    if (isScrollVisible(tableBodyRef.current)) {
      tableBodyRef.current.style.paddingRight = '17px';
      tableHeaderRef.current!.style.paddingRight = '45px';
    }
  }, [loading, items]);

  const tableId = props.table.columns.map((p) => p.title).join('_');
  useAppTableState<TQueryParams>(tableId, props.table.query.initialQueryParams, queryParams, setQueryParams);

  return (
    <div className="app-content app-pt-20 app-pb-33">
      <div className="app-systems-table-header app-mb-26 app-px-30 app-d-flex app-align-items-center app-justify-content-between">
        {tableToolbar}
      </div>
      <div className="app-table app-table-85 app-px-30 app-pt-46">
        <AppTableHeader
          columns={props.table.columns}
          bulkDeleteOn={bulkDeleteOn}
          bulkDeleteItemsLength={itemsForBulkDelete.length}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          reset={reset}
          tableHeaderRef={tableHeaderRef}
          canDeletePermissions={props.table.remove.requiredPermissions || []}
          canDeleteCustomAction={props.table.remove.customPermissionAction}
          turnOnBulkDelete={turnOnBulkDelete}
        />
        <AppTableBody
          inTabsShell={props.inTabsShell}
          columns={props.table.columns}
          items={items}
          onRowClick={(p) => goToItemOrToggleBulkDelete(p, bulkDeleteOn)}
          bulkDeleteOn={bulkDeleteOn}
          itemsForBulkDelete={itemsForBulkDelete}
          toggleBulkDeleteItem={toggleBulkDeleteItem}
          singleDelete={singleDelete}
          canDeletePermissions={props.table.remove.requiredPermissions || []}
          canDeleteCustomAction={props.table.remove.customPermissionAction}
          loading={loading || bulkDeleteInProgress}
          tableBodyRef={tableBodyRef}
        />
        <AppTableFooter
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totals={totals?.totals ? totals?.getTotalValues(totals.totals) : []}
        />
      </div>
    </div>
  );
}
