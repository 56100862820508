import { KeyedMutator, useSWRConfig } from 'swr';
import { IGridQueryParams } from '../../../routes/systems/models/IGridQueryParams';
import {
  IEntryUserGridItem,
  IEntryUsersTotals,
  IUserData,
} from '../../../routes/systems/models/IUserData';
import { RegistrationStatus } from '../constants/RegistrationStatus';
import { UserRole } from '../constants/UserRole';
import { SortStringOptions } from '../hooks/useSortStringOptions';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import {
  getUrlWithQueryParams,
  httpDelete, httpGetJson, httpPost, httpPut,
} from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useSitesSummary } from './useSitesSummary';
import { removeLeadingZeros } from '../../jsUtils/removeTrailingZeros';

function getBodyFromUserData(userData: IUserData, companyId: string, siteId: string) {
  return {
    ...userData,
    siteInfo: {
      ...userData.siteInfo,
      groupId: userData.siteInfo.groupId || '',
    },
    companyId,
    createDate: new Date().toISOString(),
    userRole: UserRole.AppUser,
    siteId,
  };
}

export async function reinstateUser(entryUserId?: string, siteId?: string) {
  if (!siteId || !entryUserId) {
    return;
  }

  await httpPut(Urls.ReinstateUser(entryUserId, siteId), {});
}

export async function resendUserEmail(entryUserId?: string, siteId?: string): Promise<boolean> {
  if (!siteId || !entryUserId) {
    return false;
  }

  const { ok: isSuccess } = await httpPost(Urls.ResendUserEmail(entryUserId, siteId), '');
  return isSuccess;
}

function trimUserData(userData: IUserData): IUserData {
  return {
    ...userData,
    firstName: userData.firstName.trim(),
    lastName: userData.lastName.trim(),
    email: userData.email.trim(),
    siteInfo: {
      ...userData.siteInfo,
      callName: userData.siteInfo.callName?.trim() || '',
      callId: removeLeadingZeros(userData.siteInfo.callId.trim()),
      groupId: userData.siteInfo.groupId?.trim() || '',
    },
  };
}

export async function updateEntryUser(userData: IUserData, companyId: string, siteId: string): Promise<IUserData> {
  const trimmedUserData = trimUserData(userData);
  await httpPut(Urls.Users, getBodyFromUserData(trimmedUserData, companyId, siteId));

  return trimmedUserData;
}

export async function createEntryUser(userData: IUserData, companyId: string, siteId: string): Promise<IUserData | undefined> {
  const trimmedUsersData = trimUserData(userData);
  await httpPost(Urls.Users, JSON.stringify(getBodyFromUserData(trimmedUsersData, companyId, siteId)));

  return trimmedUsersData;
}

export async function deleteEntryUser(entryUserId: string, companyId?: string, siteId?: string): Promise<void> {
  if (!companyId || !siteId) {
    throw new TypeError('companyId and siteId are required');
  }
  const url = Urls.User(entryUserId, companyId, siteId);
  if (url) {
    await httpDelete(url);
  }
}

export async function isEmailUnique(email: string, siteId: string, userId?: string) {
  const url = Urls.IsUserEmailNameUnique(email, siteId, userId);
  return httpGetJson<boolean>(url!);
}

export function useEntryUsers(siteId?: string, queryParams?: IGridQueryParams):
    {
      entryUsersGridData: IEntryUserGridItem[] | undefined,
      mutate: KeyedMutator<IEntryUserGridItem[]>,
      clearCache: () => void
    } {
  const { cache } = useSWRConfig();
  const url = Urls.UsersWithSiteId(siteId)
    ? getUrlWithQueryParams(Urls.UsersWithSiteId(siteId)!, queryParams)
    : undefined;
  const { data, mutate } = useSWRAndHandleErrors<IEntryUserGridItem[]>(url, httpGetJson);

  return { entryUsersGridData: data, mutate, clearCache: () => cache.delete(Urls.UsersWithSiteId(siteId)) };
}

export function useEntryUsersTotals(siteId?: string, queryParams?: {
  nameSort?: SortStringOptions,
  isActive?: boolean,
  registrationStatus?: RegistrationStatus,
}): { usersTotals: IEntryUsersTotals | undefined, mutate: KeyedMutator<IEntryUsersTotals> } {
  const url = Urls.UsersTotals(siteId)
    ? getUrlWithQueryParams(Urls.UsersTotals(siteId)!, queryParams)
    : undefined;
  const { data, mutate } = useSWRAndHandleErrors<IEntryUsersTotals>(url, httpGetJson);
  return { usersTotals: data, mutate };
}

export function useTotalUsersPerCompany(): {usersTotals: { totalUsers: number} | undefined} {
  const { sitesSummary } = useSitesSummary({
    search: '',
  });

  return sitesSummary ? {
    usersTotals: {
      totalUsers: sitesSummary.activeUsers,
    },
  } : { usersTotals: undefined };
}
