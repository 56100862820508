import { useTranslation } from 'react-i18next';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { httpGetString } from '../../backend/http/http';
import { Urls } from '../../backend/urls';

export function useTermsAndConditions() {
  const { i18n } = useTranslation();
  const url = `${Urls.AdminTermsAndConditions}?languageCode=${i18n.language}`;
  const { data } = useSWRAndHandleErrors(url, httpGetString, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return data;
}
