export enum StatusCodesEnum {
    /**
     * The server cannot or will not process the request due to an apparent
     * client error (e.g., malformed request syntax, size too large, invalid
     * request message framing, or deceptive request routing).
     */
    BadRequest = 400,

    /**
     * The request was valid, but the server is refusing action. The user might
     * not have the necessary permissions for a resource, or may need an account
     * of some sort.
     */
    Forbidden = 403,

    /**
     * A request method is not supported for the requested resource; for
     * example, a `GET` request on a form that requires data to be presented
     * via `POST`, or a `PUT` request on a read-only resource.
     */
    MethodNotAllowed = 405,

    /**
     * The requested resource could not be found but may be available in the
     * future. Subsequent requests by the client are permissible.
     */
    NotFound = 404,

    /**
     * The server timed out waiting for the request. According to HTTP
     * specifications: "The client did not produce a request within the time
     * that the server was prepared to wait. The client MAY repeat the request
     * without modifications at unknown later time."
     */
    RequestTimeout = 408,

    /**
     * The request could not be completed due to a conflict with the current
     * state of the resource. This code is only allowed in situations where it
     * is expected that the user might be able to resolve the conflict and
     * resubmit the request.
     */
    Conflict = 409,

    /**
     * Similar to `403 Forbidden`, but specifically for use when authentication
     * is required and has failed or has not yet been provided. The response
     * must include a WWW-Authenticate header field containing a challenge
     * applicable to the requested resource. See Basic access authentication and
     * Digest access authentication. `401` semantically means "unauthenticated",
     * i.e. the user does not have the necessary credentials.
     *
     * Note: Some sites issue HTTP `401` when an IP address is banned from the
     * website (usually the website domain) and that specific address is refused
     * permission to access a website.
     */
    Unauthorized = 401,

    /**
     * The requester has asked the server to switch protocols and the server has
     * agreed to do so.
     */
    InfoSwitchingProtocols = 101,

    /**
     * The server was acting as a gateway or proxy and received an invalid
     * response from the upstream server.
     */
    ServerErrorBadGateway = 502,

    /**
     * The server was acting as a gateway or proxy and did not receive a timely
     * response from the upstream server.
     */
    ServerErrorGatewayTimeout = 504,

    /**
     * A generic error message, given when an unexpected condition was
     * encountered and no more specific message is suitable.
     */
    ServerErrorInternal = 500,

    /**
     * The server is currently unavailable (because it is overloaded or down for
     * maintenance). Generally, this is a temporary state.
     */
    ServerErrorServiceUnavailable = 503,

    /**
     * The request has been accepted for processing, but the processing has not
     * been completed. The request might or might not be eventually acted upon,
     * and may be disallowed when processing occurs.
     */
    SuccessAccepted = 202,

    /**
     * The request has been fulfilled, resulting in the creation of a new
     * resource.
     */
    SuccessCreated = 201,

    /**
     * Standard response for successful HTTP requests. The actual response will
     * depend on the request method used. In a GET request, the response will
     * contain an entity corresponding to the requested resource. In a POST
     * request, the response will contain an entity describing or containing the
     * result of the action.
     */
    SuccessOK = 200,
}

export const RESPONSE_CODE_ERROR_LIST = [
  StatusCodesEnum.BadRequest,
  StatusCodesEnum.Unauthorized,
  StatusCodesEnum.Forbidden,
  StatusCodesEnum.NotFound,
  StatusCodesEnum.MethodNotAllowed,
  StatusCodesEnum.RequestTimeout,
  StatusCodesEnum.ServerErrorInternal,
  StatusCodesEnum.ServerErrorGatewayTimeout,
  StatusCodesEnum.ServerErrorServiceUnavailable,
];
