import {
  useEffect,
  useState,
  RefObject,
  useRef,
} from 'react';
import { getUrlWithQueryParams, httpGetJson } from '../../backend/http/http';
import { debounce } from '../../jsUtils/debounce';

export const PAGE_SIZE = 10;

interface IPageObject<TItem> {
  page: number;
  items: TItem[];
  isLoading: boolean;
}

function getPagesToLoad(page: number, maxPage: number): number[] {
  if (page <= 0) {
    throw new Error('Page cannot be less than 1');
  }

  let pages: number[] = [];
  if (page === 1) {
    pages = [1, 2, 3];
  } else if (page === maxPage) {
    pages = [maxPage - 2, maxPage - 1, maxPage];
  } else {
    pages = [page - 1, page, page + 1];
  }

  return pages.filter((p) => p > 0 && p <= maxPage);
}

export interface IInfiniteWithPaginationModel<TItem> {
  items: TItem[];
  loading: boolean;
  isCurrentPageLoading: boolean;
  setPage: (page: number) => void;
  reset: () => void;
  page: number;
  removeItem: (itemIndex: number) => void;
}

export function useInfiniteWithPagination<TItem>(
  url?: string,
  maxPage?: number,
  tableParams?: {
    scrollRef?: RefObject<HTMLDivElement | null>;
  },
): IInfiniteWithPaginationModel<TItem> {
  if (url && (url.includes('page') || url.includes('pageSize'))) {
    throw new Error('Do not include page or pageSize in the url');
  }

  const [page, setPage] = useState<number | null>(null);
  const [sortedPages, setSortedPages] = useState<number[]>([]);
  const [loadedPageObjects, setLoadedPageObjects] = useState<{ [key: number]: IPageObject<TItem> }>({});
  const [requestToScrollIntoView, setRequestToScrollIntoView] = useState(false);
  const [loading, setLoading] = useState(true);
  const currPage = page || 1;

  // sync pages with current page
  useEffect(() => {
    if (url && maxPage) {
      if (!page) {
        setPage(currPage);
      }
      setSortedPages((prevPages) => {
        const pagesToLoad = getPagesToLoad(currPage, maxPage);
        return [...new Set([...prevPages, ...pagesToLoad].sort((a, b) => a - b))];
      });
    } else {
      setSortedPages([]);
    }
  }, [url, maxPage, currPage, page]);

  const syncHttpCanceledRef = useRef<{ [key: string]: boolean }>({});
  const latestUrlRef = useRef<string | undefined>();
  latestUrlRef.current = url;
  // sync pages data with pages
  useEffect(() => {
    if (!url || maxPage == null) {
      return;
    }

    const newPages = sortedPages.filter((p) => !loadedPageObjects[p]);
    if (newPages.length === 0 && requestToScrollIntoView) {
      if (tableParams?.scrollRef && loadedPageObjects[currPage]) {
        const scrollRef = tableParams.scrollRef.current;
        if (scrollRef) {
          const { scrollHeight, clientHeight } = scrollRef;
          const totalItemsOnAllPages = Object.values(loadedPageObjects).reduce(
            (acc, pageObject) => acc + pageObject.items.length,
            0,
          );
          const itemHeight = scrollHeight / totalItemsOnAllPages;
          const heightRequiredForCurrPage = itemHeight * loadedPageObjects[currPage].items.length;
          const heightRequiredForPrevPages = Object.values(loadedPageObjects).reduce((acc, pageObject) => {
            if (pageObject.page < currPage) {
              return acc + itemHeight * pageObject.items.length;
            }
            return acc;
          }, 0);

          if (totalItemsOnAllPages < PAGE_SIZE || currPage === 1) {
            scrollRef.scrollTop = 0;
          } else {
            scrollRef.scrollTop = heightRequiredForPrevPages - clientHeight + heightRequiredForCurrPage;
          }
        }
        setRequestToScrollIntoView(false);
      }
      return;
    }

    const syncKey = Date.now().toString();
    syncHttpCanceledRef.current[syncKey] = false;
    const newPageObjectsPromises = newPages.map((newPage) => httpGetJson<TItem[]>(getUrlWithQueryParams(url, {
      page: newPage - 1,
      pageSize: PAGE_SIZE,
    }))
      .then((items) => ({ page: newPage, items, isLoading: false })));

    Promise
      .all(newPageObjectsPromises)
      .then((newPageObjects) => {
        if (syncHttpCanceledRef.current[syncKey] === true
          || url !== latestUrlRef.current) {
          return;
        }
        setLoadedPageObjects((prevPageObjects) => newPageObjects
          .reduce(
            (pageObjectsAcc, pageObject) => ({ ...pageObjectsAcc, [pageObject.page]: pageObject }),
            prevPageObjects,
          ));
      })
      .finally(() => setLoading(false));
  }, [
    sortedPages,
    url,
    currPage,
    tableParams?.scrollRef,
    requestToScrollIntoView,
    maxPage,
    loading,
  ]);

  // sync current page and load more on scrolling
  useEffect(() => {
    if (!tableParams?.scrollRef || !url || maxPage == null) {
      return () => { };
    }

    const onScroll = (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      // load more
      const scrollPercentage = scrollTop / (scrollHeight - clientHeight);
      if (scrollPercentage > 0.7 && currPage + 1 <= maxPage) {
        sortedPages.push(currPage + 1);
        setSortedPages([...new Set(sortedPages)]);
      }

      if (scrollPercentage < 0.3 && currPage - 1 > 0) {
        sortedPages.unshift(currPage - 1);
        setSortedPages([...new Set(sortedPages)]);
      }

      // sync current page
      // calculate current page based on scroll position
      const totalItemsOnAllPages = Object.values(loadedPageObjects).reduce(
        (acc, pageObject) => acc + pageObject.items.length,
        0,
      );
      // const itemHeight = scrollHeight / totalItemsOnAllPages;
      const scrollPercentageOfItems = scrollTop / (scrollHeight - clientHeight);
      const amountOfScrolledItems = Math.floor(scrollPercentageOfItems * totalItemsOnAllPages);
      const amountOfScrolledPages = Math.floor(amountOfScrolledItems / PAGE_SIZE);
      const newPage = amountOfScrolledPages + 1;
      if (newPage !== currPage) {
        setPage(newPage);
      }
    };
    const debouncedOnScroll = debounce(onScroll, 100);
    tableParams?.scrollRef?.current?.addEventListener('scroll', debouncedOnScroll);

    return () => {
      tableParams?.scrollRef?.current?.removeEventListener('scroll', debouncedOnScroll);
    };
  }, [tableParams, url, maxPage, currPage, sortedPages, loadedPageObjects]);

  return {
    items: Object.values(loadedPageObjects)
      .sort((a, b) => a.page - b.page)
      .reduce((acc, pageObject) => ([...acc, ...pageObject.items]), [] as TItem[]),
    loading,
    isCurrentPageLoading: !loadedPageObjects[currPage] || loadedPageObjects[currPage].isLoading,
    setPage: (newPage: number) => {
      setRequestToScrollIntoView(true);
      setPage(newPage);
    },
    page: currPage,
    reset: () => {
      Object.keys(syncHttpCanceledRef.current).forEach((key) => {
        syncHttpCanceledRef.current[key] = true;
      });
      setLoadedPageObjects({});
      setPage(null);
      setSortedPages([]);
    },
    removeItem: (itemIndex: number) => {
      const pageObject = loadedPageObjects[currPage];
      if (!pageObject) {
        return;
      }

      const newItems = [...pageObject.items];
      newItems.splice(itemIndex, 1);
      setLoadedPageObjects((prevPageObjects) => ({
        ...prevPageObjects,
        [currPage]: {
          ...pageObject,
          items: newItems,
        },
      }));
    },
  };
}
