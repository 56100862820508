import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import TaxCertificateDropzone from '../../../shared/appUIFramework/components/uploadTaxCertificate/TaxCertificateDropzone';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { handleHttpRequestError } from '../../../shared/appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';
import { uploadTaxCertificate } from '../../../shared/appUIFramework/appBackend/useCompany';

export default function ReplaceTaxCertificatePopup() {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const cancel = () => closePopup();

  const submit = async () => {
    try {
      setUploadInProgress(true);
      await uploadTaxCertificate(file);
    } catch (e) {
      await handleHttpRequestError(e);
    } finally {
      setUploadInProgress(false);
    }
    closePopup();
  };

  return (
    <AppPopupLayout
      headerTitle={t('TaxCertificate')}
      popupBody={(
        <TaxCertificateDropzone
          style={{
            width: '60%',
          }}
          showIcon
          showLoading={uploadInProgress}
          fileName={file?.name || ''}
          onFileRemoved={() => setFile(null)}
          onFileSelected={(newFile) => setFile(newFile)}
        />
)}
      buttons={(
        <div className="app-popup-buttons">
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
          <button type="button" onClick={submit} className="app-primary-button app-popup-right-button">{t('Save')}</button>
        </div>
      )}
    />
  );
}
