import { KeyedMutator } from 'swr';
import { ISiteInfo } from '../../../routes/systems/models/ISiteInfo';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { httpGetJson } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useCompany } from './useCompany';

export function useSiteInfo(siteId?: string): { siteInfo?: ISiteInfo, mutate?: KeyedMutator<ISiteInfo>} {
  const company = useCompany();

  const url = siteId ? Urls.Site(siteId, company?.id) : undefined;

  const { data, mutate } = useSWRAndHandleErrors<ISiteInfo>(url, httpGetJson);

  return { siteInfo: data, mutate };
}
