import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { AdminUserRole } from '../../../shared/appUIFramework/appBackend/useProfileDetails';
import { getDeleteAdminSuccessfulTextByRole } from '../AdminPeople';

export default function DeleteAdminConfirmationPopup({ admin, role }:{admin: string, role: AdminUserRole}) {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  const accessLevel = t(role);

  return (
    <AppPopupLayout
      headerTitle={getDeleteAdminSuccessfulTextByRole(role).header}
      extraClass="app-popup-delete-user"
      popupBody={(
        <>
          <div className="app-delete-system-popup-title app-pt-30">
            {t('DeleteUser_AreYouSure')}
          </div>
          <form onSubmit={confirm} className="app-popup-form">
            <div className="app-popup-form-controls">
              <div className="app-popup-form-control">
                <div className="app-popup-form-control-label">
                  <span>{ t('Name') }</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    value={admin}
                    readOnly
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
              </div>
              <div className="app-popup-form-control">
                <div className="app-popup-form-control-label">
                  <span>{ t('AccessLevel') }</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    value={accessLevel}
                    readOnly
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
              </div>
            </div>
            <div className="app-popup-buttons">
              <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
              <button type="submit" className="app-warn-button app-popup-right-button">{t('Table_Delete')}</button>
            </div>
          </form>
        </>
      )}
    />
  );
}
