import { CountryCode } from '../../shared/appUIFramework/constants/Countries';

export enum Region {
  UnitedKingdom = 'United Kingdom',
  France = 'France',
  Germany = 'Germany',
  Netherlands = 'Netherlands',
  UnitedStates = 'United States',
  Ireland = 'Ireland',
  Belgium = 'Belgium',
  Luxembourg = 'Luxembourg',
  SouthAfrica = 'South Africa',
  Canada = 'Canada',
  Sweden = 'Sweden',
  Norway = 'Norway',
  Denmark = 'Denmark',
}

export interface ISignUpUserDto {
  CompanyName: string;
  CompanyLogo: string;
  Email: string;
  TypeOfCompany: number;
  Title: string;
  FirstName: string;
  LastName: string;
  Address1: string;
  Address2: string;
  Town: string;
  County: string;
  PostCode: string;
  CountryCode: CountryCode;
  CountryId: number;
  Position: string;
  TelephoneNumber: string;
  Mobile: string;
  Source: string;
}
