import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'wouter';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import { deleteSite } from '../../shared/appUIFramework/appBackend/useSites';
import { useSitesSummary } from '../../shared/appUIFramework/appBackend/useSitesSummary';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import { CanNavigateToSitePermissions } from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { hasAnyOfPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../shared/appUIFramework/appPermisions/userPermission';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import AppSearch from '../../shared/appUIFramework/components/AppSearch';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import AppTablePagination from '../../shared/appUIFramework/components/AppTablePagination/AppTablePagination';
import { getTotalPages } from '../../shared/appUIFramework/dataTransformers/getTotalPages';
import { formatSystemAddress } from '../../shared/appUIFramework/formatters/formatSiteAddress';
import { useShouldUseMobileColumns } from '../../shared/appUIFramework/hooks/useShouldUseMobileColumns';
import useSortStringOptions, { SortStringOptions } from '../../shared/appUIFramework/hooks/useSortStringOptions';
import { closePopup, showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import DeleteSystemConfirmationPopup from './components/DeleteSystemConfirmationPopup';
import DeleteSystemErrorPopup from './components/DeleteSystemErrorPopup';
import DeleteSystemSuccessPopup from './components/DeleteSystemSuccessPopup';
import { IGridQueryParams } from './models/IGridQueryParams';
import { ISite } from './models/ISiteInfo';
import {
  useAccountInfo,
  AccountStatusUIState,
  applyFunctionalityLimitedStyles,
} from '../../shared/appUIFramework/appBackend/useAccountInfo';
import AppDeleteButton from '../../shared/appUIFramework/components/AppDeleteButton';
import { AppSortIcon } from '../../shared/appUIFramework/components/SortAndFilterIcons';
import { togglePaymentScrollClass, useAddPaddingLeftWhenScrollIsVisible } from '../../styles';
import callIfEnterKeyPressed from '../../shared/jsUtils/callIfEnterKeyPressed';
import { PAGE_SIZE, useInfiniteWithPagination } from '../../shared/appUIFramework/hooks/useInfiniteWithPagination';
import { getUrlWithQueryParams } from '../../shared/backend/http/http';
import { Urls } from '../../shared/backend/urls';
import { useAppTableState } from '../../shared/appUIFramework/components/AppTablePage';
import DeleteSiteProcessingPopup from './components/DeleteSiteProcessingPopup';

export default function Systems() {
  const { t } = useTranslation();
  const { options: sortStringOptions, getOptionLabel: getSortStringOptionLabel } = useSortStringOptions();
  const systemsTableContentRef = useRef<HTMLDivElement>(null);
  const systemsHeaderRowRef = useRef<HTMLDivElement>(null);
  const { accountInfo } = useAccountInfo();
  const isAccountFunctionalityLimited = accountInfo.accountStatus === AccountStatusUIState.Limited;

  const company = useCompany();
  const [queryParams, setQueryParams] = useState<IGridQueryParams>({
    companyId: company?.id,
    search: '',
    siteNameSort: SortStringOptions.Ascending,
  });
  const { sitesSummary, mutate: refreshSitesSummary } = useSitesSummary(queryParams);
  const totalPages = getTotalPages(PAGE_SIZE, sitesSummary?.totalSites);
  const sitesUrl = queryParams.companyId ? getUrlWithQueryParams(Urls.Sites, queryParams) : undefined;
  const {
    items: sites,
    reset,
    loading: sitesLoading,
    page,
    setPage,
    removeItem: removeSiteFromInfinitePages,
  } = useInfiniteWithPagination<ISite>(sitesUrl, totalPages, { scrollRef: systemsTableContentRef });

  const [, setLocation] = useLocation();
  const goToViewSystemDetails = (system: ISite) => {
    setLocation(`/systems/${system.id}/entry-users`);
  };

  const scrollStylesApplied = useAddPaddingLeftWhenScrollIsVisible(systemsTableContentRef, sitesLoading);
  useEffect(() => {
    if (company) {
      setQueryParams({ ...queryParams, companyId: company.id });
    }
  }, [company]);
  useEffect(() => {
    if (!systemsHeaderRowRef.current) {
      return;
    }
    togglePaymentScrollClass(scrollStylesApplied, systemsHeaderRowRef.current);
  }, [scrollStylesApplied]);

  const removeSite = async (system: ISite) => {
    const shouldRemove = await showPopup(<DeleteSystemConfirmationPopup
      system={system.siteName}
      siteId={system.id}
    />);
    if (!shouldRemove) {
      return;
    }
    try {
      showPopup(<DeleteSiteProcessingPopup />);
      await deleteSite(system.id, company!.id);
      closePopup();
      removeSiteFromInfinitePages(sites!.findIndex((s) => s.id === system.id));
      await refreshSitesSummary({
        totalSites: sitesSummary!.totalSites - 1,
        activeUsers: sitesSummary!.activeUsers,
      }, {
        revalidate: false,
      });
      await showPopup(<DeleteSystemSuccessPopup siteName={system.siteName} />);
    } catch {
      await showPopup(<DeleteSystemErrorPopup />);
    }
  };
  const shouldUseMobileColumns = useShouldUseMobileColumns();

  const tableHeaderNode = (
    <>
      <AppSearch
        onSearch={(search) => {
          reset();
          setQueryParams({ ...queryParams, search });
        }}
        placeholder={t('Sites_Search')}
      />
      <AppPermissionComponentGuard permissions={[UserPermission.CanCreateSite]}>
        {(isAccountFunctionalityLimited && (
          <span className="app-primary-button disabled-green" {...applyFunctionalityLimitedStyles(true)}>
            {t('Sites_AddSites')}
          </span>
        ))
          || (
            <Link
              to="/systems/0"
              className="app-primary-button"
            >
              {t('Sites_AddSites')}
            </Link>
          )}
      </AppPermissionComponentGuard>
    </>
  );

  const colsAmountClassName = shouldUseMobileColumns ? 'app-table-2-cols' : 'app-table-3-cols';
  const columnClassName = `app-table-content-row ${colsAmountClassName} app-d-flex app-align-items-center app-justify-content-between`;
  useAppTableState('systems', queryParams, queryParams, (q) => {
    setQueryParams(q);
  });

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb">{t('Navigation_Sites')}</span>
      </AppContentToolbar>
      <div className="app-content app-pt-62 app-pb-33 app-px-30">
        <div
          className="app-systems-table-header app-mb-46 app-d-flex app-align-items-center app-justify-content-between"
        >
          {tableHeaderNode}
        </div>
        <div className="app-flex-vertical-scrollable">
          <div className="app-table">
            <div ref={systemsHeaderRowRef} className={`app-table-header-row ${colsAmountClassName}`}>
              <div className="app-table-header-row-with-sort">
                <span className="app-mr-9">{t('SiteAdd_SiteName')}</span>
                <AppPopupMenu
                  options={(sortStringOptions as unknown) as string[]}
                  selectedOption={queryParams.siteNameSort || (SortStringOptions.Default as any)}
                  onOptionSelected={(option) => {
                    reset();
                    setQueryParams({
                      ...queryParams,
                      siteNameSort: +option,
                    });
                  }}
                  getOptionLabel={getSortStringOptionLabel as any}
                  render={() => (<AppSortIcon applied={!!queryParams.siteNameSort} />)}
                />
              </div>
              {!shouldUseMobileColumns && <div>{t('Table_SiteAddress')}</div>}
            </div>
            <div
              className="app-flex-vertical-scrollable app-table-content"
              ref={systemsTableContentRef}
            >
              <AppShowLoading showLoading={sitesLoading}>
                {
                  sites && sites.map((site) => (
                    <div
                      className={columnClassName}
                      onClick={() => hasAnyOfPermissions(...CanNavigateToSitePermissions)
                        && goToViewSystemDetails(site)}
                      tabIndex={0}
                      key={site.id}
                      role="row"
                      onKeyDown={callIfEnterKeyPressed(() => goToViewSystemDetails(site))}
                    >
                      <div>{site.siteName}</div>
                      {!shouldUseMobileColumns && <div>{formatSystemAddress(site.siteAddress)}</div>}
                      <div>
                        <AppPermissionComponentGuard permissions={[UserPermission.CanDeleteSite]}>
                          <AppDeleteButton deleteAction={() => removeSite(site)} />
                        </AppPermissionComponentGuard>
                      </div>
                    </div>
                  ))
                }
              </AppShowLoading>
            </div>
          </div>
        </div>

        <div className="app-content-footer">
          <div className="app-d-flex app-align-items-center app-w-100pcnt">
            <div
              className="app-content-footer-pagination app-flex-grow-1 app-d-flex app-align-items-center app-justify-content-center"
            >
              <AppTablePagination
                activePage={page}
                onPageSelect={setPage}
                totalPages={totalPages}
              />
            </div>
            <div>
              <div className="app-table-total">
                <div className="app-table-total-label">
                  {t('Info_TotalSystems')}
                  :
                </div>
                <div className="app-table-total-value">
                  {sitesSummary?.totalSites}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
