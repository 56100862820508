import { useEffect, useRef, useState } from 'react';
import {
  IAutoPaymentEventData,
  IWorldpayData,
  useOnWorldpayOperationResult,
} from '../../../shared/appUIFramework/appBackend/useWorldpay';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';

export default function WorldpayIframe({
  worldpayData,
  loadingPlaceholder = true,
  onSuccess = () => {},
  onFailure = () => {},
}:
{
  worldpayData?: IWorldpayData,
  loadingPlaceholder?: boolean
  onSuccess?: ({ reqCardNumber, cardType } : IAutoPaymentEventData) => void,
  onFailure?: () => void,
}) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [loading, setLoading] = useState(true);

  useOnWorldpayOperationResult(onSuccess, onFailure);

  useEffect(() => {
    if (worldpayData && formRef.current) {
      setTimeout(() => {
        // if submit HTML form to the iframe it is like new page and we avoid OPTIONS request, that is not supported
        // by the worldpay
        formRef.current?.submit();
      }, 200);
    }
  }, [worldpayData]);

  return (
    <>
      <form
        style={{ display: 'none' }}
        id="tokenForm"
        ref={formRef}
        action={worldpayData?.IframeUrl}
        method="post"
        target="WorldPayIFrame"
      >
        <input
          type="text"
          defaultValue={worldpayData?.AccessKey}
          name="access_key"
        />
        <input
          type="text"
          defaultValue={worldpayData?.ProfileId}
          name="profile_id"
        />
        <input
          type="text"
          defaultValue={worldpayData?.TransactionUuid}
          name="transaction_uuid"
        />
        <input
          type="text"
          defaultValue={worldpayData?.SignedFieldname}
          name="signed_field_names"
        />
        <input
          type="text"
          defaultValue={worldpayData?.UnsignedFieldname}
          name="unsigned_field_names"
        />
        <input
          type="text"
          defaultValue={worldpayData?.MerchantDefinedData1}
          name="merchant_defined_data1"
        />
        <input
          type="text"
          defaultValue={worldpayData?.SignedDateTime}
          name="signed_date_time"
        />
        <input
          type="text"
          defaultValue={worldpayData?.Locale}
          name="locale"
        />
        <input
          type="text"
          defaultValue={worldpayData?.TransactionType}
          name="transaction_type"
        />
        <input
          type="text"
          defaultValue={worldpayData?.ReferenceNumber}
          name="reference_number"
        />
        <input
          type="text"
          defaultValue={worldpayData?.Amount}
          name="amount"
        />
        <input
          type="text"
          defaultValue={worldpayData?.Currency}
          name="currency"
        />
        <input
          type="text"
          defaultValue={worldpayData?.Signature}
          name="signature"
        />
        <input
          type="text"
          defaultValue={worldpayData?.SkipBinLookup}
          name="skip_bin_lookup"
        />
      </form>
      {loading && loadingPlaceholder && (
      <div className="app-position-relative app-w-100pcnt">
        <AppShowLoading showLoading />
      </div>
      )}
      <iframe
        className="app-w-95pcnt app-h-100pcnt"
        style={{
          visibility: loading ? 'hidden' : undefined,
        }}
        name="WorldPayIFrame"
        id="worldpayIFrame"
        title="worldpay"
        onLoad={() => setLoading(false)}
      />
    </>
  );
}
