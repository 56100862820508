import { useState } from 'react';
import { useBooleanState } from './useBooleanState';

export function useBulkDelete<TModel extends {id: any}>(): {
  bulkDeleteOn: boolean,
  turnOffBulkDelete: () => void,
  turnOnBulkDelete: () => void,
  itemsForBulkDelete: TModel[],
  toggleBulkDeleteItem: (item: TModel) => void,
  bulkDeleteDisabled: boolean,
  isBulkDeleteItemSelected: (item: TModel) => boolean,
  } {
  const { value: bulkDeleteOn, set: turnOnBulkDelete, reset } = useBooleanState(false);
  const [itemsToDelete, setItemsToDelete] = useState<TModel[]>([]);
  const bulkDeleteDisabled = itemsToDelete.length === 0;
  const turnOffBulkDelete = () => {
    reset();
    setItemsToDelete([]);
  };
  const addBulkDeleteItem = (item: TModel) => setItemsToDelete((items) => [...items, item]);
  const removeBulkDeleteItem = (item: TModel) => setItemsToDelete((items) => items.filter((p) => p.id !== item.id));
  const isBulkDeleteItemSelected = (item: TModel) => itemsToDelete.some((p) => p.id === item.id);
  const toggleBulkDeleteItem = (item: TModel) => {
    if (isBulkDeleteItemSelected(item)) {
      removeBulkDeleteItem(item);
    } else {
      addBulkDeleteItem(item);
    }
  };

  return {
    bulkDeleteOn,
    turnOffBulkDelete,
    turnOnBulkDelete,
    itemsForBulkDelete: itemsToDelete,
    toggleBulkDeleteItem,
    bulkDeleteDisabled,
    isBulkDeleteItemSelected,
  };
}
