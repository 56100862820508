import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import { AdminUserRole, getUserRoleTranslationKey } from '../../appBackend/useProfileDetails';
import BaseNotificationContent from './BaseNotificationContent';

export default function PermissionsUpdatedContent({ notification }:{notification: IAppNotification}) {
  const { t } = useTranslation();
  const { toRole } = JSON.parse(notification.jsonPayload) as { toRole: AdminUserRole };
  return (
    <BaseNotificationContent
      title={t('PermissionsUpdated')}
      description={t('YourPermissionsHaveBeenUpdated', { role: t(getUserRoleTranslationKey(toRole)) })}
      createdAt={notification.createdAt}
    />
  );
}
