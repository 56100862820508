import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'wouter';
import { ReactComponent as SignUpTopLeftLogo } from './assets/Paxton logo - White.svg';
import SignUpInstaller from './routes/signup/SignUpInstaller';
import SignUpRegion from './routes/signup/SignUpRegion';
import SignUpVariant from './routes/signup/SignUpVariant';
import SignUpVatInstallerCompanyInfo from './routes/signup/SignUpVatInstallerCompanyInfo';
import SignUpVatInstallerContactInfo from './routes/signup/SignUpVatInstallerContactInfo';
import SignUpVatInstallerEmail from './routes/signup/SignUpVatInstallerEmail';
import SignUpVatInstallerPersonalInfo from './routes/signup/SignUpVatInstallerPersonalInfo';
import SignUpVatInstallerSuccess from './routes/signup/SignUpVatInstallerSuccess';
import { Welcome } from './routes/welcome/Welcome';
import './AppForAuthenticatedUser.scss';
import AppErrorPage from './routes/AppErrorPage';
import SignUpVatInstallerError from './routes/signup/SignUpVatInstallerError';

export default function AppForUnauthenticatedUser() {
  useEffect(() => {
    // currently session storage is being used only by b2c
    // there is a bug in chrome, when after sign in started
    // and navigating back to with back arrow
    // b2c methods not working anymore (e.g. when click on the Login button
    // error in console Interaction in progress)
    // to make it working again need to clear sessions storage manually
    // so with that session storage will be cleared everytime user opens welcom page with Login button
    sessionStorage.clear();
  }, []);
  return (
    <>
      <div className="app-signup-top-left-logo">
        <SignUpTopLeftLogo />
      </div>
      <Switch>
        <Route path="/welcome">
          <Welcome />
        </Route>
        <Route path="/signup">
          <Redirect to="/signup/region" />
        </Route>
        <Route path="/signup/region">
          <SignUpRegion />
        </Route>
        <Route path="/signup/variant">
          <SignUpVariant />
        </Route>
        <Route path="/signup/installer">
          <SignUpInstaller />
        </Route>
        <Route path="/signup/vat-installer">
          <SignUpVatInstallerEmail />
        </Route>
        <Route path="/signup/vat-installer/email">
          <SignUpVatInstallerEmail />
        </Route>
        <Route path="/signup/vat-installer/personal-info">
          <SignUpVatInstallerPersonalInfo />
        </Route>
        <Route path="/signup/vat-installer/company-info">
          <SignUpVatInstallerCompanyInfo />
        </Route>
        <Route path="/signup/vat-installer/contact-info">
          <SignUpVatInstallerContactInfo />
        </Route>
        <Route path="/signup/vat-installer/success">
          <SignUpVatInstallerSuccess />
        </Route>
        <Route path="/signup/vat-installer/error">
          <SignUpVatInstallerError />
        </Route>
        <Route path="/">
          <Welcome />
        </Route>
        <Route path="/error/:code">
          <AppErrorPage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
}
