import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteSiteIcon } from '../../../assets/Popup_SystemDeleted.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';

export default function DeleteSystemSuccessPopup(
  {
    siteName,
  }: {
        siteName: string
    },
) {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      headerTitle="DeleteSystem_DeleteASystem"
      onHeaderClose={closePopup}
      extraClass="app-popup-delete-system"
      popupBody={(
        <>
          <DeleteSiteIcon />
          <span className="app-font-20 app-uppercase app-weight-600">
            { t('Popup_SystemDeleted') }
          </span>
          <span className="app-font-20 app-pb-33">
            {t('Popup_SystemHasBeenDeleted', { siteName })}
          </span>
        </>
      )}
    />
  );
}
