import ReactCountryFlag from 'react-country-flag';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as AddressIcon } from '../../assets/icons/Text field icons/Address icon.svg';
import { ReactComponent as CompanyNameIcon } from '../../assets/icons/Text field icons/Company name icon.svg';
import AppSelect from '../../shared/appUIFramework/components/AppSelect';
import { NO_SPECIAL_CHARACTERS_PATTERN, POSTCODE_MAX_LENGTH } from '../../shared/appUIFramework/constants/constants';
import {
  getCountryCodeByCountryName,
  getCountryId, getCountryName,
  listOfCountryNamesForDropdown,
} from '../../shared/appUIFramework/constants/Countries';
import SignUpPersonalInfoLayout from './components/SignUpPersonalInfoLayout';
import { useCancelVatInstallerSignUp } from './hooks/useCancelVatInstallerSignUp';
import { useSignUpState } from './hooks/useSignupState';
import { ISignUpUserDto, Region } from './signup.models';
import './SignVatInstallerForm.scss';

function getCountyTranslationKey(region: Region): string {
  switch (region) {
    case Region.Canada:
    case Region.UnitedStates:
      return 'State';
    case Region.France:
    case Region.Germany:
    case Region.Netherlands:
    case Region.Luxembourg:
    case Region.Belgium:
    case Region.SouthAfrica:
    case Region.Denmark:
      return 'Province';
    case Region.Sweden:
      return 'Region';
    case Region.Norway:
    default:
      return 'County';
  }
}

function getPostCodeTranslationKey(region: Region): string {
  switch (region) {
    case Region.UnitedStates:
      return 'ZipCode';
    case Region.Netherlands:
      return 'PostalCode';
    default:
      return 'Postcode';
  }
}

export default function SignUpVatInstallerCompanyInfo() {
  const { t } = useTranslation();
  const { getValue, setValue, signUpState: { region } } = useSignUpState();
  const signUpUser = getValue();
  const {
    register, handleSubmit, control, getValues,
    formState: {
      errors,
    },
  } = useForm<Partial<ISignUpUserDto>>({
    mode: 'onChange',
    defaultValues: {
      CompanyName: signUpUser.CompanyName,
      Address1: signUpUser.Address1,
      Address2: signUpUser.Address2,
      Town: signUpUser.Town,
      PostCode: signUpUser.PostCode,
      County: signUpUser.County,
      CountryCode: signUpUser.CountryCode ?? getCountryCodeByCountryName(region ?? ''),
      CountryId: signUpUser.CountryId,
    },
  });
  const [, setLocation] = useLocation();
  const saveFormAndNavigate = (path: string) => {
    const values = getValues();
    setValue({
      CompanyName: values.CompanyName,
      Address1: values.Address1,
      Address2: values.Address2,
      Town: values.Town,
      PostCode: values.PostCode,
      County: values.County,
      CountryCode: values.CountryCode,
      CountryId: getCountryId(values.CountryCode),
    });
    setLocation(path);
  };
  const { cancelVatInstallerSignUp } = useCancelVatInstallerSignUp();
  const saveFormAnGoToContactInfo = () => saveFormAndNavigate('/signup/vat-installer/contact-info');
  const saveFormAndGoToPersonalInfo = () => saveFormAndNavigate('/signup/vat-installer/personal-info');

  return (
    <SignUpPersonalInfoLayout currentStep={2}>
      <form onSubmit={handleSubmit(saveFormAnGoToContactInfo)} className="app-d-flex app-flex-column app-gap-23">
        <div>
          <div className="app-signup-input-container">
            <CompanyNameIcon className="app-signup-input-prefix" />
            <input
              {...register('CompanyName', { required: true })}
              data-invalid={errors.CompanyName ? 'true' : 'false'}
              type="text"
              className="app-form-input app-signup-input"
              placeholder={t('CompanyName')}
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors.CompanyName && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
        </div>
        <div>
          <div className="app-signup-input-container">
            <AddressIcon className="app-signup-input-prefix" />
            <input
              {...register('Address1', { required: true })}
              data-invalid={errors.Address1 ? 'true' : 'false'}
              placeholder={t('Address1')}
              type="text"
              className="app-form-input app-signup-input"
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors.Address1 && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
        </div>
        <div>
          <div className="app-signup-input-container">
            <AddressIcon className="app-signup-input-prefix" />
            <input
              {...register('Address2')}
              data-invalid={errors.Address2 ? 'true' : 'false'}
              placeholder={t('Address2')}
              type="text"
              className="app-form-input app-signup-input"
            />
          </div>
          {errors.Address2 && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
        </div>
        <div>
          <div className="app-signup-input-container">
            <AddressIcon className="app-signup-input-prefix" />
            <input
              {...register('Town', { required: true })}
              data-invalid={errors.Town ? 'true' : 'false'}
              placeholder={t('Town')}
              type="text"
              className="app-form-input app-signup-input"
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors.Town && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
        </div>
        <div>
          <div className="app-signup-input-container">
            <AddressIcon className="app-signup-input-prefix" />
            <input
              {...register('PostCode', {
                required: true,
                maxLength: POSTCODE_MAX_LENGTH,
                pattern: NO_SPECIAL_CHARACTERS_PATTERN,
              })}
              data-invalid={errors.PostCode ? 'true' : 'false'}
              placeholder={t(getPostCodeTranslationKey(region!))}
              type="text"
              className="app-form-input app-signup-input"
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors.PostCode?.type === 'required' && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
          { errors.PostCode?.type === 'pattern' && (
          <span className="app-signup-input-error">
            { t(
              'InputInvalidCharactersErrorMessage',
            ) }
          </span>
          ) }
          { errors.PostCode?.type === 'maxLength'
            && (
              <span className="app-signup-input-error">
                { t(
                  'InputMaxLengthErrorMessage',
                  { maxLength: POSTCODE_MAX_LENGTH },
                ) }
              </span>
            ) }
        </div>
        <div>
          <div className="app-signup-input-container">
            <AddressIcon className="app-signup-input-prefix" />
            <input
              {...register('County', { required: true })}
              data-invalid={errors.County ? 'true' : 'false'}
              placeholder={t(getCountyTranslationKey(region!))}
              type="text"
              className="app-form-input app-signup-input"
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors.County && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
        </div>
        <div>
          <div className="app-signup-input-container">
            <Controller
              control={control}
              name="CountryCode"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <AppSelect
                  className="app-signup-form-select app-select-not-in-view"
                  inputClassName="app-signup-input"
                  incomingValue={getCountryName(value)}
                  placeholder={t('SelectCountry')}
                  data-invalid={errors?.CountryCode ? 'true' : 'false'}
                  options={listOfCountryNamesForDropdown()}
                  getOptionPrefix={(countryCode) => {
                    const optionPrefixCountryCode = countryCode || value;
                    return (optionPrefixCountryCode ? (
                      <>
                        <ReactCountryFlag
                          countryCode={optionPrefixCountryCode || ''}
                          svg
                        />
                      </>
                    ) : (
                      <>
                        <AddressIcon className="app-signup-select-input-prefix" />
                      </>
                    ));
                  }}
                  onOptionSelected={(countryName) => {
                    onChange(countryName.value);
                    setValue({ CountryCode: countryName.value });
                  }}
                />
              )}
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors.CountryCode && <span className="app-signup-input-error">{t('Form_FieldRequired')}</span>}
        </div>
        <div className="app-d-flex app-gap-23">
          <button className="app-secondary-button app-signup-button-paddings" type="button" onClick={saveFormAndGoToPersonalInfo}>
            {t('Back')}
          </button>
          <button className="app-primary-button app-signup-button-paddings" type="submit">
            {t('Next')}
          </button>
        </div>
      </form>
      <div className="app-d-flex app-w-100pcnt app-justify-content-center app-mt-22">
        <button className="app-link-primary-button" type="button" onClick={cancelVatInstallerSignUp}>
          {t('Cancel')}
        </button>
      </div>
    </SignUpPersonalInfoLayout>
  );
}
