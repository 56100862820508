import { CURRENCY_DECIMALS_AMOUNT } from '../appUIFramework/constants/constants';

export function roundToDecimalPlaces(value:number, decimalPlaces: number) {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(value * multiplier) / multiplier;
}

// when we display currency on the UI, we want to round it to 2 decimal places, and if don't round in calculation
// the value calculated without rounding may be different
// so during calculation we should apply rounding
export function roundAsCurrencyOnUI(value: number) {
  return roundToDecimalPlaces(value, CURRENCY_DECIMALS_AMOUNT);
}
