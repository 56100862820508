import { ReactNode } from 'react';
import EntryMonitor from '../../../assets/Entry Monitor sign in image.png';
import PaxtonLogoSvg from '../../../assets/MyPaxton Logo.svg';

export default function SignupLayout({ className, children }:{className?: string, children: ReactNode}) {
  return (
    <div className={`app-d-flex app-signup-layout-base ${className || ''}`}>
      <img
        className="app-w-50pcnt app-h-100vh app-fit-cover"
        src={EntryMonitor}
        alt="login"
      />
      <div className="app-w-50pcnt app-d-flex app-flex-column app-align-items-center">
        <img
          className="app-mt-150 app-mb-137 app-signup-logo"
          src={PaxtonLogoSvg}
          alt="logo"
        />
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}
