import {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SystemsIcon } from '../../../assets/icons/Lefthand navigation icons/Systems icon.svg';
import { ISystemAdminInfo } from '../../../shared/appUIFramework/appBackend/useProfileDetails';
import AppSelect from '../../../shared/appUIFramework/components/AppSelect';
import { SEARCH_DEBOUNCE_TIME_MS } from '../../../shared/appUIFramework/constants/constants';
import { useLazyLoad } from '../../../shared/appUIFramework/hooks/useLazyLoad';
import { debounce } from '../../../shared/jsUtils/debounce';
import { ISite } from '../../systems/models/ISiteInfo';
import { Urls } from '../../../shared/backend/urls';
import { getCompanyIdFromClaims } from '../../../shared/backend/auth/claims';

export default function AdminFormSystemsSelect({
  addSystem,
  addedSystemsIds,
  disabled,
}:
  {
    addedSystemsIds: string[],
    addSystem: (site: ISystemAdminInfo) => void,
    disabled: boolean
  }) {
  const { t } = useTranslation();
  const companyId = getCompanyIdFromClaims();
  const sitesUrl = Urls.SitesWithCompanyId(companyId);
  const [search, setSearch] = useState('');
  const {
    items: systems, reset, loadMore, lastPageLoaded, loading,
  } = useLazyLoad<ISite>(sitesUrl, { search });

  useEffect(() => {
    loadMore();
  }, []);

  // on search start infinite scroll from the beginning
  const handleSearch = useCallback(debounce((newSearch: string) => {
    setSearch(newSearch);
  }, SEARCH_DEBOUNCE_TIME_MS), [reset]);

  return (
    <div
      className="app-form-control-input"
    >
      <div className="app-form-control-prefix">
        <SystemsIcon />
      </div>
      <AppSelect
        showLoading={loading}
        className="app-form-input"
        disabled={disabled}
        /* have to use getValues, there is a bug:
         when use value - it is not updated if array updated with setValues,
         https://github.com/react-hook-form/react-hook-form/issues/4368, the bottom comment */
        options={systems?.filter((p) => addedSystemsIds.indexOf(p.id) === -1)
          .map((p) => ({ value: p.siteName, label: p.siteName })) || []}
        onItemsContainerScroll={(e) => {
          const target = e.target as HTMLDivElement;
          const isBottomReached = target.scrollTop + target.getBoundingClientRect().height >= target.scrollHeight;
          if (isBottomReached && !lastPageLoaded) {
            loadMore();
          }
        }}
        placeholder={t('SelectSite')}
        onChange={(e) => {
          reset();
          handleSearch(e.currentTarget.value);
        }}
        onOptionSelected={(siteName, _options, _index, { selectedOnTyping }) => {
          if (selectedOnTyping) {
            return;
          }

          const selectedSite = systems!.find((p) => p.siteName === siteName.label);
          const newValue = selectedSite ? {
            systemId: selectedSite.id,
            systemName: selectedSite.siteName,
          } as ISystemAdminInfo
            : undefined;
          if (newValue) {
            addSystem(newValue);
          }
        }}
      />
    </div>
  );
}
