import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Text field icons/Email  icon.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { B2CLangCode } from '../../../shared/appUIFramework/constants/B2CLangCode';
import { useAppLocalisation } from '../../../shared/i18n/config';

const helpInfo: Record<B2CLangCode, { telephone: string, email: string }> = {
  [B2CLangCode.English]: {
    telephone: '01273 811011',
    email: 'support@paxton.co.uk',
  },
  [B2CLangCode.SpanishLatinAmerica]: {
    telephone: '(877) 438-7298',
    email: 'supportUS@paxton-access.com',
  },
  [B2CLangCode.German]: {
    telephone: '+49 (0)251 2080 6900',
    email: 'verkauf@paxton-gmbh.de',
  },
  [B2CLangCode.French]: {
    telephone: '01 57 32 93 56',
    email: 'support@paxtonaccess.fr',
  },
  [B2CLangCode.Netherlands]: {
    telephone: '+31(0)76 3333 999',
    email: 'support@paxton-benelux.com',
  },
  [B2CLangCode.Swedish]: {
    telephone: '020888547',
    email: 'support@paxtonaccess.se',
  },
  [B2CLangCode.Danish]: {
    telephone: '080820046',
    email: 'support@paxtonaccess.se',
  },
  [B2CLangCode.Norwegian]: {
    telephone: '80058262',
    email: 'support@paxtonaccess.se',
  },
  [B2CLangCode.EnglishUS]: {
    telephone: '(877) 438-7298',
    email: 'supportUS@paxton-access.com',
  },
};

export default function CreatePaymentAdminFailurePopup() {
  const { t } = useTranslation();
  const { lang } = useAppLocalisation();
  const goBack = () => closePopup(true);
  const discardChanges = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="Popup_Error"
      popupBody={(
        <>
          <EmailIcon className="app-w-160 app-h-128" />
          <span className="app-d-inline-block app-mw-500">{t('LooksLikeYourEmailInUseByAnotherCompany', helpInfo[lang])}</span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={discardChanges} className="app-secondary-button app-popup-left-button">
            {t('Popup_DiscardChanges')}
          </button>
          <button type="button" onClick={goBack} className="app-primary-button app-popup-right-button">
            {t('Popup_GoBack')}
          </button>
        </>
      )}
    />
  );
}
