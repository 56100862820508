import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { ReactComponent as DeleteUsersIcon } from '../../../assets/icons/Delete Person.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function BulkDeleteSystemsConfirmationPopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="Popup_DeleteUsers"
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
          <button type="button" onClick={confirm} className="app-warn-button app-popup-right-button">{t('Table_Delete')}</button>
        </>
      )}
      popupBody={(
        <>
          <DeleteUsersIcon className="app-w-86 app-h-163" />
          <span className="app-font-20 ">{t('Popup_AreYouSureYouWantToDeleteTheseUsers')}</span>
          <div className="app-font-20 app-pt-10">{t('Popup_TheActionIsPermanentAndIsNotReversed')}</div>
        </>
      )}
    />
  );
}
