import {
  KeyboardEventHandler,
  MouseEventHandler,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import SelectIcon from '../../../assets/icons/Button-Control icons/Select icon.svg';
import SelectedIcon from '../../../assets/icons/Button-Control icons/Selected icon.svg';
import DeleteIcon from '../../../assets/icons/Text field icons/Delete icon.svg';
import { AccountStatusUIState, applyAccountStatusStyles, useAccountInfo } from '../appBackend/useAccountInfo';
import callIfEnterKeyPressed from '../../jsUtils/callIfEnterKeyPressed';

export default function AppDeleteButton(
  {
    bulkDeleteOn = false,
    bulkDeleteItemSelected,
    deleteAction,
    bulkDeleteAction = () => {},
  }:
    {
      bulkDeleteOn?: boolean,
      bulkDeleteItemSelected?: boolean,
      deleteAction: () => void,
      bulkDeleteAction?: () => void,
    },
) {
  let imgSrc: string;

  const { accountInfo } = useAccountInfo();
  const isAccountFunctionalityLimited = accountInfo.accountStatus === AccountStatusUIState.Limited;

  const deleteHandler: ((e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => void) = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAccountFunctionalityLimited) return;
    if (bulkDeleteOn) {
      bulkDeleteAction();
    } else {
      deleteAction();
    }
  };

  if (bulkDeleteOn) {
    imgSrc = bulkDeleteItemSelected ? SelectedIcon : SelectIcon;
  } else {
    imgSrc = DeleteIcon;
  }

  return (
    <div
      className="app-h-30 app-d-flex app-justify-content-end"
      tabIndex={0}
      style={{
        padding: 0,
      }}
      role="button"
      onClick={deleteHandler as MouseEventHandler}
      onKeyDown={callIfEnterKeyPressed(() => deleteHandler as KeyboardEventHandler)}
    >
      <div
        className={`app-d-flex app-fit-content ${bulkDeleteOn ? 'app-table-bulk-delete-icon' : 'app-table-action-icon app-m-auto'}`}
        {...applyAccountStatusStyles(accountInfo)}
      >
        <img
          src={imgSrc}
          alt="Delete"
        />
      </div>
    </div>

  );
}
