import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { KeyedMutator } from 'swr';
import { handleHttpRequestError, useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { Urls } from '../../backend/urls';
import { useClaims } from '../../backend/auth/claims';
import { getUrlWithQueryParams, httpGetJson, httpPut } from '../../backend/http/http';
import { roundAsCurrencyOnUI } from '../../jsUtils/roundToDecimals';
import { useSites } from './useSites';

interface IInvoiceDetailsAddress {
    address1: string;
    address2: string;
    townCity: string;
    country: string;
    postcode: string;
    county: string;
}

enum ProductEnum {
    Unknown = 0,
    EntryApp = 1,
    P10Cloud = 2
}

export enum InvoiceStatus {
    Unknown = 0,
    Unpaid = 1,
    Pending = 2,
    Paid = 3,
    Failed = 4,
    Overdue = 5
}

export enum InvoicePaymentMethod {
    Unknown = 0,
    Auto = 1,
    Invoice = 2,
}

enum All {
    All = -1,
}

export enum CurrencyCode {
    GBP = 0,
    EUR = 1,
    USD = 2,
}

export enum CurrencyCodeString {
    GBP = 'GBP',
    EUR = 'EUR',
    USD = 'USD'
}
export enum CurrencyCodeSymbol {
    GBP = '£',
    EUR = '€',
    USD = '$'
}

export function getCurrencyCodeStringFromId(currencyCodeId: CurrencyCode): CurrencyCodeString {
  const codes: Record<CurrencyCode, CurrencyCodeString> = {
    [CurrencyCode.GBP]: CurrencyCodeString.GBP,
    [CurrencyCode.EUR]: CurrencyCodeString.EUR,
    [CurrencyCode.USD]: CurrencyCodeString.USD,
  };

  return codes[currencyCodeId];
}

export const InvoiceStatusFilterOptions = {
  ...InvoiceStatus,
  ...All,
};

export type InvoiceStatusFilterOptionsType = InvoiceStatus | All;

export const InvoicePaymentMethodFilterOptions = {
  ...InvoicePaymentMethod,
  ...All,
};

export type InvoicePaymentMethodFilterOptionsType = InvoicePaymentMethod | All;

export enum DiscountType {
    None = 0,
    InstallerTierDiscount = 6,
    DiscretionaryDiscount = 7
}

export interface IBillableUnit {
    productId: ProductEnum;
    salesCode: string;
    description: string;
    discountType: DiscountType;
    numberOfUnits: number;
    retailPrice: number;
    billableUnitUniqueId: string;
}

enum InstallerLoyaltyTierEnum {
    None = 0,
    LoyaltyPaxtonPartner,
    LoyaltySilverPartner,
    LoyaltyGoldPartner,
    LoyaltyPlatinumPartner
}

export interface IDiscount {
    typeOfDiscountId: DiscountType;
    installerLoyaltyTierId: InstallerLoyaltyTierEnum;
    discountPercent: number;
    discountAppliedOrder: number;
}

interface IInvoiceCost {
    discountsApplied: IDiscount[];
    currencyCodeId: CurrencyCode;
    conversionFactor: number;
    billableUnits: IBillableUnit[];
    vatPercentage: number;
}

interface IBillableUnitWithCost extends IBillableUnit {
    SiteCost: number;
    DiscountedSiteCost: number;
    SiteDiscount: number;
}

interface IInvoiceTotals {
    Subtotal: number;
    Vat: number;
    Total: number;
    TotalDiscount: number;
    BillableUnitsWithCosts: IBillableUnitWithCost[];
}

export interface IInvoice extends IInvoiceCost {
    id: string;
    applicationId: number;
    customerReference: string;
    invoiceId: number;
    auditDateTime: string;
    invoiceStatusId: InvoiceStatus;
    InvoicePaymentMethodId: InvoicePaymentMethod;
    email: string;
    orderId: number;
    orderDate: string;
    invoiceDate: string;
    paymentDueDate: string;
    invoiceAddress: IInvoiceDetailsAddress;
    salesRegionName: string;
    salesRegionId: number;
    billingId: number;
    environment: string;
    Totals: IInvoiceTotals;

    // not implemented
    customerVatNumber: string;
}

export function fillNotImplementedValues() {
  const { branchOfficeId } = useClaims();

  return (p: IInvoice) => ({
    ...p,
    customerReference: branchOfficeId,
  } as IInvoice);
}

export function useInvoice(invoiceId: number): { invoice: IInvoice | undefined } {
  const { customerReference } = useClaims();
  const url = Urls.Invoice(customerReference, invoiceId);
  const { data } = useSWRAndHandleErrors<IInvoice>(url, () => httpGetJson<IInvoice>(url).then(fillNotImplementedValues()));
  if (data) {
    data.billableUnits = data.billableUnits.sort((siteA, siteB) => siteA.description.localeCompare(siteB.description));
  }
  return { invoice: data };
}

export async function getInvoice(customerReference: string, invoiceId: number): Promise<IInvoice | null> {
  // todo: refactor to use global mutate from useSWR to update cache for the invoice details
  // that will improve performance when opening details for the invoice that was already loaded
  try {
    const url = Urls.Invoice(customerReference, invoiceId);
    return httpGetJson<IInvoice>(url).then(fillNotImplementedValues());
  } catch (e) {
    // log left to log if any js error happened in try
    // eslint-disable-next-line no-console
    console.error(e);
    await handleHttpRequestError(e);
    return null;
  }
}

export interface IInvoiceFilter {
    paidByMethod?: InvoicePaymentMethodFilterOptionsType;
    invoiceStatusIds?: InvoiceStatusFilterOptionsType | InvoiceStatusFilterOptionsType[];
}

interface IInvoicesTotalNumber {
    totalNumber: number;
}

export function useCurrencyOptions() {
  const { t } = useTranslation();
  const currencyTranslationsRef = useRef<Record<CurrencyCode, string>>({
    [CurrencyCode.GBP]: t('GBP'),
    [CurrencyCode.EUR]: t('EUR'),
    [CurrencyCode.USD]: t('USD'),
  });

  const currencyTranslations = currencyTranslationsRef.current;
  const options = Object.keys(currencyTranslations).map((c) => +c) as CurrencyCode[];

  return { options, getOptionLabel: (option: CurrencyCode) => currencyTranslations[option] };
}

export function useInvoicePaymentMethodOptions() {
  const { t } = useTranslation();
  const paymentMethodTranslationsRef = useRef<Record<InvoicePaymentMethodFilterOptionsType, string>>({
    [InvoicePaymentMethodFilterOptions.All]: t('All'),
    [InvoicePaymentMethodFilterOptions.Unknown]: t('-'),
    [InvoicePaymentMethodFilterOptions.Auto]: t('Auto'),
    [InvoicePaymentMethodFilterOptions.Invoice]: t('Manual'),
  });

  const paymentMethodTranslations = paymentMethodTranslationsRef.current;
  const options = [
    InvoicePaymentMethodFilterOptions.All,
    InvoicePaymentMethodFilterOptions.Unknown,
    InvoicePaymentMethodFilterOptions.Invoice,
    InvoicePaymentMethodFilterOptions.Auto,
  ];
    // @ts-ignore
  const getOptionLabel = (option: InvoicePaymentMethodFilterOptionsType) => paymentMethodTranslations[option];

  return { options, getOptionLabel };
}

export function useInvoiceStatusOptions() {
  const { t } = useTranslation();
  const invoiceStatusesTranslationsRef = useRef<Record<InvoiceStatusFilterOptionsType, string>>({
    [InvoiceStatusFilterOptions.All]: t('All'),
    [InvoiceStatusFilterOptions.Unknown]: t('-'),
    [InvoiceStatusFilterOptions.Unpaid]: t('InvoiceStatusDue'),
    [InvoiceStatusFilterOptions.Pending]: t('InvoiceStatusProcessing'),
    [InvoiceStatusFilterOptions.Paid]: t('InvoiceStatusPaid'),
    [InvoiceStatusFilterOptions.Failed]: t('InvoiceStatusFailed'),
    [InvoiceStatusFilterOptions.Overdue]: t('InvoiceStatusOverdue'),
  });

  const invoiceStatusesTranslations = invoiceStatusesTranslationsRef.current;
  const options = Object.keys(invoiceStatusesTranslations)
    .filter((p) => +p !== InvoiceStatusFilterOptions.Unknown)
    .map((p) => +p).sort() as InvoiceStatus[];
  // @ts-ignore
  const getOptionLabel = (option: InvoiceStatus) => invoiceStatusesTranslations[option];

  return { options, getOptionLabel };
}

export function getInvoiceStatusOptionsForUrlParameter(invoiceStatusId: InvoiceStatusFilterOptionsType | InvoiceStatusFilterOptionsType[] | undefined) {
  if (invoiceStatusId === undefined) {
    return undefined;
  }
  if (Array.isArray(invoiceStatusId)) {
    return invoiceStatusId.includes(InvoiceStatusFilterOptions.All) ? undefined : JSON.stringify(invoiceStatusId);
  }

  return invoiceStatusId === InvoiceStatusFilterOptions.All ? undefined : JSON.stringify([invoiceStatusId]);
}

export function useInvoices(invoiceFilter: IInvoiceFilter): {
    invoices: IInvoice[] | undefined,
    mutate: KeyedMutator<IInvoice[]>
} {
  const { customerReference } = useClaims();
  const url = Urls.Invoices(customerReference);

  const urlWithFilter = getUrlWithQueryParams(url, {
    ...invoiceFilter,
    invoiceStatusIds: getInvoiceStatusOptionsForUrlParameter(invoiceFilter.invoiceStatusIds), // allows for getting invoices with different statuses
  } as IInvoiceFilter);
  const { data, mutate } = useSWRAndHandleErrors<IInvoice[]>(urlWithFilter, () => httpGetJson<IInvoice[]>(urlWithFilter)
    .then((p) => p.map(fillNotImplementedValues())));
  return {
    invoices: data,
    mutate,
  };
}

export async function handleInvoiceOneTimePaymentSuccess(invoiceId: number, customerReference: string) {
  try {
    await httpPut(Urls.InvoiceHandleOneTimePaymentSuccess(customerReference, invoiceId), {});
  } catch (e) {
    await handleHttpRequestError(e);
  }
}

export function useInvoicesTotalNumber(queryParams: IInvoiceFilter) {
  const { customerReference } = useClaims();
  const url = Urls.InvoicesTotalNumber(customerReference);
  const urlWithFilter = getUrlWithQueryParams(url, {
    ...queryParams,
    invoiceStatusIds: getInvoiceStatusOptionsForUrlParameter(queryParams.invoiceStatusIds), // allows for getting invoices with different statuses
  } as IInvoiceFilter);
  const { data } = useSWRAndHandleErrors<IInvoicesTotalNumber>(urlWithFilter, httpGetJson);

  return data?.totalNumber;
}

export function getDiscountPercent(discounts: IDiscount[], type: DiscountType) {
  const discount = discounts.find((p) => p.typeOfDiscountId === type);
  if (discount && (discount.discountPercent > 1 || discount.discountPercent < 0)) {
    throw new Error('Discount should be between 0 and 1');
  }
  return discount?.discountPercent || 0;
}

export function getDiscountMultiplier(discounts: IDiscount[], type: DiscountType) {
  return 1 - getDiscountPercent(discounts, type);
}

export function getTotalUnitsPrice(unitPrice: number, numberOfUnits: number) {
  return unitPrice * numberOfUnits;
}

export function getUnitPriceWithDiscount(billableUnit: IBillableUnit, discountsApplied: IDiscount[], conversionFactor: number) {
  return conversionFactor * billableUnit.retailPrice * getDiscountMultiplier(discountsApplied, DiscountType.InstallerTierDiscount);
}

export function getTotalUnitsPriceWithDiscount(billableUnit: IBillableUnit, { discountsApplied, conversionFactor }: IInvoiceCost) {
  const unitPrice = getUnitPriceWithDiscount(billableUnit, discountsApplied, conversionFactor);
  const unitPriceRounded = roundAsCurrencyOnUI(unitPrice);
  return getTotalUnitsPrice(unitPriceRounded, billableUnit.numberOfUnits);
}

export function getSiteNameFromId(siteId: string) {
  const { sites } = useSites({ search: '' });

  return sites?.find((site) => site.id === siteId)?.siteName ?? undefined;
}
