import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import PaxtonLogoSvg from '../../assets/MyPaxton Logo.svg';
import QrCode from '../../assets/qrcode.Installer-app-UK.svg';
import MobileImage from '../../assets/Installer app sign up.svg';
import { ReactComponent as ScanMeArrow } from '../../assets/Scan me arrow.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/Text field icons/Search icon.svg';
import GetOnGoogleImage from '../../assets/GetItOnGoogle.png';
import GetOnAppStoreImage from '../../assets/DownloadOnAppStore.png';

import './SignUpInstaller.scss';

export default function SignUpInstaller() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const goToSignUpVariant = () => setLocation('/signup/variant');
  return (
    <div className="app-d-flex app-signup-installer app-position-relative app-h-100vh">
      <div className="app-signup-installer-description-container app-flex-1 app-d-flex app-flex-column app-align-items-center">
        <div className="app-signup-installer-description">
          <p className="app-signup-installer-description-text">
            {t('BecomeARegisteredInstaller')}
          </p>
          <p className="app-signup-installer-description-list">
            <ul>
              <li>{t('PaxtonRewardsProgram')}</li>
              <li>{t('SalesBrochures')}</li>
              <li>{t('ProductInformation')}</li>
              <li>{t('Knowledgebase')}</li>
              <li>{t('PaxtonNews')}</li>
            </ul>
          </p>
        </div>
        <div className="app-signup-installer-mobile-image">
          <img src={MobileImage} alt="mobile" />
        </div>
      </div>
      <div className="app-flex-1">
        <div className="app-signup-installer-body">
          <img
            className="app-mt-90  app-m-auto app-d-block app-mb-30 app-signup-logo"
            src={PaxtonLogoSvg}
            alt="logo"
          />
          <div className="app-d-flex app-flex-column ">
            <div className="app-font-28 app-weight-600 app-mb-30">
              {t('SignUp')}
            </div>
            <div className="app-font-21-25 app-mb-30">
              {t('YouMustBeRegistered')}
            </div>
            <div className="app-font-21-25 app-mb-65">
              <span className="app-color-primary-green app-weight-600">
                {t('Scan')}
              </span>
              &nbsp;
              <span>
                {t('TheQRCodeOrUseTheLink')}
              </span>
            </div>
            <div className="app-d-flex app-flex-column app-justify-content-center app-align-items-center">
              <div className="app-signup-qr-code app-mb-17">
                <div className="app-signup-qr-code-image app-mb-7">
                  <img src={QrCode} alt="qr code" />
                </div>

                <div className="app-signup-qr-code-helper">
                  <span>{t('ScanMe')}</span>
                  <div className="app-signup-qr-code-arrow">
                    <ScanMeArrow />
                  </div>
                </div>
              </div>
              <div className="app-d-flex app-mt-10 app-search app-fake-search">
                <SearchIcon width={13} />
                <div className="app-pl-10 app-font-16-22 app-weight-600 app-color-primary-green">
                  {t('#PaxtonInstaller')}
                </div>
              </div>
              <div className="app-d-flex">
                <div>
                  <a href="https://paxton.info/6654">
                    <img className="app-store-image" src={GetOnGoogleImage} alt="Get it on google play store" />
                  </a>
                </div>
                <div>
                  <a href="https://paxton.info/6653">
                    <img className="app-store-image" src={GetOnAppStoreImage} alt="Download on the app store" />
                  </a>
                </div>
              </div>
              <button className="app-link-primary-button app-mt-33 app-mb-32" type="button" onClick={goToSignUpVariant}>
                {t('Back')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
