import { ReactComponent as DeletePersonIcon } from '../../assets/icons/Delete Person.svg';
import { closePopup } from '../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';

export default function ProfileCanNotDeletePopup({ message, headerKey }:{message: string, headerKey: string}) {
  const cancel = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle={headerKey}
      onHeaderClose={cancel}
      popupBody={(
        <>
          <DeletePersonIcon />
          <span className="app-popup-info">
            {message}
          </span>
        </>
      )}
    />
  );
}
