import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/Person Deleted.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { AdminUserRole } from '../../../shared/appUIFramework/appBackend/useProfileDetails';
import { getDeleteAdminSuccessfulTextByRole } from '../AdminPeople';

export default function DeleteAdminSuccessPopup(
  {
    role,
  }: {
    role: AdminUserRole
},
) {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);

  const popupTranslationKeys = useRef(getDeleteAdminSuccessfulTextByRole(role));

  return (
    <AppPopupLayout
      headerTitle={popupTranslationKeys.current.header}
      extraClass="app-popup-delete-user"
      onHeaderClose={cancel}
      popupBody={(
        <>
          <DeleteIcon />
          <span className="app-font-20 app-uppercase app-weight-600">
            { t(popupTranslationKeys.current.subheader) }
          </span>
          <span className="app-font-20">
            { t(popupTranslationKeys.current.subtext) }
          </span>
        </>
      )}
    />
  );
}
