import { useTranslation } from 'react-i18next';

export function GetDateFromString(dateString: string): Date {
  return new Date(dateString);
}

export enum DateFormats {
    ShortDate = 'DATE_SHORT',
    MobileDate = 'DATE_MOBILE',
    LongDate = 'DATE_LONG',
    LongDateMobile = 'DATE_LONG_MOBILE',
}

export function useFormatDate() {
  const { i18n } = useTranslation();

  return (dateOrDateString: Date | string, format = DateFormats.MobileDate) => {
    const date = typeof dateOrDateString === 'string' ? GetDateFromString(dateOrDateString) : dateOrDateString;
    return i18n.format(date, format, i18n.language);
  };
}
