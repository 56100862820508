import { useCompany } from './useCompany';
import { ICompanyActivity } from '../../../routes/systems/models/ICompany';
import { ISiteSummary } from '../../../routes/systems/models/ISiteSummary';
import { useSitesSummary } from './useSitesSummary';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getAccountActivity(companyActivities: ICompanyActivity[] | undefined, year: string | number, metricType: 'users' | 'sites', sitesSummary?: ISiteSummary) {
  if (!companyActivities) {
    return { activity: [] };
  }
  const yearActivities = companyActivities.filter((a) => a.year === +year);
  const resultActivities: number[] = [];

  for (let i = 0; i < 12; i += 1) {
    const monthActivity = yearActivities.find((a) => a.month === i);
    const metricValue = metricType === 'users'
      ? monthActivity?.activeUsersCount
      : monthActivity?.sitesCount;
    resultActivities.push(metricValue ?? 0);
  }

  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  if (currentYear.toString() === year.toString()) {
    resultActivities[currentMonth] = (metricType === 'users' ? sitesSummary?.activeUsers : sitesSummary?.totalSites) || 0;
  }
  return {
    activity: resultActivities,
  };
}

export function useAccountActivity(year: string | number, metricType: 'users' | 'sites'): { activity: number[] | undefined, startYear: number } {
  const company = useCompany();
  const { sitesSummary } = useSitesSummary({ search: '' });
  const { activity } = getAccountActivity(company?.companyActivities, year, metricType, sitesSummary);
  const startYear = new Date(company?.termsAndConditionsAcceptedAt ?? new Date().toString()).getFullYear();
  return { activity, startYear };
}

export function getActivityGrowth(activity: number[], currentMonth: number): number {
  const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const current = activity[currentMonth];
  const prev = activity[prevMonth];

  if (prev === 0) { // can not devide by 0
    return current === 0 ? 0 : 1; // assuming, if prev 0 current can not be negative
  }

  return (current - prev) / prev; // curr 110, prev 100, growth 10%
}
