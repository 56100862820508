import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import SignUpPersonalInfoLayout from './components/SignUpPersonalInfoLayout';
import './SignUpVatInstallerSuccess.scss';
import { ReactComponent as WarningIcon } from '../../assets/Credentials in use icon.svg';

export default function SignUpVatInstallerError() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const goToSignup = () => setLocation('/signup/vat-installer');

  return (
    <SignUpPersonalInfoLayout hideTitle currentStep={4}>
      <div className="app-signup-success">
        <WarningIcon />
        <span>{t('LooksLikeSmthWentWrongConnection')}</span>
        <button onClick={goToSignup} type="button" className="app-button app-primary-button">{t('BackToSignUp')}</button>
      </div>
    </SignUpPersonalInfoLayout>
  );
}
