import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function DeleteEntryUserConfirmationPopup({ user, status }:{user: string, status: string}) {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="DeleteUser_DeleteAUser"
      extraClass="app-popup-delete-user"
      popupBody={(
        <>
          <div className="app-delete-system-popup-title app-pt-30">
            {t('DeleteUser_AreYouSure')}
          </div>
          <form onSubmit={confirm} className="app-popup-form">
            <div className="app-popup-form-controls">
              <div className="app-popup-form-control">
                <div className="app-popup-form-control-label">
                  <span>{ t('Table_Name') }</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    value={user}
                    readOnly
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
              </div>
              <div className="app-popup-form-control">
                <div className="app-popup-form-control-label">
                  <span>{ t('License') }</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    value={status}
                    readOnly
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
              </div>
            </div>
            <div className="app-popup-buttons">
              <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
              <button type="submit" className="app-warn-button app-popup-right-button">{t('Table_Delete')}</button>
            </div>
          </form>
        </>
      )}
    />
  );
}
