import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'wouter';
import AppForAuthenticatedUser from './AppForAuthenticatedUser';
import './index.css';
import AppForUnauthenticatedUser from './AppForUnauthenticatedUser';
import { useAppUnsavedChangesLocation } from './shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import { StorageKey } from './shared/appUIFramework/constants/StorageKeys';
import AppPopup from './shared/appUIFramework/popup/AppPopup';
import { logout, msalInstance } from './shared/backend/auth/auth';
import { handleResetPasswordPage } from './shared/backend/auth/passwordChangeUtils';

// When a device is hibernating or sleeping useLogout doesn't work but it still sets the localstorage in useLogout.ts
// Then when the device awakens and the site is active, this checks localstorage and performs the logout again
if (localStorage.getItem(StorageKey.Logout)) {
  localStorage.removeItem(StorageKey.Logout);
  logout();
} else {
  handleResetPasswordPage();
  ReactDOM.render(
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <Router hook={useAppUnsavedChangesLocation}>
          <AppPopup />
          <AuthenticatedTemplate>
            <AppForAuthenticatedUser />
          </AuthenticatedTemplate>
        </Router>
        <UnauthenticatedTemplate>
          <AppForUnauthenticatedUser />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </StrictMode>,
    document.getElementById('root'),
  );
}
