import {
  ReactNode, RefObject, useCallback, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/Tooltip icon.svg';
import AppOutsideListener from './AppOutsideListener';
/* eslint-disable no-case-declarations */
export enum AppTooltipPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

function getTooltipPositionClass(position: AppTooltipPosition) {
  // When using Record type, typescript will ensure, that there is a key for every enum value
  const classes: Record<AppTooltipPosition, string> = {
    [AppTooltipPosition.Top]: 'app-tooltip-body-top',
    [AppTooltipPosition.Right]: 'app-tooltip-body-right',
    [AppTooltipPosition.Bottom]: 'app-tooltip-body-bottom',
    [AppTooltipPosition.Left]: 'app-tooltip-body-left',
  };
  // Default returns the right side position for if position is undefined
  return classes[position];
}

const zoomAppliedAtPx = 571;
const zoomFactor = 0.5;

function getBoundingClientRect(element: HTMLElement) {
  const {
    top,
    right,
    bottom,
    left,
    width,
    height,
  } = element.getBoundingClientRect();
  const zoomFactorApplied = window.innerWidth <= zoomAppliedAtPx ? zoomFactor : 1;
  return {
    top: top * zoomFactorApplied,
    right: right * zoomFactorApplied,
    bottom: bottom * zoomFactorApplied,
    left: left * zoomFactorApplied,
    width,
    height,
  };
}

function getAdjustedXForPosition(position: AppTooltipPosition, tooltipBody: HTMLDivElement, tooltipTrigger: HTMLDivElement) {
  switch (position) {
    case AppTooltipPosition.Left:
      const xLeftPosition = (getBoundingClientRect(tooltipTrigger).left - 16)
        - getBoundingClientRect(tooltipBody).width;
      return {
        position: xLeftPosition,
        overlap: xLeftPosition + 8,
      };
    case AppTooltipPosition.Top:
    case AppTooltipPosition.Bottom:
      const xTopBottomPosition = getBoundingClientRect(tooltipTrigger).left;
      return {
        position: xTopBottomPosition - 8,
        overlap: window.innerWidth - (xTopBottomPosition + getBoundingClientRect(tooltipBody).width + 16),
      };
    case AppTooltipPosition.Right:
    default:
      const xRightPosition = (getBoundingClientRect(tooltipTrigger).right + 16);
      return {
        position: xRightPosition,
        overlap: window.innerWidth - (xRightPosition + getBoundingClientRect(tooltipBody).width + 16),
      };
  }
}

function getAdjustedYForPosition(position: AppTooltipPosition, tooltipBody: HTMLDivElement, tooltipTrigger: HTMLDivElement) {
  switch (position) {
    case AppTooltipPosition.Top:
      const yTopPosition = (getBoundingClientRect(tooltipTrigger).top - 16) - getBoundingClientRect(tooltipBody).height;
      return {
        position: yTopPosition,
        overlap: yTopPosition + 8,
      };
    case AppTooltipPosition.Left:
    case AppTooltipPosition.Right:
      const yLeftRightPosition = getBoundingClientRect(tooltipTrigger).top - 8;
      return {
        position: yLeftRightPosition,
        overlap: window.innerHeight - (yLeftRightPosition + getBoundingClientRect(tooltipBody).height + 16),
      };
    case AppTooltipPosition.Bottom:
    default:
      const yBottomPosition = (getBoundingClientRect(tooltipTrigger).bottom + 16);
      return {
        position: yBottomPosition,
        overlap: window.innerHeight - (yBottomPosition + getBoundingClientRect(tooltipBody).height + 16),
      };
  }
}

function getAdjustedTooltipPosition(position: AppTooltipPosition, tooltipBody: HTMLDivElement, tooltipTrigger: HTMLDivElement) {
  const xPositionOverlap = getAdjustedXForPosition(position, tooltipBody, tooltipTrigger); // if overlap negative number, it's offscreen
  const yPositionOverlap = getAdjustedYForPosition(position, tooltipBody, tooltipTrigger);
  switch (position) {
    case AppTooltipPosition.Top:
    case AppTooltipPosition.Bottom:
      const xTBPosition = xPositionOverlap.overlap < 0 ? getBoundingClientRect(tooltipTrigger).left + xPositionOverlap.overlap : xPositionOverlap.position;
      const yTBPosition = yPositionOverlap.position;
      if (yPositionOverlap.overlap < 0) { // flip axis
        const yPositionFlip = position === AppTooltipPosition.Top
          ? getAdjustedYForPosition(AppTooltipPosition.Bottom, tooltipBody, tooltipTrigger)
          : getAdjustedYForPosition(AppTooltipPosition.Top, tooltipBody, tooltipTrigger);

        if (yPositionFlip.overlap < 0) { // if after flip tooltip still offscreen
          return {
            xPosition: getAdjustedXForPosition(AppTooltipPosition.Left, tooltipBody, tooltipTrigger).position,
            yPosition: yPositionFlip.position + (-1 * yPositionFlip.overlap) + 24,
            position: AppTooltipPosition.Left,
          };
        }

        return {
          xPosition: xTBPosition,
          yPosition: yPositionFlip.position,
          position: position === AppTooltipPosition.Top ? AppTooltipPosition.Bottom : AppTooltipPosition.Top,
        };
      }

      return {
        xPosition: xTBPosition,
        yPosition: yTBPosition,
        position,
      };
    case AppTooltipPosition.Left:
    case AppTooltipPosition.Right:
      const yLRPosition = yPositionOverlap.overlap < 0 ? getBoundingClientRect(tooltipTrigger).top + yPositionOverlap.overlap : yPositionOverlap.position; // shift
      const xLRPosition = xPositionOverlap.position;

      if (xPositionOverlap.overlap < 0) { // flip axis
        const xPositionFlip = position === AppTooltipPosition.Left ? getAdjustedXForPosition(AppTooltipPosition.Right, tooltipBody, tooltipTrigger).position
          : getAdjustedXForPosition(AppTooltipPosition.Left, tooltipBody, tooltipTrigger).position;

        return {
          xPosition: xPositionFlip,
          yPosition: yLRPosition,
          position: position === AppTooltipPosition.Left ? AppTooltipPosition.Right : AppTooltipPosition.Left,
        };
      }

      return {
        xPosition: xLRPosition,
        yPosition: yLRPosition,
        position,
      };
    default:
      return {
        xPosition: 0,
        yPosition: 0,
        position,
      };
  }
}

export default function AppTooltip({
  children,
  className,
  disabled,
  position = AppTooltipPosition.Right,
  tooltipTriggerElement,
  hideTooltipIcon,
}: {
  children: ReactNode,
  className?: string,
  disabled?: boolean,
  position?: AppTooltipPosition,
  tooltipTriggerElement?: RefObject<HTMLDivElement> | null,
  hideTooltipIcon?: boolean
}) {
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const tooltipBody = useRef<HTMLDivElement | null>(null);
  const tooltipIcon = useRef<HTMLDivElement | null>(null);

  const [shown, setShown] = useState(false);
  const show = useCallback(() => setShown(true), []);
  const hide = useCallback(() => setShown(false), []);

  const [shouldBeHiddenOnMouseLeave, setShouldBeHiddenOnMouseLeave] = useState(true);
  const tryHideOnMouseLeave = useCallback(() => {
    if (shouldBeHiddenOnMouseLeave) {
      hide();
    }
  }, [shouldBeHiddenOnMouseLeave]);

  const toggleOrPreventHideOnMouseLeave = useCallback(() => {
    if (!shown) {
      show();
      setShouldBeHiddenOnMouseLeave(false);
    } else if (shown && shouldBeHiddenOnMouseLeave) {
      setShouldBeHiddenOnMouseLeave(false);
    } else {
      hide();
      setShouldBeHiddenOnMouseLeave(true);
    }
  }, [shouldBeHiddenOnMouseLeave, show, hide]);

  const hideAndResetShouldBeHiddenOnMouseLeave = useCallback(() => {
    hide();
    setShouldBeHiddenOnMouseLeave(true);
  }, [hide]);

  useEffect(() => {
    if (!tooltipBody.current || !tooltipIcon.current || (hideTooltipIcon && !tooltipTriggerElement)) {
      return;
    }
    const newPosition = getAdjustedTooltipPosition(position, tooltipBody.current, tooltipTriggerElement?.current ?? tooltipIcon.current);
    setXPosition(newPosition.xPosition);
    setYPosition(newPosition.yPosition);
    setAdjustedPosition(newPosition.position);
  }, [tooltipBody, shown, tooltipTriggerElement]);

  useEffect(() => {
    if (tooltipTriggerElement) {
      tooltipTriggerElement.current?.addEventListener('mouseover', show);
      tooltipTriggerElement.current?.addEventListener('mouseleave', tryHideOnMouseLeave);
      return () => {
        tooltipTriggerElement.current?.removeEventListener('mouseover', show);
        tooltipTriggerElement.current?.removeEventListener('mouseleave', tryHideOnMouseLeave);
      };
    }
    return () => { };
  }, [tooltipTriggerElement, show, tryHideOnMouseLeave]);

  return (
    <AppOutsideListener onClick={hideAndResetShouldBeHiddenOnMouseLeave}>
      <div ref={tooltipIcon} className={`app-tooltip ${className || ''} ${shown ? `app-tooltip-shown ${getTooltipPositionClass(adjustedPosition)}` : ''}`}>
        {!hideTooltipIcon && (
          <TooltipIcon
            onClick={disabled ? undefined : toggleOrPreventHideOnMouseLeave}
            onMouseOver={disabled ? undefined : show}
            onMouseLeave={disabled ? undefined : tryHideOnMouseLeave}
          />
        )}
        {shown && createPortal(
          <>
            <div
              ref={tooltipBody}
              className="app-tooltip-body"
              style={{
                left: `${xPosition}px`,
                top: `${yPosition}px`,
              }}
            >
              {children}
            </div>
          </>, document.body,
        )}
      </div>
    </AppOutsideListener>
  );
}
