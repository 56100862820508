import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionMark } from '../../../assets/QuestionMarkUpright.svg';
import { closePopup } from '../popup/AppPopup';
import { AppPopupLayout } from '../popup/AppPopupLayout';

export default function AppUnsavedChangesPopup() {
  const { t } = useTranslation();
  const goBack = () => closePopup(false);
  const leavePage = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="Popup_UnsavedChanged"
      popupBody={(
        <>
          <QuestionMark className="app-w-133 app-h-118" />
          <span className="app-popup-info">{t('IfYouLeaveThisPageChangesWillNotBeSaved')}</span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={leavePage} className="app-secondary-button app-popup-left-button">{t('Leave')}</button>
          <button type="button" onClick={goBack} className="app-primary-button app-popup-right-button">{t('GoBack')}</button>
        </>
      )}
    />
  );
}
