import { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import AccountOnHoldIcon from '../../../../assets/icons/notifications/Account on hold.svg';
import NewInvoiceIcon from '../../../../assets/icons/notifications/New invoice.svg';
import CardExpiredIcon from '../../../../assets/icons/notifications/Card expired.svg';
import NewSiteIcon from '../../../../assets/icons/notifications/New site.svg';
import RemovedSiteIcon from '../../../../assets/icons/notifications/Site removed.svg';
import OverduePaymentIcon from '../../../../assets/icons/notifications/Overdue payment.svg';
import TermsAndConditionsUpdatedIcon from '../../../../assets/icons/notifications/T&C\'s updated.svg';
import ReductionInServiceIcon from '../../../../assets/icons/notifications/Reduction in service.svg';
import PaidInvoiceIcon from '../../../../assets/icons/notifications/Paid invoice.svg';
import InvoiceReminderIcon from '../../../../assets/icons/notifications/Invoice reminder.svg';
import PaymentFailedIcon from '../../../../assets/icons/notifications/payment_failed.png';
import PermissionsUpdatedIcon from '../../../../assets/icons/notifications/Permission updated.svg';
import { ReactComponent as NoNotificationsIcon } from '../../../../assets/No notifications.svg';

import {
  AppNotificationType,
  IAppNotification,
  readAllNotifications,
  readNotification,
  useAppNotifications,
  useAppNotificationsUnreadCount,
} from '../../appBackend/useAppNotifications';
import AppShowLoading from '../AppShowLoading';
import AccountOnHoldContent from './AccountOnHoldContent';
import CardExpiredContent from './CardExpiredContent';
import DueInvoicesContent from './DueInvoicesContent';
import InvoicePaidContent from './InvoicePaidContent';
import NeedUpdateTermsAndConditionsContent from './NeedUpdateTermsAndConditionsContent';
import NewSiteContent from './NewSiteContent';
import OverdueInvoicesContent from './OverdueInvoicesContent';
import PermissionsUpdatedContent from './PermissionsUpdatedContent';
import ReductionInServiceContent from './ReductionInServiceContent';
import SiteRemovedContent from './SiteRemovedContent';
import callIfEnterKeyPressed from '../../../jsUtils/callIfEnterKeyPressed';
import InvoiceBecomeOverdueIn7Days from './InvoiceBecomeOverdueIn7Days';
import PaymentFailedContent from './PaymentFailedContent';
import { IApplicationClaims, useClaims } from '../../../backend/auth/claims';
import { AdminUserRole } from '../../appBackend/useProfileDetails';

enum AppNotificationKind {
  Ok = 'Ok',
  Error = 'Error',
  Warn = 'Warn',
}

type NotificationClickHandlerType = (notification: IAppNotification, params: {
  setLocation: (url: string) => void,
  claims: IApplicationClaims,
}) => void;

const getNotificationStatusRenderDetails = (notification: AppNotificationKind) => {
  const notificationKindRenderDetails: Record<AppNotificationKind, {
    className: string;
  }> = {
    [AppNotificationKind.Ok]: {
      className: 'app-notification-ok',
    },
    [AppNotificationKind.Error]: {
      className: 'app-notification-error',
    },
    [AppNotificationKind.Warn]: {
      className: 'app-notification-warn',
    },
  };

  return notificationKindRenderDetails[notification];
};
type NotificationContentComponentType = (props: { notification: IAppNotification }) => ReactElement;

const NotificationContent: Record<AppNotificationType, {
  kind: AppNotificationKind,
  content: NotificationContentComponentType,
  handleClick: NotificationClickHandlerType,
  icon: FunctionComponent
}> = {
  [AppNotificationType.InvoicesDue]: {
    kind: AppNotificationKind.Ok,
    content: DueInvoicesContent,
    handleClick: (notification, { setLocation }) => {
      const { invoiceNumber } = JSON.parse(notification.jsonPayload);
      setLocation(`/payment/overview/${invoiceNumber}`);
    },
    icon: () => <img alt="icon" src={NewInvoiceIcon} />,
  },
  [AppNotificationType.InvoicesOverdue]: {
    kind: AppNotificationKind.Error,
    content: OverdueInvoicesContent,
    handleClick: (notification, { setLocation }) => {
      const { invoiceNumber } = JSON.parse(notification.jsonPayload);
      setLocation(`/payment/overview/${invoiceNumber}`);
    },
    icon: () => <img alt="icon" src={OverduePaymentIcon} />,
  },
  [AppNotificationType.InvoicesPaid]: {
    kind: AppNotificationKind.Ok,
    content: InvoicePaidContent,
    handleClick: (notification, { setLocation }) => {
      const { invoiceNumber } = JSON.parse(notification.jsonPayload);
      setLocation(`/payment/overview/${invoiceNumber}`);
    },
    icon: () => <img alt="icon" src={PaidInvoiceIcon} />,
  },
  [AppNotificationType.CardExpired]: {
    kind: AppNotificationKind.Warn,
    content: CardExpiredContent,
    handleClick: (notification, { setLocation }) => {
      setLocation('/payment/payments');
    },
    icon: () => <img alt="icon" src={CardExpiredIcon} />,
  },
  [AppNotificationType.SiteAdded]: {
    kind: AppNotificationKind.Ok,
    content: NewSiteContent,
    handleClick: (notification, { setLocation }) => {
      const { siteId } = JSON.parse(notification.jsonPayload);
      setLocation(`/systems/${siteId}/entry-users`);
    },
    icon: () => <img alt="icon" src={NewSiteIcon} />,
  },
  [AppNotificationType.SiteRemoved]: {
    kind: AppNotificationKind.Ok,
    content: SiteRemovedContent,
    handleClick: () => { },
    icon: () => <img alt="icon" src={RemovedSiteIcon} />,
  },
  [AppNotificationType.PermissionsUpdated]: {
    kind: AppNotificationKind.Ok,
    content: PermissionsUpdatedContent,
    handleClick: (notification, { setLocation }) => {
      setLocation('/profile');
    },
    icon: () => <img alt="icon" src={PermissionsUpdatedIcon} />,
  },
  [AppNotificationType.NeedUpdateTermsAndConditions]: {
    kind: AppNotificationKind.Ok,
    content: NeedUpdateTermsAndConditionsContent,
    handleClick: (notifications, { setLocation }) => {
      setLocation('/payment/terms');
    },
    icon: () => <img alt="icon" src={TermsAndConditionsUpdatedIcon} />,
  },
  [AppNotificationType.ReductionInService]: {
    kind: AppNotificationKind.Error,
    content: ReductionInServiceContent,
    handleClick: (notification, { setLocation, claims }) => {
      if (claims.userRole === AdminUserRole.PaymentAdmin) {
        setLocation('/payment/payments');
      }
    },
    icon: () => <img alt="icon" src={ReductionInServiceIcon} />,
  },
  [AppNotificationType.AccountOnHold]: {
    kind: AppNotificationKind.Error,
    content: AccountOnHoldContent,
    handleClick: (notification, { setLocation, claims }) => {
      if (claims.userRole === AdminUserRole.PaymentAdmin) {
        setLocation('/payment/payments');
      }
    },
    icon: () => <img alt="icon" src={AccountOnHoldIcon} />,
  },
  [AppNotificationType.InvoiceBecomeOverdueIn7Days]: {
    kind: AppNotificationKind.Warn,
    content: InvoiceBecomeOverdueIn7Days,
    handleClick: (notification, { setLocation }) => {
      const { invoiceNumber } = JSON.parse(notification.jsonPayload);
      setLocation(`/payment/overview/${invoiceNumber}`);
    },
    icon: () => <img alt="icon" src={InvoiceReminderIcon} />,
  },
  [AppNotificationType.PaymentFailed]: {
    kind: AppNotificationKind.Error,
    content: PaymentFailedContent,
    handleClick: (notification, { setLocation }) => {
      const { invoiceNumber } = JSON.parse(notification.jsonPayload);
      setLocation(`/payment/overview/${invoiceNumber}`);
    },
    icon: () => <img alt="icon" src={PaymentFailedIcon} style={{ width: '40px' }} />,
  },
};

export default function AppNotificationsDropdown() {
  const { notifications, mutate: updateNotifications } = useAppNotifications();
  const { unreadCount, mutate: updateUnreadCount } = useAppNotificationsUnreadCount();

  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const markAllAsRead = async () => {
    if (unreadCount === 0) {
      return;
    }

    await readAllNotifications();
    await updateUnreadCount({ unreadCount: 0 });
    await updateNotifications({ items: (notifications || []).map((p) => ({ ...p, read: true })) });
  };

  const claims = useClaims();
  const handleClickOnNotification = async (notification: IAppNotification, clickHandler: NotificationClickHandlerType) => {
    if (!notification.read) {
      await readNotification(notification.id);
      await Promise.all([
        updateUnreadCount({ unreadCount: unreadCount - 1 }),
        updateNotifications({
          items: (notifications || []).map((p) => (p.id === notification.id ? { ...p, read: true } : p)),
        }),
      ]);
    }

    clickHandler(notification, { setLocation, claims });
  };

  return (
    <div className="app-notifications">
      <div className="app-notifications-header app-d-flex app-justify-content-between">
        <div className="app-notifications-header-title">
          <span className="app-notifications-title">{t('Toolbar_Notifications')}</span>
        </div>
        <div
          onClick={markAllAsRead}
          onKeyDown={callIfEnterKeyPressed(markAllAsRead)}
          tabIndex={0}
          role="button"
        >
          <span
            aria-disabled={unreadCount === 0}
            className="app-notifications-mark-all-read"
          >
            {t('Toolbar_MarkAllAsRead')}
          </span>
        </div>
      </div>
      <div className="app-notifications-body">
        <AppShowLoading inline showLoading={!notifications}>
          {notifications && notifications.map((notification, index) => {
            const {
              content: Content, icon: Icon, kind, handleClick,
            } = NotificationContent[notification.type];
            const { className } = getNotificationStatusRenderDetails(kind);
            return (
              <div
                className={`app-notification ${!notification.read ? 'app-notification-active' : ''}`}
                key={notification.id}
                tabIndex={0}
                onKeyDown={callIfEnterKeyPressed(() => handleClickOnNotification(notification, handleClick))}
                onClick={() => handleClickOnNotification(notification, handleClick)}
                role="button"
              >
                {index > 0 && (
                  <div className="app-notification-hr">
                    <div />
                  </div>
                )}
                <div className="app-d-flex app-align-items-center app-w-100pcnt">
                  <div className={`app-notification-content ${className}`}>
                    <div className="app-notification-icon">
                      <Icon />
                    </div>
                    <div className="app-notification-content-inner">
                      <Content notification={notification} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {notifications && notifications.length === 0 && (
            <div className="app-notifications-empty">
              <NoNotificationsIcon />
              <span>{t('NoNotifications')}</span>
            </div>
          )}
        </AppShowLoading>
      </div>
    </div>
  );
}
