import { ReactComponent as CollapseIcon } from '../../../assets/icons/Button-Control icons/Collaspe icon.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/Button-Control icons/Expand icon.svg';
import AppPopupMenu from '../../../shared/appUIFramework/components/AppPopupMenu';
import { B2CLangCode } from '../../../shared/appUIFramework/constants/B2CLangCode';
import { useLanguagePopupMenu } from '../../../shared/appUIFramework/hooks/useLanguagePopupMenu';
import { useAppLocalisation } from '../../../shared/i18n/config';

export const LanguageSelect = () => {
  const { lang: language, changeLanguage } = useAppLocalisation();

  const updateI18nLang = async (lang: B2CLangCode) => {
    changeLanguage(lang);
  };

  const { languageOptions, getLanguageOptionLabel } = useLanguagePopupMenu();

  return (
    <AppPopupMenu
      options={languageOptions}
      selectedOption={language}
      onOptionSelected={updateI18nLang as (option: string) => void}
      getOptionLabel={getLanguageOptionLabel as (option: string) => string}
      render={(isHidden) => (
        <>
          { getLanguageOptionLabel(language) }
          { isHidden ? <ExpandIcon /> : <CollapseIcon /> }
        </>
      )}
    />
  );
};
