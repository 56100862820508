import ErrorPage from '../shared/appUIFramework/components/ErrorPage';
import { ReactComponent as ErrorRobot404Graphic } from '../assets/errorGraphics/Error_Robot_404.svg';

export default function PageNotFound() {
  return (
    <ErrorPage
      errorCode="Errors_404"
      errorMessage="Errors_404Message"
      errorGraphic={<ErrorRobot404Graphic />}
    />
  );
}
