import { useTranslation } from 'react-i18next';
import { IAccountCharges } from '../appBackend/useAccountCharges';
import styles from '../../../routes/payment/PaymentOverview.module.scss';
import { formatPercent } from '../formatters/formatPercent';
import { ReactComponent as GrowthDecreaseIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as GrowthIncreaseIcon } from '../../../assets/icons/plus.svg';
import { formatMoney } from '../formatters/formatMoney';

export function AccountCharges({
  accountCharges,
  circleTopKey,
  circleBottomKey,
  headerKey,
  footerKey,
  showGrowth = true,
}: {
    accountCharges: IAccountCharges,
    circleTopKey: string,
    circleBottomKey: string,
    headerKey?: string,
    footerKey?: string,
    showGrowth?: boolean,
}) {
  const { t } = useTranslation();
  const showGrowthIncreaseIcon = accountCharges.growth > 0;

  return (
    <div className={`${styles.accountChargesComponent}`}>
      {
        headerKey && (
          <div className="app-paper-title">
            {t(headerKey)}
          </div>
        )
      }
      <div className="app-paper-body">
        <div className={styles.chargesContainer}>
          <span className={styles.chargesText}>{t(circleTopKey)}</span>
          <span className={styles.chargesAmount}>{formatMoney(accountCharges.currencyCode, accountCharges.monthlyCharge)}</span>
          <span className={styles.chargesText}>{t(circleBottomKey)}</span>
        </div>
        {
          showGrowth && (
            <div className={styles.chargesGrowth}>
              {accountCharges.growth !== 0 && (showGrowthIncreaseIcon ? <GrowthIncreaseIcon /> : <GrowthDecreaseIcon />)}
              <span className="app-home-paper-footer-text">
                {formatPercent(Math.abs(accountCharges.growth) * 100)}
                          &nbsp;
              </span>
                {footerKey && (
                <span className="app-home-paper-footer-text">
                  {t(footerKey)}
                </span>
                )}
            </div>
          )
        }
      </div>
    </div>
  );
}
