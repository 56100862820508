import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DisablePaymentIcon } from '../../../assets/icons/Automatic payments disabled.svg';
import { closePopup, showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { useClaims } from '../../../shared/backend/auth/claims';
import DisableAutomaticPaymentErrorPopup from './DisableAutomaticPaymentErrorPopup';
import { disableAutoPayment } from '../../../shared/appUIFramework/appBackend/useAutoPaymentInfo';

export default function DisableAutomaticPaymentsConfirmationPopup() {
  const { t } = useTranslation();
  const { customerReference } = useClaims();
  const [closing, setClosing] = useState(false);

  const closeWithFalse = () => closePopup(false);

  const disableAndCloseWithTrue = async () => {
    if (closing) {
      return;
    }
    setClosing(true);
    try {
      await disableAutoPayment(customerReference);
      closePopup(true);
    } catch {
      closePopup(false);
      await showPopup(<DisableAutomaticPaymentErrorPopup />);
    }
  };

  return (
    <AppPopupLayout
      headerTitle={t('AutomaticPayments')}
      popupBody={(
        <>
          <DisablePaymentIcon className="app-mt-22 app-w-133 app-h-118" />
          <span className="app-popup-info">
            { t('DisablingAutomaticPayments') }
          </span>
        </>
      )}
      buttons={(
        <>
          <button
            type="button"
            onClick={closeWithFalse}
            className="app-secondary-button app-popup-left-button"
          >
            { t('Cancel') }
          </button>
          <button
            type="button"
            onClick={disableAndCloseWithTrue}
            className="app-primary-button app-popup-right-button"
          >
            { t('Continue') }
          </button>
        </>
      )}
    />
  );
}
