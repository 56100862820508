import { UserPermission } from './userPermission';

let userPermissions: UserPermission[] = [];
let userSiteIds: string[] = [];

const permissionsThatRequireSiteIdToBePresent = [
  UserPermission.CanViewSitesForSiteSubset,
  UserPermission.CanEditSiteForSiteSubset,
  UserPermission.CanViewEntryUsersForSiteSubset,
  UserPermission.CanCreateEntryUserForSiteSubset,
  UserPermission.CanEditEntryUserForSiteSubset,
  UserPermission.CanDeleteEntryUserForSiteSubset,
  UserPermission.CanViewHardwareForSiteSubset,
  UserPermission.CanBoundHardwareForSiteSubset,
  UserPermission.CanViewSiteCostsForSiteSubset,
  UserPermission.CanViewSiteDetailsForSiteSubset,
  UserPermission.CanEditSiteDetailsForSiteSubset,
];

function readSiteIdFromPath() {
  const path = window.location.pathname;
  const pathParts = path.split('/');
  const indexOfSystemsPart = pathParts.indexOf('systems');
  return indexOfSystemsPart > -1 && pathParts.length > indexOfSystemsPart + 1
    ? pathParts[indexOfSystemsPart + 1]
    : '';
}

export function hasAnyOfPermissions(...permissions: UserPermission[]): boolean {
  const hasPermission = () => permissions.some((permission) => userPermissions.includes(permission));

  const requiredToCheckSiteId = permissionsThatRequireSiteIdToBePresent.some((permission) => userPermissions.includes(permission));
  const siteIdFromPath = readSiteIdFromPath();

  if (requiredToCheckSiteId && !!siteIdFromPath) {
    return hasPermission() && userSiteIds.includes(siteIdFromPath);
  }

  return hasPermission();
}

export function initPermissions(permissions: UserPermission[], siteIds: string[]) {
  userPermissions = permissions;
  userSiteIds = siteIds;
}

export function useAppPermissions() {
  return {
    hasAnyOfPermissions,
    isPermissionsLoading: false,
  };
}
