import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import EntryMonitor from '../../../assets/Entry Monitor sign in image.png';
import PaxtonLogoSvg from '../../../assets/MyPaxton Logo.svg';
import SectionCompletedIcon from '../../../assets/Section completed icon.svg';
import './SignUpPersonalInfoLayout.scss';

function StepNumber({
  n, isCompleted, isCurrent, title,
}:{n: number, isCurrent: boolean, isCompleted: boolean, title: string}) {
  return (
    <div className={`app-signup-number-container ${isCurrent ? 'app-signup-number-container-current' : ''}`}>
      {isCompleted
        ? <img src={SectionCompletedIcon} alt="check" />
        : (
          <span className={`app-signup-step-number ${isCurrent ? 'app-signup-step-number-current' : ''}`}>
            {n}
          </span>
        )}
      <span className="app-signup-step-title">{title}</span>
    </div>
  );
}

export default function SignUpPersonalInfoLayout({
  children, className, currentStep, hideTitle,
}:{children: ReactNode, className?: string, currentStep: number, hideTitle?: boolean}) {
  const { t } = useTranslation();
  return (
    <div className="app-d-flex app-signup-personal-layout-base app-position-relative">
      <div className={`app-w-50pcnt app-d-flex app-flex-column app-align-items-center app-h-100vh app-position-relative ${className || ''}`}>
        <img
          className="app-w-100pcnt app-h-100vh app-blurred-image app-fit-cover"
          src={EntryMonitor}
          alt="login"
        />
        <div className="app-d-flex app-flex-column app-justify-content-center app-align-items-center app-signup-vat-installer-steps-container">
          <div className="app-signup-vat-installer-steps app-w-100pcnt">
            <StepNumber n={1} isCurrent={currentStep === 1} isCompleted={currentStep > 1} title={t('PersonalInfo')} />
            <StepNumber n={2} isCurrent={currentStep === 2} isCompleted={currentStep > 2} title={t('CompanyInfo')} />
            <StepNumber n={3} isCurrent={currentStep === 3} isCompleted={currentStep > 3} title={t('ContactInfo')} />
          </div>
        </div>
      </div>
      <div className="app-w-50pcnt app-d-flex app-flex-column app-align-items-center app-position-relative app-signup-overflow-side">
        <img
          className="app-signup-personal-info-logo"
          src={PaxtonLogoSvg}
          alt="logo"
        />
        {!hideTitle && (
          <>
            <div className="app-mb-46 app-weight-600 app-font-28 app-signup-personal-layout-start">
              {t('SignUp')}
            </div>
            <div className="app-signup-vat-installer-mobile-steps-container">
              <StepNumber n={1} isCurrent={currentStep === 1} isCompleted={currentStep > 1} title={t('PersonalInfo')} />
              <StepNumber n={2} isCurrent={currentStep === 2} isCompleted={currentStep > 2} title={t('CompanyInfo')} />
              <StepNumber n={3} isCurrent={currentStep === 3} isCompleted={currentStep > 3} title={t('ContactInfo')} />
            </div>
          </>
        )}
        <div className="app-pb-33">
          {children}
        </div>
      </div>
    </div>
  );
}
