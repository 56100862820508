import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import BaseNotificationContent from './BaseNotificationContent';

export default function CardExpiredContent({ notification }: { notification: IAppNotification }) {
  const { t } = useTranslation();
  return (
    <BaseNotificationContent
      title={t('CardExpired')}
      description={t('YourCardDetailsHaveExpired')}
      linkText={t('UpdateDetails')}
      createdAt={notification.createdAt}
    />
  );
}
