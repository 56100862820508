import {
  KeyboardEvent, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';

export default function AppPopupMenu({
  options,
  getOptionLabel,
  getOptionKey,
  onOptionSelected,
  selectedOption,
  render,
  disabled,
  optionsContainerClassName,
  onItemsWidthChange,
  className,
}: {
  options: any[],
  getOptionLabel?: (option: any) => ReactNode,
  getOptionKey?: (option: any) => string,
  onOptionSelected?: (options: any) => unknown,
  selectedOption?: any,
  render: (isHidden: boolean) => ReactNode,
  disabled?: boolean,
  optionsContainerClassName?: string,
  onItemsWidthChange?: (width: number) => void,
  className?: string,
}) {
  const selectedOptionDivRef = useRef<HTMLDivElement>(null);
  const mainContainerDivRef = useRef<HTMLDivElement>(null);
  const itemsContainerDivRef = useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState(true);
  const shouldIgnoreClickOutside = useRef(false);

  // listen to click outside of the dropdown and close options if need
  useEffect(() => {
    const hideOptionsIfClickOutSide = (e: Event) => {
      const clickOnElementThatNotChild = mainContainerDivRef.current
        && !mainContainerDivRef.current.contains(e.target as Node);
      // as we dynamically draw trigger, content can be changed, so
      // e.target can be missing as child of mainContainerDivRef.current and we have to skip that case
      if (!shouldIgnoreClickOutside.current && clickOnElementThatNotChild) {
        setIsHidden(true);
      } else if (shouldIgnoreClickOutside.current) {
        shouldIgnoreClickOutside.current = false;
      }
    };
    window.addEventListener('click', hideOptionsIfClickOutSide);

    return () => window.removeEventListener('click', hideOptionsIfClickOutSide);
  }, [setIsHidden]);

  const resizeObserverRef = useRef(new ResizeObserver((entries) => {
    window.requestAnimationFrame((): void | undefined => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      if (onItemsWidthChange) {
        onItemsWidthChange(itemsContainerDivRef.current?.clientWidth ?? 0);
      }
    });
  }));
  useEffect(() => {
    if (!itemsContainerDivRef?.current || !onItemsWidthChange) {
      return undefined;
    }
    resizeObserverRef.current.observe(itemsContainerDivRef.current);
    return () => resizeObserverRef.current.disconnect();
  }, [itemsContainerDivRef?.current, onItemsWidthChange]);

  const toggleIsHidden = () => {
    if (isHidden) {
      shouldIgnoreClickOutside.current = true;
    }
    setIsHidden(!isHidden);
  };

  const selectOptionAndHideOptions = useCallback((event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>, option: any) => {
    const selectedValue = option;
    if (selectedValue == null) {
      const parentNode = (event.target as HTMLElement).parentNode as EventTarget;
      if (parentNode) {
        selectOptionAndHideOptions({
          ...event,
          target: parentNode,
        }, option);
      }
      return;
    }
    const handled = onOptionSelected && onOptionSelected(selectedValue);
    if (handled !== true) {
      setIsHidden(true);
    }
  }, [onOptionSelected, setIsHidden]);

  return (
    <div
      ref={mainContainerDivRef}
      className={`app-select app-popup-menu ${className} ${isHidden ? 'app-popup-menu-hidden' : 'app-popup-menu-shown'}`}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className="app-select-trigger-container"
        tabIndex={0}
        role="button"
        onClick={disabled ? undefined : toggleIsHidden}
      >
        {render(isHidden)}
      </div>
      <div
        ref={itemsContainerDivRef}
        className={`app-select-items ${optionsContainerClassName || ''}`}
        aria-hidden={isHidden}
      >
        {!isHidden && options.map((option) => (
          <div
            role="option"
            aria-selected={selectedOption === option}
            ref={selectedOption === option
              ? selectedOptionDivRef
              : undefined}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                selectOptionAndHideOptions(event, option);
              }
            }}
            onClick={(event) => selectOptionAndHideOptions(event, option)}
            className="app-select-item"
            key={(getOptionKey && getOptionKey(option)) || option}
          >
            {(getOptionLabel && getOptionLabel(option)) || option}
          </div>
        ))}
      </div>
    </div>
  );
}
