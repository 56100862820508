import { BaseSyntheticEvent } from 'react';
import { UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { KeyedMutator } from 'swr';
import AppRequestFailedPopup from './AppRequestFailedPopup';
import { showPopup } from '../popup/AppPopup';

const resetAndGoBack = (reset: UseFormReset<any>, goBack: () => void) => {
  reset(); // reset form to prevent unsaved changes popup
  setTimeout(goBack, 0); // set timeout so reset above will be applied before goBack
};

export async function handleFormError(error: unknown, { reset, goBack }:{
  reset: UseFormReset<any>;
  goBack: () => void;
  mutate?: KeyedMutator<any>;
}) {
  const backToForm = await showPopup(<AppRequestFailedPopup />);
  if (!backToForm) {
    resetAndGoBack(reset, goBack);
  }
}

export function appSubmitAndHandleErrors<TFormValues>({
  submit, handleSubmit, reset, goBack, mutate,
}:
  {
    submit: (data: TFormValues) => Promise<any>;
    handleSubmit: UseFormHandleSubmit<TFormValues>;
    reset: UseFormReset<TFormValues>;
    goBack: () => void;
    mutate?: KeyedMutator<any>
  }): (e?: BaseSyntheticEvent) => Promise<void> {
  return handleSubmit(async (data) => {
    try {
      const submitAborted = await submit(data);
      if (!submitAborted) {
        resetAndGoBack(reset, goBack);
      }
    } catch (error) {
      // console left here to provide some information about what is going wrong
      // here all errors are catched - js and http, but there is no any output for js
      // so if error happen it hard to de-risk the fix
      // eslint-disable-next-line no-console
      console.error(error);
      await handleFormError(error, { reset, goBack, mutate });
    }
  });
}
