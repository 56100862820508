import { KeyedMutator } from 'swr';
import { useClaims } from '../../backend/auth/claims';
import {
  getUrlWithQueryParams, httpDelete, httpGetJson, httpGetString, httpPostJson, httpPut,
} from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { AdminUserRole, IProfile, ISystemAdminInfo } from './useProfileDetails';

export interface IAdminUserGridItem {
  id: string;
  name: string;
  email: string;
  userRole: AdminUserRole;
  system: string;
  systems?: ISystemAdminInfo[];
  sites?: ISystemAdminInfo[];
}

export interface IAdminTotals {
  totalAdmins: number;
}

export async function createAdmin(admin: IProfile): Promise<void> {
  await httpPostJson(Urls.AdminUsers, admin);
}

export async function updateAdmin(admin: IProfile): Promise<void> {
  await httpPut(Urls.AdminUsers, admin);
}

export async function isAdminEmailUnique(email: string, userIdToExclude?: string) {
  if (!email) {
    return Promise.resolve(true);
  }
  const url = Urls.IsAdminEmailUnique(email, userIdToExclude);
  const resp = await httpGetString(url!);
  return resp.toLowerCase() === 'true';
}

export function getCurrentAdmin(adminId: string) {
  return httpGetJson<IProfile>(Urls.Admin(adminId));
}

export function useAdmin(adminId?: string): { admin?: IProfile, mutate?: KeyedMutator<IProfile> } {
  const url = adminId ? Urls.Admin(adminId) : undefined;

  const { data, mutate } = useSWRAndHandleErrors<IProfile>(
    url,
    httpGetJson,
  );
  return { admin: data, mutate };
}

export function useCurrentAdmin() {
  const claims = useClaims();

  return useAdmin(claims?.userId);
}

export enum PaymentType {
  Automatic,
  Manual
}

export function deleteAdmin(adminId: string): Promise<any> {
  return httpDelete(Urls.Admin(adminId));
}

export function useAdminListTotals(queryParams: { search?: string, userRole?: string }): { adminTotals: IAdminTotals | undefined, mutate: KeyedMutator<IAdminTotals> } {
  const url = getUrlWithQueryParams(Urls.AdminTotals, queryParams);
  const { data, mutate } = useSWRAndHandleErrors<IAdminTotals>(url, httpGetJson);
  return { adminTotals: data, mutate };
}
