import { ReactNode } from 'react';
import AccessForbidden from './AccessForbidden';
import { useAppPermissions } from './useAppPermissions';
import { UserPermission } from './userPermission';

function Forbidden({ variant }: { variant: 'component' | 'page' }) {
  return (
    <>
      {
        variant === 'component' ? <></> : <AccessForbidden />
      }
    </>
  );
}

function AppPermissionGuard({
  permissions,
  children,
  variant,
  customAction,
}: {
  permissions: UserPermission[],
  variant: 'component' | 'page'
  customAction?: () => boolean,
  children: ReactNode
}) {
  const { hasAnyOfPermissions, isPermissionsLoading } = useAppPermissions();

  return isPermissionsLoading ? <></> : (
    <>
      {
        (customAction && customAction()) || hasAnyOfPermissions(...permissions)
          ? children
          : <Forbidden variant={variant} />
      }
    </>
  );
}

export function AppPermissionPageGuard({
  permissions,
  children,
  customAction,
}: {
  permissions: UserPermission[],
  children: ReactNode,
  customAction?: () => boolean,
}) {
  return (
    <AppPermissionGuard
      permissions={permissions}
      variant="page"
      customAction={customAction}
    >
      {children}
    </AppPermissionGuard>
  );
}

export function AppPermissionComponentGuard({
  permissions,
  customAction,
  children,
}: { permissions?: UserPermission[], children: ReactNode, customAction?: () => boolean }) {
  return (
    <AppPermissionGuard
      permissions={permissions || []}
      customAction={customAction}
      variant="component"
    >
      {children}
    </AppPermissionGuard>
  );
}
