import ErrorPage from '../components/ErrorPage';
import { ReactComponent as ErrorRobot403Graphic } from '../../../assets/errorGraphics/Error_Robot_403.svg';

export default function AccessForbidden() {
  return (
    <ErrorPage
      errorCode="Errors_403"
      errorMessage="Errors_403Message"
      errorGraphic={<ErrorRobot403Graphic />}
    />
  );
}
