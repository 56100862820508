import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import BaseNotificationContent from './BaseNotificationContent';

export default function SiteRemovedContent({ notification }:{notification: IAppNotification}) {
  const { t } = useTranslation();
  const { siteNames } = notification.jsonPayload
    ? JSON.parse(notification.jsonPayload) as { siteNames: string }
    : { siteNames: '' };
  return (
    <BaseNotificationContent
      title={t('SiteRemoved')}
      description={t('YouHaveBeenRemovedFromASite', { siteNames })}
      createdAt={notification.createdAt}
    />
  );
}
