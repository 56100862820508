export enum B2CLangCode {
  English = 'en-GB',
  French = 'fr',
  EnglishUS = 'en-US',
  German = 'de',
  Netherlands = 'nl',
  Swedish = 'sv',
  Danish = 'da',
  Norwegian = 'no',
  SpanishLatinAmerica = 'es-419',
}
