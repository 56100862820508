import { useRef } from 'react';
import { debounce } from '../../jsUtils/debounce';

export function useDebounce(action : () => void, debounceTime: number): () => void {
  const ref = useRef(() => {});
  ref.current = action;
  return debounce(() => {
    ref.current();
  }, debounceTime);
}
