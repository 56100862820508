import { Configuration } from '@azure/msal-browser';

const tenantName = process.env.REACT_APP_B2C_TENANT_NAME!;
const clientId = process.env.REACT_APP_B2C_CLIENT_ID!;
const b2cLoginURL = process.env.REACT_APP_B2C_LOGIN_URL!;
const b2cProfile = process.env.REACT_APP_B2C_PROFILE!;
const postLogoutRedirect = process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT!;
const passwordResetFlow = process.env.REACT_APP_B2C_PASSWORD_RESET_FLOW!;

const getMsalAuthority = (flowName: string) => `https://${b2cLoginURL}/${tenantName}.onmicrosoft.com/${flowName}`;

export const PASSWORD_RESET_AUTHORITY = getMsalAuthority(passwordResetFlow);

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: getMsalAuthority(b2cProfile),
    knownAuthorities: [b2cLoginURL],
    postLogoutRedirectUri: postLogoutRedirect,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPii) => {
    //     if (containsPii) {
    //       return;
    //     }
    //     // eslint-disable-next-line no-console
    //     console.log(level, message);
    //   },
    // },
    iframeHashTimeout: 10000,
  },
};
