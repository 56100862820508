import { useTranslation } from 'react-i18next';
import { ReactComponent as QuestionIcon } from '../../../assets/QuestionMarkUpright.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function SignUpUnsavedChangesPopup() {
  const { t } = useTranslation();
  const closeWithTrue = () => closePopup(true);
  const closeWithFalse = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="Popup_UnsavedChanged"
      popupBody={(
        <>
          <QuestionIcon className="app-w-133 app-h-118" />
          <span className="app-popup-info">{t('Popup_ChangesNotSavedSignUp')}</span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={closeWithFalse} className="app-secondary-button app-popup-left-button">{t('Cancel')}</button>
          <button type="button" onClick={closeWithTrue} className="app-primary-button app-popup-right-button">{t('Yes')}</button>
        </>
      )}
    />
  );
}
