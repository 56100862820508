import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';

export function isScrollVisible(el: HTMLElement | null) {
  if (!el) {
    return false;
  }

  // eslint-disable-next-line no-param-reassign
  el.scrollTop += 1;
  if (el.scrollTop) {
    // eslint-disable-next-line no-param-reassign
    el.scrollTop -= 1;
    return true;
  }

  return false;
}

function toggleClass(classShouldBeApplied: boolean, element: HTMLDivElement, className: string) {
  if (classShouldBeApplied) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
}

const appFlexVerticalScrollablePaddingRightClass = 'app-flex-vertical-scrollable-padding-right';
const appHeaderScrollableClass = 'app-table-header-scroll-visible';

export function togglePaymentScrollClass(scrollVisible: boolean, element: HTMLDivElement) {
  toggleClass(scrollVisible, element, appHeaderScrollableClass);
}

function toggleScrollPaddingClass(element: HTMLDivElement) {
  const scrollStylesNeeded = isScrollVisible(element);

  toggleClass(scrollStylesNeeded, element, appFlexVerticalScrollablePaddingRightClass);

  return scrollStylesNeeded;
}

// there is no way to check is scroll visible or not with pure CSS, so we need to add padding manually
export function useAddPaddingLeftWhenScrollIsVisible(ref: MutableRefObject<HTMLDivElement | null>, isDataLoading: boolean) {
  const [scrollStylesApplied, setScrollStylesApplied] = useState(false);
  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const mutationObserver = new MutationObserver(() => {
      if (!ref.current) {
        return;
      }

      setScrollStylesApplied(toggleScrollPaddingClass(ref.current));
      mutationObserver.disconnect();
    });

    mutationObserver.observe(ref.current, {
      childList: true,
      subtree: true,
    });

    setScrollStylesApplied(toggleScrollPaddingClass(ref.current));

    return () => {
      mutationObserver.disconnect();
    };
  }, [ref.current, isDataLoading]);

  return scrollStylesApplied;
}

export function applyAddPaddingLeftWhenScrollIsVisible(isDataLoading: boolean) {
  const ref = useRef<HTMLDivElement>(null);
  useAddPaddingLeftWhenScrollIsVisible(ref, isDataLoading);
  return { ref };
}
