import { TimeoutError } from '../errors/timeoutError';
import { httpGet, httpGetJson, httpPut } from '../http/http';
import { InternalServerError } from '../errors/internalServerError';
import { RESPONSE_CODE_ERROR_LIST, StatusCodesEnum } from '../../appUIFramework/constants/StatusCodesEnum';
import { handleUnknownError } from '../../appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';

interface INegotiate {
  baseUrl: string;
  url: string;
  accessToken: string;
}

export async function wsSubscribe<TMessageModel>(onDataReceived: (data: TMessageModel) => any, negotiateUrl: string, negotiateGroupsUrl?: string): Promise<() => void> {
  const { url } = await httpGetJson<INegotiate>(negotiateUrl);
  const webSocket = new WebSocket(url);

  const notifyAboutMessage = (e: any) => {
    const data = JSON.parse(e.data);
    onDataReceived(data);
  };
  webSocket.addEventListener('message', notifyAboutMessage);

  const notifyAboutError = (e: unknown) => {
    handleUnknownError(e);
  };
  webSocket.addEventListener('error', notifyAboutError);

  const joinUserToGroups = async () => {
    if (negotiateGroupsUrl) {
      try {
        await httpPut(negotiateGroupsUrl, {});
      } catch (e) {
        handleUnknownError(e);
      }
    }
  };
  webSocket.addEventListener('open', joinUserToGroups);

  return () => {
    webSocket.removeEventListener('message', notifyAboutMessage);
    webSocket.removeEventListener('open', joinUserToGroups);
    webSocket.removeEventListener('error', notifyAboutError);
    webSocket.close();
  };
}

export async function wsGet<TResult>(
  triggerForWebSocket: string | (() => Promise<void>),
  getWsUrlOrNegotiateUrl: string | (() => Promise<string>),
  timeoutMs = 10000,
): Promise<TResult> {
  const url = typeof getWsUrlOrNegotiateUrl === 'string'
    ? (await httpGetJson<INegotiate>(getWsUrlOrNegotiateUrl)).url
    : await getWsUrlOrNegotiateUrl();

  return new Promise((resolve, reject) => {
    const webSocket = new WebSocket(url);

    let timeoutId: any = setTimeout(() => {
      reject(new TimeoutError());
    }, timeoutMs);

    const dispose = () => {
      webSocket.close();
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    webSocket.addEventListener('open', async () => {
      try {
        if (typeof triggerForWebSocket === 'function') {
          await triggerForWebSocket();
        } else {
          await httpGet(triggerForWebSocket);
        }
      } catch (error) {
        dispose();
        reject(error);
      }
    });

    webSocket.addEventListener('message',
      (e: any) => {
        dispose();
        const messageData = JSON.parse(e.data);
        if (RESPONSE_CODE_ERROR_LIST.includes(messageData.code)) {
          const error = {
            [StatusCodesEnum.ServerErrorGatewayTimeout]: new TimeoutError(),
            [StatusCodesEnum.ServerErrorInternal]: new InternalServerError(),
          }[messageData.code as number] || Error;

          reject(error);
        } else {
          resolve(messageData);
        }
      });

    webSocket.addEventListener('error', (e) => {
      dispose();
      reject(e);
    });
  });
}
