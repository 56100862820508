import { useTranslation } from 'react-i18next';
import {
  CSSProperties, DragEvent, useRef, useState,
} from 'react';
import styles from './UploadTaxCertificate.module.scss';
import AppShowLoading from '../AppShowLoading';
import { ReactComponent as UploadFileIcon } from '../../../../assets/Upload file icon.svg';

export interface IUploadTaxCertificateProps {
    fileName?: string,
    style?: CSSProperties,
    onFileSelected: (file: File) => void,
    onFileRemoved: () => void,
    showLoading: boolean,
    showIcon: boolean,
}

export default function TaxCertificateDropzone({
  showLoading,
  showIcon,
  fileName,
  onFileSelected,
  onFileRemoved,
  style,
}: IUploadTaxCertificateProps) {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const selectFileIfValid = (file: File) => {
    const supportedExtensions = ['.pdf', '.png', '.jpg', 'jpeg'];
    const maxFileSizeMb = 20;
    const maxFileNameLength = 128;

    const validation = [
      {
        isValid: () => {
          const bytesInMb = 1024 * 1024;
          const mbInFile = file.size / bytesInMb;
          return mbInFile < maxFileSizeMb;
        },
        errorMessage: () => t('FileExceedsSizeLimit', { mbLimit: maxFileSizeMb }),
      },
      {
        isValid: () => supportedExtensions.some((p) => file.name.endsWith(p)),
        errorMessage: () => t('UploadedFileShouldBeImageOrPdf', { extensions: supportedExtensions.join(', ') }),
      },
      {
        isValid: () => file.name.length <= maxFileNameLength,
        errorMessage: () => t('FileNameExceedsLengthLimit', { lengthLimit: maxFileNameLength }),
      },
    ];

    const failedValidation = validation.find((p) => !p.isValid());
    setErrorMessage(failedValidation?.errorMessage() || '');

    if (!failedValidation) {
      onFileSelected(file);
    }
  };

  const browse = () => fileInputRef.current?.click();

  const deleteFile = () => {
    onFileRemoved();
  };

  const dragEnterTarget = useRef<EventTarget | null>(null);

  const setDragOverStyle = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    dragEnterTarget.current = e.target;
  };

  const removeDragOverStyle = (e: DragEvent<HTMLDivElement>, force?: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    if (dragEnterTarget.current === e.target || force) {
      setIsDragging(false);
      dragEnterTarget.current = null;
    }
  };

  const setFileFromDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer) {
      selectFileIfValid(e.dataTransfer.files[0]);
    }
    removeDragOverStyle(e, true);
  };

  return (
    <div
      className={`${styles.filesDropZoneWrapper} ${isDragging ? styles.isDragging : ''}`}
      style={style}
    >
      <AppShowLoading showLoading={showLoading}>
        <div
          className={styles.filesDropZone}
          onDragEnter={setDragOverStyle}
          onDragLeave={removeDragOverStyle}
          onDrop={setFileFromDrop}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {showIcon && <UploadFileIcon />}
          <div className="app-mb-7">
            <span className="app-mp-bold-16 app-uppercase app-color-primary-green">
              {t('DragAndDrop')}
                &nbsp;
            </span>
          </div>
          <div>
            <span className="app-font-16-22 app-color-primary-green">{t('AFileOr')}</span>
            <span>
&nbsp;
              <button
                className="app-primary-button app-primary-button-browse"
                type="button"
                onClick={browse}
              >
                {t('Browse')}
              </button>
            </span>
          </div>
          {errorMessage && (
            <div className="app-text-secondary-red-color app-pt-5">
              {errorMessage}
            </div>
          )}
          <input
            onChange={(e) => {
              if (!e.target.files) {
                return;
              }
              selectFileIfValid(e.target.files[0]);
            }}
            type="file"
            hidden
            ref={fileInputRef}
          />
        </div>
        {
                    fileName && (
                    <div className={styles.uploadedFile}>
                      <span className={styles.uploadedFileName}>{fileName}</span>
                      <button className={styles.deleteButton} type="button" onClick={() => deleteFile()}>x</button>
                    </div>
                    )
                }
        {
                    !fileName && (
                    <div className="app-text-align-right app-pr-12 app-mb-7">
                      <span className="app-color-primary-green app-font-9">{t('MaxFileSize20mb')}</span>
                    </div>
                    )
                }
      </AppShowLoading>
    </div>
  );
}
