import { useEffect, useState } from 'react';
import { FieldValues, useForm, UseFormProps } from 'react-hook-form';

export function useAppForm<TData extends FieldValues = FieldValues>(data?: TData | undefined, props?: UseFormProps<TData>) {
  const [dataLoaded, setDataLoaded] = useState(false);

  const form = useForm<TData>(props);

  useEffect(() => {
    if (!dataLoaded && data) {
      setDataLoaded(true);
      form.reset(data);
    }
  }, [data]);

  useEffect(() => {
    form.trigger();
  }, [form.formState.touchedFields]);

  return { ...form };
}
