import { ISiteAddress } from '../../../routes/systems/models/ISiteInfo';

export function formatSystemAddress(address?: ISiteAddress): string {
  if (!address) {
    return '';
  }

  const {
    address1, address2, city, postCode,
  } = address;
  return [address1 || '', address2 || '', city || '', postCode || '']
    .filter((item) => !!item)
    .join(', ');
}
