import { useTranslation } from 'react-i18next';
import { ReactComponent as AutoPaymentIcon } from '../../../assets/icons/Automatic payments icon.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { useIsAccountBlocked } from '../../../shared/appUIFramework/appBackend/useAccountInfo';

export default function AutomaticPaymentSetupSuccess() {
  const { t } = useTranslation();
  const isBlocked = useIsAccountBlocked();

  return (
    <AppPopupLayout
      extraClass="app-popup-without-title"
      onHeaderClose={closePopup}
      popupBody={(
        <div className="app-d-flex app-flex-column app-align-items-center app-justify-content-center app-signup-success-popup">
          <AutoPaymentIcon />
          <span className="app-font-20 app-weight-600 app-mb-40 app-uppercase">{t('AutomaticPaymentsSetup')}</span>
          {!isBlocked && <span className="app-font-20 app-mb-40">{t('YouCanViewYourInvoicesInPaymentsSectionOfThePortal')}</span>}
          {isBlocked && (
            <>
              <span className="app-font-20">{t('Popup_YourPaymentIsBeingProcessed')}</span>
              <br />
              <span className="app-font-20">{t('Popup_UponCompletionOfPaymentYouWillNeedToContact')}</span>
            </>
          )}
        </div>
      )}
    />
  );
}
