import { useTranslation } from 'react-i18next';
import {
  AccountStatusUIState,
  useIsAccountRestricted,
  useAccountInfo,
} from './shared/appUIFramework/appBackend/useAccountInfo';
import './AppAccountStatusBar.scss';
import { ReactComponent as WarningIcon } from './assets/icons/Warning icon.svg';

export default function AppAccountStatusBar() {
  const { t } = useTranslation();
  const { accountInfo } = useAccountInfo();
  let message: string;

  switch (accountInfo?.accountStatus) {
    case undefined:
    case AccountStatusUIState.Ok:
      message = '';
      break;
    case AccountStatusUIState.Blocked:
      message = t('AccountOnHoldMessage');
      break;
    case AccountStatusUIState.Limited:
      message = t('AccountLimitedMessage');
      break;
    default:
      message = '';
      break;
  }

  const shouldShowBanner = useIsAccountRestricted();
  const additionalClasses = `${shouldShowBanner ? 'shown' : 'hidden'} ${accountInfo.accountStatus === AccountStatusUIState.Blocked ? 'blocked' : ''}`;
  return (
    <div className={`app-account-status-warning app-d-flex app-align-items-center ${additionalClasses}`}>
      <WarningIcon className="app-mr-9" />
      <span>{message}</span>
    </div>
  );
}
