import { useEffect, useState } from 'react';
import { wsSubscribe } from '../../../backend/websockets/websockets';
import { Urls } from '../../../backend/urls';
import {
  AppNotificationType,
  IAppNotification,
  useAppNotifications,
  useAppNotificationsUnreadCount,
} from '../../appBackend/useAppNotifications';
import { useLogout } from '../../hooks/useLogout';

export default function AppNotificationsListener() {
  const { notifications, mutate: updateNotifications } = useAppNotifications();
  const { unreadCount, mutate: updateUnreadCount } = useAppNotificationsUnreadCount();
  const logout = useLogout();

  const [shouldLogoutOnNextAction, setShouldLogoutOnNextAction] = useState(false);
  useEffect(() => {
    if (shouldLogoutOnNextAction) {
      window.addEventListener('click', logout, { once: true });
    }
  }, [shouldLogoutOnNextAction]);

  useEffect(() => {
    let clearWsSubscribe = () => {
    };

    wsSubscribe<IAppNotification>(async (notification) => {
      const permissionsUpdatedNotifications = [AppNotificationType.PermissionsUpdated, AppNotificationType.SiteAdded, AppNotificationType.SiteRemoved];
      if (permissionsUpdatedNotifications.includes(notification.type)) {
        setShouldLogoutOnNextAction(true);
      }
      await updateNotifications({ items: [notification, ...(notifications || [])] });
      await updateUnreadCount({ unreadCount: (unreadCount || 0) + 1 });
    }, Urls.NotificationsNegotiate, Urls.NotificationsNegotiateGroups)
      .then((clearSub) => {
        clearWsSubscribe = clearSub;
      });

    return () => {
      clearWsSubscribe();
    };
  }, []);

  return <></>;
}
