export enum DeviceType {
  Unknown = 'Unknown',
  Mark1Panel = 'Mark1Panel',
  Mark1Monitor = 'Mark1Monitor',
  Mark2Panel = 'Mark2Panel',
  TouchPanel = 'TouchPanel',
  PremiumMonitor = 'PremiumMonitor',
  Mark2Monitor = 'Mark2Monitor',
  AudioOnly = 'AudioOnly',
  Mobile = 'Mobile',
  SIP = 'SIP',
}

export enum DeviceTypeIds {
  Unknown = 0,
  Mark1Panel,
  Mark1Monitor,
  Mark2Panel,
  TouchPanel,
  PremiumMonitor,
  Mark2Monitor,
  AudioOnly,
  Mobile = 1001,
  SIP = 1002
}

export interface IHardwareDevice {
  deviceName: string;
  deviceType: string;
  deviceTypeId: DeviceTypeIds;
  group: string;
  deviceId: string;
  serialNumber: string;
  isOnline: boolean;
}

export interface IHardwareRequest {
  hardwareSummary: IHardwareDevice[];
}
