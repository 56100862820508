import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export enum SortNumericOptions {
    Default = 1,
    Ascending = 2,
    Descending = 3,
}

export default function useSortNumericOptions(): {options: SortNumericOptions[], getOptionLabel: (option: SortNumericOptions) => string} {
  const { t } = useTranslation();
  const numericTranslationsRef = useRef({
    [SortNumericOptions.Ascending]: t('Ascending'),
    [SortNumericOptions.Descending]: t('Descending'),
  });

  const numericTranslations = numericTranslationsRef.current;
  const options = Object.keys(numericTranslations).map((p) => +p) as SortNumericOptions[];
  // @ts-ignore
  const getOptionLabel = (option: SortNumericOptions) => numericTranslations[option];

  return { options, getOptionLabel };
}
