import { useTranslation } from 'react-i18next';
import { ReactComponent as RecordErrorIcon } from '../../../assets/icons/Record error icon.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function ChangePaymentAdminFailurePopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle={t('Popup_UnsavedChanged')}
      extraClass="app-popup-delete-user"
      onHeaderClose={cancel}
      popupBody={(
        <>
          <RecordErrorIcon />
          <span className="app-font-20">
            { t('Popup_MustHaveAnotherPaymentAdmin') }
          </span>
        </>
      )}
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Popup_GoBack')}</button>
          <button type="button" onClick={confirm} className="app-primary-button app-popup-right-button">{t('Popup_Ok')}</button>
        </>
      )}
    />
  );
}
