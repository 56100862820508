import { useEffect } from 'react';
import { useLocation } from 'wouter';
import { ISignUpUserDto, Region } from '../signup.models';

class UseSignupState {
  user: Partial<ISignUpUserDto> = {};
  region: Region | null = null;
}

let signUpState: UseSignupState | null = null;

export function initSignUpState() {
  signUpState = new UseSignupState();
}

export function freeSignUpState() {
  signUpState = null;
}

export function useSignUpState() {
  const [, setLocation] = useLocation();

  useEffect(() => {
    if (!signUpState) {
      setLocation('/signup/region');
    }
  }, []);

  return {
    signUpState: signUpState || { region: undefined },
    getValue: () => signUpState?.user || {},
    setValue: (fieldsToUpdate: Partial<ISignUpUserDto>) => {
      signUpState!.user = { ...signUpState?.user, ...fieldsToUpdate };
    },
    init: () => {
      initSignUpState();
    },
  };
}
