import { useTranslation } from 'react-i18next';
import AdminShell from './components/AdminShell';
import AppSearch from '../../shared/appUIFramework/components/AppSearch';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { ReactComponent as DownloadIcon } from '../../assets/icons/Download icon.svg';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import ReplaceTaxCertificatePopup from './components/ReplaceTaxCertificatePopup';
import { Urls } from '../../shared/backend/urls';
import { httpGet } from '../../shared/backend/http/http';
import { useFormatDate } from '../../shared/appUIFramework/formatters/formatDate';

export default function AdminDocuments() {
  const { t } = useTranslation();
  const company = useCompany();
  const downloadCertificate = async () => {
    if (!company) {
      return;
    }

    try {
      const file = await httpGet(Urls.CompaniesTaxCertificate);
      const blob = await file.blob();
      const objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = company.taxCertificateFileName;
      a.click();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const formatDate = useFormatDate();

  return (
    <AdminShell activeTab="documents">
      <div className="app-content app-pt-20 app-pb-33 app-px-30 admin-people">
        <div
          className="app-systems-table-header app-mb-46 app-d-flex app-align-items-center app-justify-content-between admin-people-header"
        >
          <AppSearch
            onSearch={() => {
            }}
            placeholder={t('SearchDocuments')}
          />
          <button
            type="button"
            onClick={() => showPopup(<ReplaceTaxCertificatePopup />)}
            className="app-primary-button"
          >
            {t('ReplaceDocument')}
          </button>
        </div>
        <div className="app-table">
          <div className="app-table-header-row app-table-3-cols">
            <div>{t('Doc')}</div>
            <div>{t('DateUploaded')}</div>
            <div />
          </div>
          <div className="app-flex-vertical-scrollable">
            <AppShowLoading showLoading={!company}>
              <div className="app-table-content-row app-table-3-cols">
                <div>{t('TaxCertificate')}</div>
                <div className="admin-people-email-col app-ellipsis">{company && formatDate(company.taxCertificateUploadedAt)}</div>
                <DownloadIcon onClick={downloadCertificate} />
              </div>
            </AppShowLoading>
          </div>
        </div>
      </div>
    </AdminShell>
  );
}
