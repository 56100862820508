import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as EmailIcon } from '../../assets/icons/Text field icons/Email  icon.svg';
import { ReactComponent as NameIcon } from '../../assets/icons/Text field icons/Name Icon V2.svg';
import { useUnsavedPopup } from '../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import AppSelect, { IAppSelectOption } from '../../shared/appUIFramework/components/AppSelect';
import SignUpPersonalInfoLayout from './components/SignUpPersonalInfoLayout';
import { useCancelVatInstallerSignUp } from './hooks/useCancelVatInstallerSignUp';
import { useSignUpState } from './hooks/useSignupState';
import './SignVatInstallerForm.scss';
import { B2CLangCode } from '../../shared/appUIFramework/constants/B2CLangCode';
import { useAppLocalisation } from '../../shared/i18n/config';

const titleOptions: Record<string, IAppSelectOption> = {
  Mr: {
    value: 'Mr',
    label: 'Title_Mr',
  },
  Mrs: {
    value: 'Mrs',
    label: 'Title_Mrs',
  },
  Miss: {
    value: 'Miss',
    label: 'Title_Miss',
  },
  Ms: {
    value: 'Ms',
    label: 'Title_Ms',
  },
  Mx: {
    value: 'Mx',
    label: 'Title_Mx',
  },
};

const titleOptionsExclude: Record<string, B2CLangCode[]> = {
  Mr: [],
  Mrs: [],
  Miss: [B2CLangCode.Netherlands],
  Ms: [],
  Mx: [],
};

export default function SignUpVatInstallerPersonalInfo() {
  const { t } = useTranslation();
  const { lang } = useAppLocalisation();
  const { getValue, setValue } = useSignUpState();
  const signUpUser = getValue();
  const {
    register, handleSubmit, control, getValues, formState: {
      errors, isDirty,
    },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Title: signUpUser.Title,
      FirstName: signUpUser.FirstName,
      LastName: signUpUser.LastName,
      Position: signUpUser.Position,
    },
  });
  const [, setLocation] = useLocation();
  const saveFormAndNavigate = (path: string) => {
    setValue(getValues());
    setLocation(path);
  };
  const { cancelVatInstallerSignUp } = useCancelVatInstallerSignUp();
  const saveFormAndGoToCompanyInfo = () => saveFormAndNavigate('/signup/vat-installer/company-info');
  const goSignUpVatInstallerEmail = () => saveFormAndNavigate('/signup/vat-installer/email');

  useUnsavedPopup('SignUpInstallerPersonalInfo', isDirty);

  return (
    <SignUpPersonalInfoLayout currentStep={1}>
      <form onSubmit={handleSubmit(saveFormAndGoToCompanyInfo)} className="app-d-flex app-flex-column app-gap-23">
        <div className="app-signup-input-container">
          <EmailIcon className="app-signup-input-prefix" />
          <input
            disabled
            readOnly
            value={signUpUser.Email}
            type="text"
            className="app-form-input app-signup-input"
          />
        </div>
        <div>
          <div className="app-signup-input-container">
            <Controller
              control={control}
              name="Title"
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <AppSelect
                  className="app-signup-form-select"
                  inputClassName="app-signup-input"
                  incomingValue={signUpUser.Title}
                  readOnly
                  placeholder={t('SelectTitle')}
                  data-invalid={errors.Title ? 'true' : 'false'}
                  options={
                    Object.keys(titleOptions)
                      .filter((option) => !titleOptionsExclude[option].includes(lang as B2CLangCode))
                      .map((option) => ({ value: titleOptions[option].value, label: t(titleOptions[option].label) }))
                  }
                  onOptionSelected={(title) => {
                    onChange(title.value);
                  }}
                />
              )}
            />
            <NameIcon className="app-signup-input-prefix" />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors?.Title?.type === 'required' && (
            <div className="app-signup-input-error">
              {t('Form_FieldRequired')}
            </div>
          )}
        </div>
        <div>
          <div className="app-signup-input-container">
            <NameIcon className="app-signup-input-prefix" />
            <input
              {...register('FirstName', { required: true })}
              data-invalid={errors.FirstName ? 'true' : 'false'}
              placeholder={t('FirstName')}
              type="text"
              className="app-form-input app-signup-input"
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors?.FirstName?.type === 'required' && (
            <div className="app-signup-input-error">
              {t('Form_FieldRequired')}
            </div>
          )}
        </div>
        <div>
          <div className="app-signup-input-container">
            <NameIcon className="app-signup-input-prefix" />
            <input
              {...register('LastName', { required: true })}
              data-invalid={errors.LastName ? 'true' : 'false'}
              placeholder={t('LastName')}
              type="text"
              className="app-form-input app-signup-input"
            />
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {errors?.FirstName?.type === 'required' && (
            <div className="app-signup-input-error">
              {t('Form_FieldRequired')}
            </div>
          )}
        </div>
        <div className="app-d-flex app-gap-23">
          <button className="app-secondary-button app-signup-button-paddings" type="button" onClick={goSignUpVatInstallerEmail}>
            {t('Back')}
          </button>
          <button className="app-primary-button app-signup-button-paddings" type="submit">
            {t('Next')}
          </button>
        </div>
      </form>
      <div className="app-d-flex app-w-100pcnt app-justify-content-center app-mt-22">
        <button className="app-link-primary-button" type="button" onClick={cancelVatInstallerSignUp}>
          {t('Cancel')}
        </button>
      </div>
    </SignUpPersonalInfoLayout>
  );
}
