import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import BaseNotificationContent from './BaseNotificationContent';

export default function InvoicePaidContent({ notification }:{notification: IAppNotification}) {
  const { t } = useTranslation();
  const { invoiceNumber } = JSON.parse(notification.jsonPayload) as { invoiceNumber: string };
  return (
    <BaseNotificationContent
      title={t('InvoicePaid')}
      description={t('YourInvoiceHasBeenPaid')}
      linkPrefix={t('NoDotInvoice', { invoiceNumber })}
      linkText={t('ViewInvoice')}
      createdAt={notification.createdAt}
    />
  );
}
