import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { useEntryUsersTotals } from '../../../shared/appUIFramework/appBackend/useEntryUsers';

export default function DeleteSystemConfirmationPopup({
  system,
  siteId,
}: { system: string, siteId: string }) {
  const { t } = useTranslation();
  const { usersTotals } = useEntryUsersTotals(siteId);
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);
  const canBeDeleted = usersTotals && usersTotals.totalActiveUsers === 0;
  const info = canBeDeleted ? 'DeleteSystem_AreYouSure' : 'Popup_YouMustFirstDeactivateAllUsersOnThisSystems';

  return (
    <AppPopupLayout
      headerTitle="DeleteSystem_DeleteASystem"
      extraClass="app-popup-delete-system"
      popupBody={(
        <>
          <div className="app-delete-system-popup-title app-pt-30">
            { t(info) }
          </div>
          <form onSubmit={confirm} className="app-popup-form">
            <div className="app-popup-form-controls">
              <div className="app-popup-form-control">
                <div className="app-popup-form-control-label">
                  <span>{ t('SiteAdd_SiteName') }</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    value={system}
                    readOnly
                    type="text"
                    className="app-popup-form-input"
                    placeholder={t('Table_SerialNumber')}
                  />
                </div>
              </div>
              <div className="app-popup-form-control">
                <div className="app-popup-form-control-label">
                  <span>{ t('DeleteSystem_TotalActiveUsers') }</span>
                </div>
                <div className="app-popup-form-control-input">
                  <input
                    value={usersTotals ? usersTotals.totalActiveUsers : ''}
                    readOnly
                    type="text"
                    className="app-popup-form-input"
                  />
                </div>
              </div>
            </div>
            <div className="app-popup-buttons">
              <button
                type="button"
                onClick={cancel}
                className="app-secondary-button app-popup-left-button"
              >
                { t('Form_Cancel') }
              </button>
              { canBeDeleted && (
              <button
                type="submit"
                className="app-warn-button app-popup-right-button"
              >
                { t('Table_Delete') }
              </button>
              ) }
            </div>
          </form>
        </>
    )}
    />
  );
}
