import { useTranslation } from 'react-i18next';
import { ReactComponent as ConnectSuccessIcon } from '../../../assets/icons/Modal icons/System connected icon.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function SystemAddedPopup() {
  const { t } = useTranslation();
  const closeConnectHardwareLater = () => closePopup(false);
  const closeConnectHardware = () => closePopup(true);

  return (
    <AppPopupLayout
      headerTitle="SiteAdd_SiteAdded"
      extraClass="app-popup-system-added"
      popupBody={(
        <>
          <ConnectSuccessIcon />
          <span className="app-font-20 app-uppercase app-weight-600">
            { t('SiteAdd_SiteAdded') }
            !
          </span>
          <span className="app-font-20">{ t('Popup_DoYouWishToConnectHardware') }</span>
        </>
    )}
      buttons={(
        <>
          <button type="button" onClick={closeConnectHardwareLater} className="app-secondary-button app-popup-left-button">{t('Popup_NoDoItLater')}</button>
          <button type="button" onClick={closeConnectHardware} className="app-primary-button app-popup-right-button">{t('Popup_Yes')}</button>
        </>
      )}
    />
  );
}
