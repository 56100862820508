import { useTranslation } from 'react-i18next';
import { ReactComponent as PlainIcon } from '../../../assets/EmailSent.svg';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import './SignUpVatInstallerSuccessPopup.scss';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';

export default function EmailNotWhitelistedPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      extraClass="app-popup-without-title"
      onHeaderClose={closePopup}
      popupBody={(
        <>
          <PlainIcon />
          <span className="app-text-align-center app-font-20 app-mt-36">{t('SorryCurrentlyOnlyPreConfiguredSetOfEmailsAllowedToSignUp')}</span>
        </>
      )}
    />
  );
}
