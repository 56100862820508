import { useTranslation } from 'react-i18next';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function ConnectDeviceProcessingPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      headerTitle="SiteAdd_ConnectToSite"
      extraClass="app-popup-connecting"
      popupBody={(
        <>
          <span className="app-mt-22" />
          <AppShowLoading showLoading inline />
          <span className="app-font-20 app-uppercase app-weight-600 app-text-align-center">
            {t('Popup_SystemConnecting')}
            <br />
            {t('ThisCanTake60Seconds')}
          </span>
        </>
      )}
    />
  );
}
