import { useTranslation } from 'react-i18next';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';

export default function NewMobileUserMonitorAlreadyAssignedConfirmationPopup({ id }:{id: string}) {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);
  const confirm = () => closePopup(true);
  return (
    <AppPopupLayout
      extraClass="app-popup-connect"
      buttons={(
        <>
          <button type="button" onClick={cancel} className="app-secondary-button app-popup-left-button">{t('Form_Cancel')}</button>
          <button type="button" onClick={confirm} className="app-primary-button app-popup-right-button">{t('Form_Save')}</button>
        </>
      )}
      headerTitle={t('Popup_MonitorId')}
      popupBody={(
        <>
          <div className="app-py-42">
            {id && (
              <div className="app-id-text">
                {id}
              </div>
            )}
          </div>
          <span className="app-font-20 app-text-align-center ">
            {`${t('AddUser_MonitorIdAlreadyAssigned')}`}
          </span>
        </>
            )}
    />
  );
}
