import {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../shared/jsUtils/debounce';
import { clamp } from '../../../shared/jsUtils/clamp';
import { roundToDecimalPlaces } from '../../../shared/jsUtils/roundToDecimals';

class AccountActivityBarChart {
  public readonly originalDesignWidth = 1542;
  public readonly originalDesignBarWidth = 60;
  public readonly originalDesignSpaceBetweenBars = 55;
  public readonly originalDesignBarsOffset = 42;
  public readonly zoom = this.svgWidth / this.originalDesignWidth;
  public readonly yAxisTitleX = clamp(29 * this.zoom, { min: 14 });
  public readonly yAxisTitleY = this.svgHeight / 2;
  public readonly yAxisSpaceBetweenTickAndLine = 9 * this.zoom;
  public readonly yAxisTickX = clamp(80 * this.zoom, { min: 36 });
  public readonly xAxisSpaceToBottom = clamp(66 * this.zoom, { min: 40 });
  public readonly xAxisSpaceToLeft = clamp(87 * this.zoom, { min: 42 });
  public readonly xAxisY = this.svgHeight - this.xAxisSpaceToBottom;
  public readonly xAxisSpaceToRight = clamp(47 * this.zoom, { min: 2 });
  public readonly xAxisX2 = this.svgWidth - (this.xAxisSpaceToRight);
  public readonly xAxisWidth = this.xAxisX2 - this.xAxisSpaceToLeft;
  public readonly xAxisSpaceToLabel = 11;
  public readonly xAxisLabelHeight = 10;
  public readonly xAxisTitleSpaceToBottom = 14 * this.zoom;
  public readonly xAxisTitleY = this.svgHeight - this.xAxisTitleSpaceToBottom;
  public readonly barWidth = this.originalDesignBarWidth * this.zoom;
  public readonly barsOffset = this.originalDesignBarsOffset * this.zoom;
  public readonly spaceBetweenBars = (this.xAxisWidth - this.barsOffset) / 12 - this.barWidth;
  public readonly yTicks: number[];
  public readonly xTicks: string[];
  // noinspection MagicNumberJS
  public readonly yAxisSpaceToTop = this.svgHeight > 83 * 3 ? 83 : 20;

  constructor(
    public readonly svgWidth: number,
    public readonly svgHeight: number,
    public readonly accountActivityCounts: number[],
  ) {
    const maxYValue = Math.max(...this.accountActivityCounts);
    // noinspection MagicNumberJS
    this.yTicks = [ // show 4 ticks, multiply by 1.3 to make sure the last tick is not cut off
      0,
      roundToDecimalPlaces((maxYValue * 1.3 * 0.3), 0),
      roundToDecimalPlaces((maxYValue * 1.3 * 0.6), 0),
      roundToDecimalPlaces((maxYValue * 1.3 * 0.9), 0),
    ];
    this.xTicks = [
      'MonthShort_Jan',
      'MonthShort_Feb',
      'MonthShort_Mar',
      'MonthShort_Apr',
      'MonthShort_May',
      'MonthShort_Jun',
      'MonthShort_Jul',
      'MonthShort_Aug',
      'MonthShort_Sep',
      'MonthShort_Oct',
      'MonthShort_Nov',
      'MonthShort_Dec',
    ];
  }

  anyYTicks(): boolean {
    return this.yTicks.some((p) => !!p);
  }

  xScale(x: number): number {
    const minValue = this.barsOffset + this.xAxisSpaceToLeft;

    return minValue + (x * (this.spaceBetweenBars + this.barWidth));
  }

  yScale(y: number): number {
    const minValue = this.yAxisSpaceToTop;
    const maxValue = this.svgHeight - this.xAxisSpaceToBottom;
    const res = maxValue - ((y * (maxValue - minValue)) / this.yTicks[this.yTicks.length - 1]);

    return !res || Number.isNaN(res) ? maxValue : res;
  }
}

export enum BarColor {
  Blue = '#2E6D96',
  Orange = '#DE6634',
}

export default function AccountActivityBarchart({ data, title, barColor }: { data: number[], title: string, barColor: BarColor }) {
  const { t } = useTranslation();
  const svgRef = useRef<SVGSVGElement>(null);
  const [accountActivityBarChart, setAccountActivityBarChart] = useState<AccountActivityBarChart | null>(null);
  useEffect(() => {
    const drawChart = () => {
      const { width, height } = svgRef.current!.getBoundingClientRect();
      setAccountActivityBarChart(new AccountActivityBarChart(width, height, data));
    };
    const debouncedHandler = debounce(drawChart);
    drawChart();
    window.addEventListener('resize', debouncedHandler);
    return () => window.removeEventListener('resize', debouncedHandler);
  }, [data]);

  return (
    <svg
      className="app-activity-bar-chart"
      ref={svgRef}
    >
      {accountActivityBarChart != null && (
        <>
          <g
            x={accountActivityBarChart.yAxisTitleX}
            y={accountActivityBarChart.yAxisTitleY}
            style={{
              transform: `translate(${accountActivityBarChart.yAxisTitleX}px, ${accountActivityBarChart.yAxisTitleY}px)`,
            }}
          >
            <text
              style={{
                transform: 'rotate(-90deg)',
                font: 'normal normal normal 14px/19px myriad-pro',
                textAnchor: 'start',
                color: '#3c3c3b',
                textTransform: 'uppercase',
              }}
            >
              {title}
            </text>
          </g>
          <line
            x1={accountActivityBarChart.xAxisSpaceToLeft}
            y1={accountActivityBarChart.xAxisY}
            x2={accountActivityBarChart.xAxisX2}
            y2={accountActivityBarChart.xAxisY}
            stroke="#d8d8d8"
            strokeWidth={2}
          />
          {accountActivityBarChart.anyYTicks() && accountActivityBarChart.yTicks.map((p) => (
            <Fragment key={Math.random()}>
              <text
                x={accountActivityBarChart.yAxisTickX}
                y={accountActivityBarChart.yScale(p) + 3}
                textAnchor="end"
                style={{
                  font: 'normal normal normal 10px/14px myriad-pro',
                  color: '#3c3c3b',
                }}
              >
                {p}
              </text>
              {p > 0 && (
              <line
                x1={accountActivityBarChart.xAxisSpaceToLeft}
                y1={accountActivityBarChart.yScale(p)}
                x2={accountActivityBarChart.xAxisX2}
                y2={accountActivityBarChart.yScale(p)}
                stroke="#cccccc"
                strokeWidth={1}
                strokeDasharray="15,15"
              />
              )}
            </Fragment>
          ))}
          {
            accountActivityBarChart.xTicks.map((p, i) => (
              <text
                key={p}
                style={{
                  transformOrigin: 'top-left',
                  font: 'normal normal normal 10px/14px myriad-pro',
                  color: '#3c3c3b',
                }}
                x={accountActivityBarChart.xScale(i) + accountActivityBarChart?.barWidth / 2}
                y={accountActivityBarChart.xAxisY
                  + accountActivityBarChart.xAxisSpaceToLabel
                  + accountActivityBarChart.xAxisLabelHeight}
                textAnchor="middle"
                dominantBaseline="center"
              >
                {accountActivityBarChart.svgWidth < 250 ? t(p)[0] : t(p)}
              </text>
            ))
          }
          {
            accountActivityBarChart.accountActivityCounts.map((p, i) => (
              <rect
                key={Math.random()}
                x={accountActivityBarChart.xScale(i)}
                y={accountActivityBarChart.yScale(p)}
                width={accountActivityBarChart.barWidth}
                height={accountActivityBarChart.yScale(0) - accountActivityBarChart.yScale(p)}
                fill={barColor}
                strokeWidth={1}
              />
            ))
          }
          <text
            x={accountActivityBarChart.xAxisSpaceToLeft + accountActivityBarChart.xAxisWidth / 2}
            y={accountActivityBarChart.xAxisTitleY}
            textAnchor="middle"
            style={{
              font: 'normal normal normal 14px/19px myriad-pro',
              textTransform: 'uppercase',
              color: '#3c3c3b',
            }}
          >
            {t('Home_AccountActivity_Months')}
          </text>
        </>
      )}
    </svg>
  );
}
