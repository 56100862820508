import { useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as MobileIcon } from '../../assets/icons/Text field icons/Mobile icon.svg';
import { ReactComponent as TelephoneIcon } from '../../assets/icons/Text field icons/Telephone icon.svg';
import AppSelect from '../../shared/appUIFramework/components/AppSelect';
import { PHONE_MAX_LENGTH, PHONE_MIN_LENGTH, PHONE_PATTERN } from '../../shared/appUIFramework/constants/constants';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import SignUpPersonalInfoLayout from './components/SignUpPersonalInfoLayout';
import SignUpVatInstallerSuccessPopup from './components/SignUpVatInstallerSuccessPopup';
import { useCancelVatInstallerSignUp } from './hooks/useCancelVatInstallerSignUp';
import { freeSignUpState, useSignUpState } from './hooks/useSignupState';
import { signup } from './signup.api';
import { ISignUpUserDto } from './signup.models';
import './SignVatInstallerForm.scss';
import './SignUpVatInstallerContactInfo.scss';
import {
  getCountryCodeForMobileCodeInForm,
  getMobileCodeByCountryCode, listOfMobileCodesWithCountryCodeForDropdown,
  mobileCodes,
  useDefaultCountryCode,
} from '../../shared/appUIFramework/constants/Countries';

export default function SignUpVatInstallerContactInfo() {
  const { t } = useTranslation();
  const { getValue, setValue: setValueForSignUpState } = useSignUpState();
  const signUpUser = getValue();
  const defaultCountryCode = useDefaultCountryCode(signUpUser.CountryCode);
  const mobileIconRef = useRef<HTMLElement | null>(null);
  const defaultDiallingCode = getMobileCodeByCountryCode(defaultCountryCode) || mobileCodes[0];
  const {
    register, handleSubmit, control, watch, getValues, setValue: setValueInForm, formState: {
      errors,
      isSubmitting,
    },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      TelephoneNumber: signUpUser?.TelephoneNumber,
      Mobile: signUpUser?.Mobile,
      MobileDiallingCode: defaultDiallingCode,
      MobileDiallingCodeCountryCode: defaultCountryCode || getCountryCodeForMobileCodeInForm(defaultDiallingCode, mobileCodes, 0),
      Position: '',
    },
  });
  const [, setLocation] = useLocation();
  const { cancelVatInstallerSignUp } = useCancelVatInstallerSignUp();
  const signUp = async () => {
    const values = getValues();
    const mobileFormattedForInternalApps = (values.MobileDiallingCode + values.Mobile).substring(1);
    setValueForSignUpState({
      TelephoneNumber: values.TelephoneNumber,
      Mobile: mobileFormattedForInternalApps.substring(1),
      Position: values.Position,
    });
    try {
      await signup({
        ...signUpUser,
        TelephoneNumber: values.TelephoneNumber || '',
        Mobile: mobileFormattedForInternalApps,
        TypeOfCompany: 21, // that is magic number should be passed for request to IA from Entry Portal
      } as ISignUpUserDto);
      freeSignUpState();
      const pointToUsePopup = 919;
      const shouldUsePopupOnSuccess = document.documentElement.clientWidth < pointToUsePopup;
      if (shouldUsePopupOnSuccess) {
        await showPopup(<SignUpVatInstallerSuccessPopup />);
      } else {
        setLocation('/signup/vat-installer/success');
      }
    } catch {
      setLocation('/signup/vat-installer/error');
    }
  };
  const saveFormAndNavigate = (path: string) => {
    setValueForSignUpState(getValues());
    setLocation(path);
  };

  const goToCompanyInfo = () => saveFormAndNavigate('/signup/vat-installer/company-info');
  const diallingCodeCountryCode = watch('MobileDiallingCodeCountryCode');

  return (
    <SignUpPersonalInfoLayout currentStep={3}>
      <form
        onSubmit={handleSubmit(signUp)}
        className="app-d-flex app-flex-column app-gap-23"
      >
        <div>
          <div className="app-signup-input-container">
            <TelephoneIcon className="app-signup-input-prefix" />
            <input
              {...register('TelephoneNumber',
                { pattern: PHONE_PATTERN, minLength: PHONE_MIN_LENGTH, maxLength: PHONE_MAX_LENGTH })}
              data-invalid={errors.TelephoneNumber ? 'true' : 'false'}
              type="text"
              className="app-form-input app-signup-input"
              placeholder={t('Telephone')}
            />
          </div>
          {errors.TelephoneNumber && (
          <span className="app-signup-input-error">
            { errors.TelephoneNumber.type === 'pattern' && t('Form_PhoneLengthInvalid') }
            { errors.TelephoneNumber.type !== 'pattern' && t('Form_PhoneNumberInvalid') }
          </span>
          )}
        </div>
        <div>
          <div className="app-signup-input-container">
            <Controller
              control={control}
              name="MobileDiallingCode"
              rules={{
                validate: (mobileCode) => {
                  const countryCode = getCountryCodeForMobileCodeInForm(mobileCode, mobileCodes, 0);
                  return !!countryCode;
                },
              }}
              render={({ field: { onChange, value } }) => (
                <AppSelect
                  className="app-mr-9 app-signup-select-dialling-code app-signup-form-select"
                  inputClassName="app-signup-input"
                  incomingValue={value}
                  disableSearch
                  externalTriggerRef={mobileIconRef}
                  isOptionSelected={(countryCode) => !!diallingCodeCountryCode && countryCode.value === diallingCodeCountryCode}
                  onOptionSelected={(countryCode) => {
                    onChange(countryCode.label);
                    setValueInForm('MobileDiallingCodeCountryCode', countryCode.value);
                  }}
                  getOptionPrefix={(countryCode) => {
                    const country = countryCode || getValues('MobileDiallingCodeCountryCode');
                    return (
                      <>
                        { !!country && (
                        <>
                          <ReactCountryFlag
                            id="app-country-flag-icon"
                            countryCode={country}
                            svg
                          />
                        </>
                        ) }
                      </>
                    );
                  }}
                  options={listOfMobileCodesWithCountryCodeForDropdown()}
                />
              )}
            />
            <MobileIcon ref={mobileIconRef as any} className="app-signup-input-prefix" />
            <div
              className="app-form-control-input-wrapper"
              aria-invalid={!!errors.Mobile}
            >
              <input
                {...register(
                  'Mobile',
                  {
                    required: true,
                    pattern: PHONE_PATTERN,
                    minLength: PHONE_MIN_LENGTH,
                    maxLength: PHONE_MAX_LENGTH,
                  },
                )}
                placeholder={t('Mobile')}
                data-invalid={!!errors.Mobile}
                type="text"
                className="app-signup-input app-signup-input-mobile"
              />
            </div>
            <span className="app-signup-input-required">&nbsp;*</span>
          </div>
          {(errors.Mobile || errors.MobileDiallingCode) && (
            <span className="app-signup-input-error app-pl-50pcnt">
              { errors.Mobile?.type === 'pattern' && t('Form_PhoneLengthInvalid') }
              { (errors.Mobile?.type === 'minLength' || errors.Mobile?.type === 'maxLength')
                && t('Form_PhoneNumberInvalid') }
              { errors.Mobile?.type === 'required' && t('Form_FieldRequired') }
              { errors.MobileDiallingCode && Object.keys(errors.Mobile || {}).length === 0
                 && t('PleaseSelectAMobileCode') }
            </span>
          )}
        </div>
        <div className="app-d-flex app-sign-up-vat-installer-contact-info-buttons app-gap-23">
          <button
            className="app-secondary-button app-signup-button-paddings"
            type="button"
            onClick={goToCompanyInfo}
          >
            { t('Back') }
          </button>
          <button
            disabled={isSubmitting}
            className="app-primary-button app-signup-button-paddings"
            type="submit"
          >
            { t('Finish') }
          </button>
        </div>
      </form>
      <div className="app-d-flex app-w-100pcnt app-justify-content-center app-mt-22">
        <button
          className="app-link-primary-button"
          type="button"
          onClick={cancelVatInstallerSignUp}
        >
          { t('Cancel') }
        </button>
      </div>
    </SignUpPersonalInfoLayout>
  );
}
