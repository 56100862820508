import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'wouter';
import AppContentToolbar from '../../../shared/appUIFramework/components/AppContentToolbar';
import { hasAnyOfPermissions } from '../../../shared/appUIFramework/appPermisions/useAppPermissions';
import {
  isTaxMethodUsedInCompany,
  useInternalAppsCompanyDetails,
} from '../../../shared/appUIFramework/appBackend/useKeyContact';
import { CanViewAdminDocuments } from '../../../shared/appUIFramework/appPermisions/permissionsGroups';
import { VatEvidenceType } from '../../../shared/appUIFramework/constants/Countries';

export default function AdminShell({ activeTab, children }:{activeTab: 'company-details' | 'people' | 'documents', children: ReactNode}) {
  const { t } = useTranslation();
  const { data: internalAppsCompanyDetails } = useInternalAppsCompanyDetails();

  const tabs = [
    { title: t('People_Title'), key: 'people', to: '/admin/people' },
    { title: t('CompanyDetails'), key: 'company-details', to: '/admin/company-details' },
  ];

  if (internalAppsCompanyDetails && isTaxMethodUsedInCompany(internalAppsCompanyDetails, VatEvidenceType.TaxCertificate)
      && hasAnyOfPermissions(...CanViewAdminDocuments)) {
    tabs.push({ title: t('Documents'), key: 'documents', to: '/admin/documents' });
  }

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb">
          { t('Admin_Title') }
        </span>
      </AppContentToolbar>
      <div className="app-tabs">
        { tabs.map(({ title, key, to }) => (
          <Link
            className={`app-tab ${key === activeTab ? 'app-tab-active' : ''}`}
            key={key}
            to={to}
          >
            { title }
          </Link>
        ))}
      </div>
      { children }
    </>
  );
}
