import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import {
  ReactComponent as BackwardBreadCrumpIcon,
} from '../../assets/icons/Button-Control icons/Breaccrumb back button.svg';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AdminForm from './components/AdminForm';

export default function AdminPeopleCreate() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();

  const goToPeople = () => {
    setLocation('/admin/people');
  };
  return (
    <>
      <AppContentToolbar>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <span className="app-toolbar-breadcrumb app-hover-pointer" onClick={goToPeople}>{ t('Navigation_People') }</span>
        <span className="app-toolbar-breadcrumb-separator app-rotate-svg-180"><BackwardBreadCrumpIcon /></span>
        <span className="app-toolbar-breadcrumb">{ t('AddANewPerson') }</span>
      </AppContentToolbar>
      <AdminForm disabled={false} adminId={undefined} />
    </>
  );
}
