import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AppPopupLayout } from '../popup/AppPopupLayout';
import LoadingBubbles from '../../../assets/loading-bubbles.svg';
import { closePopup, showPopup } from '../popup/AppPopup';
import { createAdmin, isAdminEmailUnique, useCurrentAdmin } from '../appBackend/useAdminsInfinite';
import { EMAIL_PATTERN, PAXTON_EMAIL_DOMAINS } from '../constants/constants';
import { useAppForm } from '../appBackend/useAppForm';
import NewUserEmailConfirmationPopup from '../appForm/NewUserEmailConfirmationPopup';

export default function AppInviteSupportPopup() {
  const { t } = useTranslation();
  const { admin: currentAdmin } = useCurrentAdmin();

  const {
    handleSubmit,
    formState: {
      isSubmitting,
    },
  } = useAppForm<{email: string}>(undefined, {
    mode: 'onChange',
  });

  // have a bug with validation in react-hook-form, so using this workaround
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);
  const isPaxtonEmail = !!PAXTON_EMAIL_DOMAINS.find((p) => email?.endsWith(p));
  const isEmailProvided = !!email;
  const isEmailPatternValid = EMAIL_PATTERN.test(email);
  const [isEmailUnique, setIsEmailUnique] = useState(true);
  const [validatingUniqueness, setValidatingUniqueness] = useState(false);
  const validateEmail = async (value: string) => {
    try {
      setIsEmailUnique(true);
      setValidatingUniqueness(false);
      const isUnique = await isAdminEmailUnique(value);
      setIsEmailUnique(isUnique);
      setValidatingUniqueness(true);
    } finally {
      setValidatingUniqueness(false);
    }
  };

  const isEmailValid = isEmailProvided && isEmailPatternValid && isPaxtonEmail && isEmailUnique;
  const isValid = isEmailValid && !validatingUniqueness;

  const inviteSupport = async () => {
    if (!currentAdmin) {
      throw new Error('currentAdmin is not defined');
    }

    await createAdmin({
      email,
      companyId: currentAdmin.companyId,
      userRole: currentAdmin.userRole,
      branchOfficeId: currentAdmin.branchOfficeId,
      systems: currentAdmin.systems,
      internalAppsCustomerReference: currentAdmin.internalAppsCustomerReference,
      locale: currentAdmin.locale,
      firstName: currentAdmin.firstName,
      lastName: currentAdmin.lastName,
      impersonationTriggeredById: currentAdmin.id,
    });

    closePopup();
    await showPopup(<NewUserEmailConfirmationPopup email={email} confirmationText={t('InviteConfirmation')} />);
  };

  const errorMessage = [
    !isEmailProvided && t('Form_FieldRequired'),
    !isEmailPatternValid && t('SiteAdd_EmailInvalid'),
    !isPaxtonEmail && t('PleaseEnterPaxtonEmail'),
    !isEmailUnique && t('EmailAlreadyExists'),
  ].find((p) => typeof p === 'string');

  return (
    <>
      <AppPopupLayout
        headerTitle="InviteSupport"
        extraClass="app-popup-connect"
        popupBody={(
          <>
            {isSubmitting && (
            <div className="app-connect-device-popup-loading-wrapper">
              <img
                className="app-show-loading-image"
                src={LoadingBubbles}
                alt="Loading..."
              />
              <span className="app-font-20 app-uppercase app-weight-600">
                {t('Inviting')}
              </span>
            </div>
            )}
            {/* strange fix to update error message in the template, remove anything from that block and error message will not be updated as expected */}
            <div style={{ display: 'none' }}>
              {email}
              <br />
              {errorMessage}
              <br />
              {isEmailPatternValid.toString()}
              <br />
              {EMAIL_PATTERN.test(email).toString()}
              <br />
            </div>
            <div className={`app-popup-info app-pt-30 ${isSubmitting ? 'app-invisible' : ''}`}>
              {t('InviteSupportDescription')}
            </div>
            <form onSubmit={handleSubmit(inviteSupport)} className="app-popup-form">
              <div className={`app-popup-form-controls  ${isSubmitting ? 'app-invisible' : ''}`}>
                <div className="app-popup-form-control" aria-invalid={!isValid && touched}>
                  {' '}
                  {/* set up the dev tool */}
                  <div className="app-popup-form-control-label">
                    <span>{t('Email')}</span>
                    <span className="app-text-secondary-red-color">&nbsp;*</span>
                  </div>
                  <div className="app-popup-form-control-input">
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(e.target.value);
                      }}
                      onBlur={() => setTouched(true)}
                      type="text"
                      className="app-popup-form-input"
                    />
                  </div>
                  {!isEmailValid && touched && (
                  <div className="app-popup-form-error app-popup-form-error-with-prefix">
                    {errorMessage}
                  </div>
                  )}
                </div>
              </div>
              <div className="app-popup-buttons">
                <button
                  type="button"
                  onClick={closePopup}
                  className="app-secondary-button app-popup-left-button"
                >
                  {t('Form_Cancel')}
                </button>
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className={`app-primary-button app-popup-right-button ${isSubmitting ? 'app-invisible' : ''}`}
                >
                  {t('Invite')}
                </button>
              </div>
            </form>
          </>
      )}
      />
    </>
  );
}
