import { Control, Controller } from 'react-hook-form';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import AppTooltip, { AppTooltipPosition } from '../../../shared/appUIFramework/components/AppTooltip';
import styles from '../../payment/PaymentPayments.module.scss';
import { formatRegistrationStatus } from '../../../shared/appUIFramework/formatters/formatRegistrationStatus';
import { RegistrationStatus } from '../../../shared/appUIFramework/constants/RegistrationStatus';
import { IUserData } from '../models/IUserData';
import { ReactComponent as LicenseIcon } from '../../../assets/icons/Text field icons/EntryUser_Icon_License.svg';
import { ReactComponent as UserStatusIcon } from '../../../assets/icons/Text field icons/User status icon.svg';
import { UserLicenseTooltipBody, UserStatusTooltipBody } from './EntryUserFieldTooltipComponents';

export default function EntryUserFormRegistrationStatus({
  isNewEntryUser,
  registrationStatus,
  isActive,
  control,
  reinstate,
  resendConfirmationLink,
  disabled,
}:{
  isNewEntryUser: boolean,
  registrationStatus: RegistrationStatus,
  isActive: boolean,
  control: Control<IUserData>,
  reinstate: () => Promise<void>,
  resendConfirmationLink: () => Promise<void>,
  disabled: boolean,
}) {
  const { t } = useTranslation();
  const userHasBeenUnregistered = registrationStatus === RegistrationStatus.Unregistered && !isActive;
  const shouldShowSendConfirmationLink = !isNewEntryUser && registrationStatus === RegistrationStatus.Pending;

  const isActiveText = (() => {
    switch (isActive) {
      case true:
        return t('FieldValue_Active');
      case false:
        return t('FieldValue_Inactive');
      default:
        return '-';
    }
  })();
  return (
    <div>
      <div className="app-form-control">
        <div className="app-form-control-label app-form-control-label-with-prefix">
          {t('License')}
        </div>
        <div className="app-form-control-input">
          <div className="app-form-control-prefix">
            <LicenseIcon />
          </div>
          <input
            type="text"
            value={isActiveText}
            readOnly
            disabled={disabled}
            className="app-form-input"
          />
          <div className="app-post-input-content">
            <AppTooltip
              className={`app-ml-28 ${styles.tooltip}`}
              position={AppTooltipPosition.Bottom}
            >
              <UserLicenseTooltipBody />
            </AppTooltip>
          </div>
          <div className="app-form-input-postfix-switch">
            <Controller
              control={control}
              name="isActive"
              render={({ field: { onChange, value, onBlur } }) => (
                <Switch
                  checked={!!value}
                  disabled={userHasBeenUnregistered || disabled}
                  onChange={onChange}
                  onBlurCapture={onBlur}
                  onColor="#56aa1c"
                  offColor="#ce171d"
                  onHandleColor="#ffffff"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="lkk-switch"
                  id="material-switch"
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="app-form-control">
        <div className="app-form-control-label app-form-control-label-with-prefix">
          {t('Status')}
        </div>
        <div className="app-form-control-input">
          <div className="app-form-control-prefix">
            <UserStatusIcon />
          </div>
          <input
            value={t(formatRegistrationStatus(registrationStatus)) as string || ''}
            readOnly
            disabled={disabled}
            type="text"
            className="app-form-input"
          />
          <div className="app-post-input-content">
            <AppTooltip
              className={`app-ml-28 ${styles.tooltip}`}
              position={AppTooltipPosition.Bottom}
            >
              <UserStatusTooltipBody />
            </AppTooltip>
          </div>
          {userHasBeenUnregistered
         && (
           <button
             className="app-primary-button app-form-input-postfix-button app-reinstate-button"
             type="button"
             disabled={disabled}
             onClick={reinstate}
           >
             {t('Form_Reinstate')}
           </button>
         )}
        </div>
        {shouldShowSendConfirmationLink && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={resendConfirmationLink}
          className="app-form-control-input-link app-form-control-input-link-with-prefix"
        >
          {t('User_ResendConfirmation')}
        </div>
        )}
      </div>
    </div>
  );
}
