import { KeyboardEvent } from 'react';

export default function callIfEnterKeyPressed(callback: (e: KeyboardEvent<any>) => void, updateEvent?: (e: KeyboardEvent<any>) => void) {
  return (e: KeyboardEvent<any>) => {
    if (updateEvent) {
      updateEvent(e);
    }
    if (e.key === 'Enter') callback(e);
  };
}
