import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export enum SortStringOptions {
    Default = 1,
    Ascending = 2,
    Descending = 3,
}

export default function useSortStringOptions(): {options: SortStringOptions[], getOptionLabel: (option: SortStringOptions) => string} {
  const { t } = useTranslation();
  const sortSiteNameOptionsRef = useRef({
    [SortStringOptions.Ascending]: t('TableSort_ATOZ'),
    [SortStringOptions.Descending]: t('TableSort_ZTOA'),
  });

  const sortSiteNameOptions = sortSiteNameOptionsRef.current;
  const options = Object.keys(sortSiteNameOptions).map((p) => +p) as SortStringOptions[];
  // @ts-ignore
  const getOptionLabel = (option: SortStringOptions) => sortSiteNameOptions[option];

  return { options, getOptionLabel };
}
