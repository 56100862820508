import { AccountStatus, useClaims } from '../../backend/auth/claims';

export enum AccountStatusUIState {
  Ok = 'Active',
  Limited = 'Overdue',
  Blocked = 'Blocked',
}

export interface IAccountInfo {
  accountStatus: AccountStatusUIState;
}

function getAccountStatusUIState(accountStatusApi: AccountStatus) {
  const map: Record<AccountStatus, AccountStatusUIState> = {
    [AccountStatus.Ok]: AccountStatusUIState.Ok,
    [AccountStatus.Limited]: AccountStatusUIState.Limited,
    [AccountStatus.Blocked]: AccountStatusUIState.Blocked,
    [AccountStatus.Unknown]: AccountStatusUIState.Ok,
  };

  return map[accountStatusApi];
}

export function useAccountInfo() {
  const claims = useClaims();

  const { data }: { data: IAccountInfo } = {
    data: {
      accountStatus: getAccountStatusUIState(claims.accountStatus),
    },
  };
  return {
    accountInfo: data,
  };
}

export function applyFunctionalityLimitedStyles(isFunctionalityLimited: boolean) {
  return {
    'data-functionality-limited': isFunctionalityLimited,
  };
}

export function applyAccountStatusStyles(accountInfo: IAccountInfo) {
  return {
    ...applyFunctionalityLimitedStyles(accountInfo.accountStatus !== AccountStatusUIState.Ok),
    'data-on-hold': accountInfo.accountStatus === AccountStatusUIState.Blocked,
  };
}

export function useIsAccountRestricted() {
  const { accountInfo } = useAccountInfo();
  return accountInfo?.accountStatus === AccountStatusUIState.Limited || accountInfo?.accountStatus === AccountStatusUIState.Blocked;
}

export function useIsAccountBlocked() {
  const { accountInfo } = useAccountInfo();
  return !accountInfo || accountInfo?.accountStatus === AccountStatusUIState.Blocked;
}
