import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import PaxtonLogo from '../../assets/paxton-logo.svg';
import LogoutIcon from '../../assets/icons/Lefthand navigation icons/Logout icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/Text field icons/Close icon.svg';
import { useLogout } from '../appUIFramework/hooks/useLogout';
import { AppNavigationMenuLink } from './AppNavigationMenuLink';
import { AccountStatusUIState, useAccountInfo } from '../appUIFramework/appBackend/useAccountInfo';
import { useAppPermissions } from '../appUIFramework/appPermisions/useAppPermissions';
import {
  CanNavigateToAdminPermissions,
  CanNavigateToHomePermissions,
  CanNavigateToPaymentPermissions,
  CanNavigateToSitesPermissions,
} from '../appUIFramework/appPermisions/permissionsGroups';
import HomeIcon from '../../assets/icons/Lefthand navigation icons/Home Icon.svg';
import SystemsIcon from '../../assets/icons/Lefthand navigation icons/Systems icon.svg';
import PaymentIcon from '../../assets/icons/Lefthand navigation icons/Payment icon.svg';
import AdminIcon from '../../assets/icons/Lefthand navigation icons/Profile icon.svg';
import { UserPermission } from '../appUIFramework/appPermisions/userPermission';
import callIfEnterKeyPressed from '../jsUtils/callIfEnterKeyPressed';

type NavigationLink = {
  to: string,
  icon: any,
  text: string,
  disabled: boolean,
  childRoutes?: string[]
}

export function getAppNavigationLinks(accountInfo: any, t: (s: string) => string, hasAnyOfPermissions: (...permissions: UserPermission[]) => boolean) {
  const isAccountOnHold = accountInfo.accountStatus === AccountStatusUIState.Blocked;

  const appNavigationLinks = [] as NavigationLink[];

  if (hasAnyOfPermissions(...CanNavigateToHomePermissions)) {
    appNavigationLinks.push({
      to: '/home',
      icon: HomeIcon,
      text: t('Navigation_Home'),
      disabled: isAccountOnHold,
      childRoutes: ['/profile'],
    });
  }
  if (hasAnyOfPermissions(...CanNavigateToSitesPermissions)) {
    appNavigationLinks.push({
      to: '/systems',
      icon: SystemsIcon,
      text: t('Navigation_Sites'),
      disabled: isAccountOnHold,
      childRoutes: [],
    });
  }
  if (hasAnyOfPermissions(...CanNavigateToPaymentPermissions)) {
    appNavigationLinks.push({
      to: '/payment',
      icon: PaymentIcon,
      text: t('Navigation_Payment'),
      disabled: false,
      childRoutes: ['overview'],
    });
  }
  if (hasAnyOfPermissions(...CanNavigateToAdminPermissions)) {
    appNavigationLinks.push({
      to: '/admin',
      icon: AdminIcon,
      text: t('Navigation_Admin'),
      disabled: isAccountOnHold,
      childRoutes: ['people'],
    });
  }
  return appNavigationLinks;
}

export function useAppNavigationLinks() {
  const { hasAnyOfPermissions } = useAppPermissions();
  const { t, i18n } = useTranslation();
  const { accountInfo } = useAccountInfo();

  const initialAppNavigationLinks = useRef(null as unknown as NavigationLink[]);
  if (!initialAppNavigationLinks.current) {
    initialAppNavigationLinks.current = getAppNavigationLinks(accountInfo, t, hasAnyOfPermissions);
  }

  const [appNavigationLinks, setAppNavigationLinks] = useState(initialAppNavigationLinks.current);

  useEffect(() => {
    setAppNavigationLinks(getAppNavigationLinks(accountInfo, t, hasAnyOfPermissions));
  }, [i18n.language]);

  return appNavigationLinks;
}

export default function AppNavigationMenu() {
  const { t } = useTranslation();

  const appNavigationLinks = useAppNavigationLinks();
  const handleLogout = useLogout();

  return (
    <div className="app-navigation-menu-container">
      <div className="app-navigation-close-wrapper">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor="menu-visibility-handler-checkbox"
          className="app-navigation-close"
        >
          <CloseIcon />
        </label>
      </div>
      <div className="app-navigation-logo">
        <img
          src={PaxtonLogo}
          alt="Paxton Logo"
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div className="app-navigation-links">
        {appNavigationLinks.map((link) => (
          <AppNavigationMenuLink
            key={link.to}
            to={link.to}
            childRoutes={link.childRoutes || ['/profile']}
            disabled={link.disabled}
          >
            <img
              src={link.icon}
              alt={link.text}
            />
            <span>{link.text}</span>
          </AppNavigationMenuLink>
        ))}
      </div>
      <div className="app-navigation-logout">
        <div
          role="button"
          tabIndex={0}
          className="app-mp-bold-16 app-px-30 app-py-12 app-navigation-link app-navigation-logout"
          onClick={handleLogout}
          onKeyDown={callIfEnterKeyPressed(handleLogout)}
        >
          <img
            src={LogoutIcon}
            alt="Logout"
          />
          <span>{t('Sites_Logout')}</span>
        </div>
      </div>
    </div>
  );
}
