import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonDeletedIcon } from '../../assets/icons/Person Deleted.svg';
import { closePopup } from '../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../shared/appUIFramework/popup/AppPopupLayout';

export default function ProfileDeleteSuccessPopup(
  {
    userName,
  }: {
        userName: string
    },
) {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="DeleteUser_DeleteAUser"
      onHeaderClose={cancel}
      popupBody={(
        <>
          <PersonDeletedIcon />
          <span className="app-font-20 app-uppercase app-weight-600">
            { t('Popup_UserDeleted') }
          </span>
          <span className="app-font-20">
            { t('Popup_UserHasBeenDeleted', { userName })}
          </span>
        </>
      )}
    />
  );
}
