import { KeyedMutator } from 'swr';
import { ISiteSummary } from '../../../routes/systems/models/ISiteSummary';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { httpGetJson } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useCompany } from './useCompany';
import { IGridQueryParams } from '../../../routes/systems/models/IGridQueryParams';

export function useSitesSummary(queryParams: IGridQueryParams): { sitesSummary: ISiteSummary | undefined, mutate: KeyedMutator<ISiteSummary> } {
  const company = useCompany();
  const url = Urls.SitesSummary(company?.id, queryParams.search);
  const { data, mutate } = useSWRAndHandleErrors<ISiteSummary>(url, httpGetJson);
  return { sitesSummary: data, mutate };
}
