import { useTranslation } from 'react-i18next';
import { ReactComponent as KeyIcon } from '../../../assets/key.svg';
import { closePopup } from '../popup/AppPopup';
import { AppPopupLayout } from '../popup/AppPopupLayout';

export default function AppDoYouWantToChangePasswordPopup() {
  const { t } = useTranslation();
  const closeWithTrue = () => closePopup(true);
  const closeWithFalse = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="ChangePassword"
      popupBody={(
        <>
          <KeyIcon className="app-w-133 app-h-118" />
          <span className="app-popup-info">
            <span>{t('YouWillBeLoggedOut')}</span>
            <br />
            <span>{t('AreYouSureToProceed')}</span>
          </span>
        </>
            )}
      buttons={(
        <>
          <button type="button" onClick={closeWithFalse} className="app-secondary-button app-popup-left-button">{t('Cancel')}</button>
          <button type="button" onClick={closeWithTrue} className="app-primary-button app-popup-right-button">{t('Yes')}</button>
        </>
            )}
    />
  );
}
