import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import BaseNotificationContent from './BaseNotificationContent';

export default function NeedUpdateTermsAndConditionsContent({ notification }: {notification: IAppNotification}) {
  const { t } = useTranslation();
  return (
    <BaseNotificationContent
      title={t('UpdatedTermsAndConditions')}
      description={t('WeHaveUpdateOurTermsAndConditions')}
      linkText={t('ViewTermsAndConditions')}
      createdAt={notification.createdAt}
    />
  );
}
