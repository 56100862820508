import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteUsersIcon } from '../../../assets/Person Deleted.svg';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';

export default function BulkDeleteEntryUsersSuccessPopup() {
  const { t } = useTranslation();
  const cancel = () => closePopup(false);

  return (
    <AppPopupLayout
      headerTitle="Popup_DeleteUsers"
      extraClass="app-popup-delete-user"
      onHeaderClose={cancel}
      popupBody={(
        <>
          <DeleteUsersIcon />
          <span className="app-font-20 app-uppercase app-weight-600">
            { t('Popup_UsersDeleted') }
          </span>
          <span className="app-font-20 app-pb-33">
            { t('Popup_SelectedUsersHaveBeenDeleted') }
          </span>
        </>
      )}
    />
  );
}
