import { useLocation } from 'wouter';
import { logout } from '../../backend/auth/auth';
import { StorageKey } from '../constants/StorageKeys';
import { deleteAdmin, useCurrentAdmin } from '../appBackend/useAdminsInfinite';

export function useLogout(): () => Promise<void> {
  const [, setLocation] = useLocation();
  const { admin } = useCurrentAdmin();
  return async () => {
    if (admin?.impersonationTriggeredById) {
      await deleteAdmin(admin.id!);
    }
    localStorage.setItem(StorageKey.Logout, 'true'); // when page will be refreshed, if logout is true will navigate
    setLocation('/'); // redirect to home page, because other pages are not valid as redirect uri for b2c
    setTimeout(logout, 0); // setTimeout, to logout after url updated to valid redirect uri for the b2c
  };
}
