import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeFlag } from '../../../assets/DE-Flag.svg';
import { ReactComponent as FrFlag } from '../../../assets/F-Flag.svg';
import { ReactComponent as NlFlag } from '../../../assets/NL-Flag.svg';
import { ReactComponent as UkFlag } from '../../../assets/UK-Flag.svg';
import { ReactComponent as UsFlag } from '../../../assets/US-FLag.svg';
import { ReactComponent as SvFlag } from '../../../assets/SE-Flag.svg';
import { ReactComponent as NoFlag } from '../../../assets/No Flag.svg';
import { ReactComponent as DaFlag } from '../../../assets/DA- Flag.svg';
import Es419Flag from '../../../assets/ES-419.svg';
import { B2CLangCode } from '../constants/B2CLangCode';

export function useLanguagePopupMenu() {
  const { t } = useTranslation();

  const languageLabels: Record<B2CLangCode, ReactNode> = {
    [B2CLangCode.English]:
  <div className="app-language-label">
    <UkFlag />
    <span>{ t('Language_English') }</span>
  </div>,
    [B2CLangCode.French]:
  <div className="app-language-label">
    <FrFlag />
    <span>{ t('Language_French') }</span>
  </div>,
    [B2CLangCode.German]:
  <div className="app-language-label">
    <DeFlag />
    <span>{ t('Language_German') }</span>
  </div>,
    [B2CLangCode.Netherlands]:
  <div className="app-language-label">
    <NlFlag />
    <span>{ t('Language_Netherlands') }</span>
  </div>,
    [B2CLangCode.EnglishUS]:
  <div className="app-language-label">
    <UsFlag />
    <span>{ t('Language_EnglishUS') }</span>
  </div>,
    [B2CLangCode.Swedish]:
  <div className="app-language-label">
    <SvFlag />
    <span>{ t('Language_Swedish') }</span>
  </div>,
    [B2CLangCode.Danish]:
  <div className="app-language-label">
    <DaFlag />
    <span>{ t('Language_Danish') }</span>
  </div>,
    [B2CLangCode.Norwegian]:
  <div className="app-language-label">
    <NoFlag />
    <span>{ t('Language_Norwegian') }</span>
  </div>,
    [B2CLangCode.SpanishLatinAmerica]:
  <div className="app-language-label">
    <img src={Es419Flag} alt="icon" />
    <span>{ t('Language_Spanish') }</span>
  </div>,
  };

  const order = [
    B2CLangCode.English, // English
    B2CLangCode.Danish, // Danish
    B2CLangCode.German, // Deutsch
    B2CLangCode.French, // French
    B2CLangCode.Netherlands, // Netherlands
    B2CLangCode.Norwegian, // Norsk
    B2CLangCode.SpanishLatinAmerica, // Spanish
    B2CLangCode.Swedish, // Swedish
    B2CLangCode.EnglishUS, // English US
  ];

  const languageOptions = Object
    .keys(languageLabels)
    .sort((a, b) => order.indexOf(a as B2CLangCode) - order.indexOf(b as B2CLangCode)) as B2CLangCode[];

  const getLanguageOptionLabel = (option: B2CLangCode) => languageLabels[option];

  return { languageOptions, getLanguageOptionLabel };
}
