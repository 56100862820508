import { useTranslation } from 'react-i18next';
import { useClaims } from '../../../backend/auth/claims';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import { AdminUserRole } from '../../appBackend/useProfileDetails';
import BaseNotificationContent from './BaseNotificationContent';

export default function AccountOnHoldContent({ notification }: { notification: IAppNotification }) {
  const { t } = useTranslation();
  const claims = useClaims();
  const isPaymentAdmin = claims.userRole === AdminUserRole.PaymentAdmin;
  const description = isPaymentAdmin
    ? t('YourAccountHasBeenSuspended')
    : `${t('YourAccountHasBeenSuspended')}. ${t('ContactPaymentAdmin')}.`;

  return (
    <BaseNotificationContent
      title={t('AccountOnHold')}
      description={description}
      linkText={isPaymentAdmin ? t('PayOverdueInvoices') : undefined}
      createdAt={notification.createdAt}
    />
  );
}
