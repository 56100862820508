import { useTranslation } from 'react-i18next';
import { ReactComponent as SystemSaveError } from '../../../assets/icons/System Save Error.svg';
import { closePopup } from '../popup/AppPopup';
import { AppPopupLayout } from '../popup/AppPopupLayout';

export default function AppSystemErrorPopup() {
  const { t } = useTranslation();

  return (
    <AppPopupLayout
      headerTitle="Popup_GenericErrorHeader"
      extraClass="app-popup-connect"
      onHeaderClose={closePopup}
      popupBody={(
        <>
          <SystemSaveError />
          <span className="app-font-20 app-uppercase app-weight-600">
            {t('Popup_GenericErrorSystemError')}
          </span>
          <span className="app-font-20 app-mb-26 app-mw-500 app-text-align-center">
            {t('Popup_GenericErrorBody')}
          </span>
        </>
      )}
    />
  );
}
