import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { useState } from 'react';
import AccessIcon from '../../../assets/icons/Access level.svg';
import { ReactComponent as EmailIcon } from '../../../assets/icons/Text field icons/Email  icon.svg';
import { ReactComponent as NameIcon } from '../../../assets/icons/Text field icons/Name Icon V2.svg';
import { ReactComponent as CrossIcon } from '../../../assets/White cross.svg';
import {
  isAdminEmailUnique,
  useAdmin,
} from '../../../shared/appUIFramework/appBackend/useAdminsInfinite';
import { useCompany } from '../../../shared/appUIFramework/appBackend/useCompany';
import {
  AdminUserRole,
  getUserRoleTranslationKey,
  IProfile,
  ISystemAdminInfo,
} from '../../../shared/appUIFramework/appBackend/useProfileDetails';
import {
  appSubmitAndHandleErrors,
} from '../../../shared/appUIFramework/appForm/appSubmitAndHandleErrors';
import { useUnsavedPopup } from '../../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import { hasAnyOfPermissions } from '../../../shared/appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../../shared/appUIFramework/appPermisions/userPermission';
import AppSelect from '../../../shared/appUIFramework/components/AppSelect';
import AppShowLoading from '../../../shared/appUIFramework/components/AppShowLoading';
import {
  EMAIL_PATTERN,
  NAME_MAX_LENGTH,
  NO_SPECIAL_CHARACTERS_PATTERN,
} from '../../../shared/appUIFramework/constants/constants';
import './AdminForm.scss';
import AdminFormSystemsSelect from './AdminFormSystemsSelect';
import AppTooltip, { AppTooltipPosition } from '../../../shared/appUIFramework/components/AppTooltip';
import { useAppForm } from '../../../shared/appUIFramework/appBackend/useAppForm';
import { getAdminUserFormSubmitHandler } from './AdminForm.methods';

export type AdminFormType = Omit<IProfile, 'systems'> & { systems?: Array<ISystemAdminInfo> }

export default function AdminForm({ adminId, disabled }: { adminId?: string, disabled: boolean }) {
  const { t } = useTranslation();
  const { admin, mutate } = useAdmin(adminId);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const {
    register, getFieldState,
    control,
    formState: {
      errors, isDirty, isValid: isFormValid, isSubmitting,
    },
    reset,
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useAppForm<AdminFormType>(admin, {
    mode: 'all',
  });
  const accessLevelTranslationValueMap = {
    [t(getUserRoleTranslationKey(AdminUserRole.PaymentAdmin))]: AdminUserRole.PaymentAdmin,
    [t(getUserRoleTranslationKey(AdminUserRole.SystemAdmin))]: AdminUserRole.SystemAdmin,
    [t(getUserRoleTranslationKey(AdminUserRole.SiteAdmin))]: AdminUserRole.SiteAdmin,
  };
  const adminSystems = getValues('systems') || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeSystem = (indexToRemove: number) => {
    const systemsWithoutRemovedSystem = adminSystems.filter((p, fIndex) => indexToRemove !== fIndex);
    setValue('systems', systemsWithoutRemovedSystem, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  const addSystem = (newSystem: ISystemAdminInfo) => {
    const systemWithNewEmptySystem = [...adminSystems, newSystem];
    setValue('systems', systemWithNewEmptySystem, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  const adminRole = watch('userRole');
  if (adminRole !== AdminUserRole.SiteAdmin) {
    setValue('systems', undefined);
  } else if (!adminSystems || adminSystems.length === 0) {
    setValue('systems', []);
  }

  const isNew = !adminId;
  const [, setLocation] = useLocation();

  const goToAdminPeople = () => setLocation('/admin/people');
  const company = useCompany();

  useUnsavedPopup('adminUnsavedPopup', isDirty);

  return (
    <div className="app-content">
      <AppShowLoading showLoading={isSubmitting || (!isNew && !admin)}>
        <form
          className="app-d-flex-vertical-100 app-form"
          onSubmit={appSubmitAndHandleErrors<AdminFormType>({
            submit: getAdminUserFormSubmitHandler({ isNew, goBack: goToAdminPeople }), handleSubmit, reset, goBack: goToAdminPeople, mutate,
          })}
        >
          <div
            className="app-form-section-title"
            aria-disabled={disabled}
          >
            {t('Profile_Details')}
          </div>
          <div className="app-d-flex app-flex-vertical app-mobile-form app-form-container app-flex-1" aria-disabled={disabled}>
            <div className="app-form-column app-form-column-left">
              <div
                className="app-form-control"
                aria-invalid={!!errors.firstName && getFieldState('firstName').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  <span>{t('SiteAdd_FirstName')}</span>
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <NameIcon />
                  </div>
                  <input
                    {...register('firstName', {
                      maxLength: NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      required: true,
                      disabled,
                    })}
                    type="text"
                    className="app-form-input"
                    placeholder={t('SiteAdd_FirstName')}
                  />
                </div>
                {errors.firstName && getFieldState('firstName').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.firstName.type === 'required' && t('Form_FieldRequired')}
                    {errors.firstName.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.firstName.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: NAME_MAX_LENGTH })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.lastName && getFieldState('lastName').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('SiteAdd_LastName')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <NameIcon />
                  </div>
                  <input
                    {...register('lastName', {
                      required: true,
                      maxLength: NAME_MAX_LENGTH,
                      pattern: NO_SPECIAL_CHARACTERS_PATTERN,
                      disabled,
                    })}
                    type="text"
                    className="app-form-input"
                    placeholder={t('SiteAdd_LastName')}
                  />
                </div>
                {errors.lastName && getFieldState('lastName').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.lastName.type === 'required' && t('Form_FieldRequired')}
                    {errors.lastName.type === 'pattern' && t('InputInvalidCharactersErrorMessage')}
                    {errors.lastName.type === 'maxLength' && t('InputMaxLengthErrorMessage', { maxLength: NAME_MAX_LENGTH })}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.email && getFieldState('email').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('SiteAdd_EmailAddress')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div className="app-form-control-input">
                  <div className="app-form-control-prefix">
                    <EmailIcon />
                  </div>
                  <input
                    {...register('email', {
                      required: true,
                      pattern: EMAIL_PATTERN,
                      disabled,
                      validate: {
                        unique: async (value: string) => {
                          if (!value || !company?.id) {
                            return true;
                          }

                          if (!value || !value.trim()) {
                            return t('Form_FieldRequired') as string;
                          }
                          setIsValidatingEmail(true);
                          const isUnique = await isAdminEmailUnique(value, adminId);
                          setIsValidatingEmail(false);
                          if (!isUnique) {
                            return t('EmailShouldBeUniquePerSite') as string;
                          }

                          return true;
                        },
                      },
                    })}
                    type="text"
                    disabled={!isNew}
                    className="app-form-input"
                    placeholder={t('SiteAdd_EmailAddress')}
                  />
                </div>
                {errors.email && getFieldState('email').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.email.type === 'required' && t('Form_FieldRequired')}
                    {errors.email.type === 'pattern' && t('SiteAdd_EmailInvalid')}
                    {errors.email.type === 'unique' && t('EmailAlreadyExists')}
                  </div>
                )}
              </div>
              <div
                className="app-form-control"
                aria-invalid={!!errors.userRole && getFieldState('userRole').isTouched}
              >
                <div className="app-form-control-label app-form-control-label-with-prefix">
                  {t('Profile_AccessLevel')}
                  <span className="app-text-secondary-red-color">&nbsp;*</span>
                </div>
                <div
                  className="app-form-control-input"
                >
                  <div className="app-form-control-prefix">
                    <img
                      src={AccessIcon}
                      alt="form icon"
                    />
                  </div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="userRole"
                    render={({ field: { onChange, onBlur } }) => {
                      const canEditLowerRoles = hasAnyOfPermissions(UserPermission.CanCreateAdminsOfLowerRoles, UserPermission.CanDeleteAdminsOfLowerRoles);
                      const canEditAnyRoles = hasAnyOfPermissions(UserPermission.CanCreateAdmins, UserPermission.CanEditAdmins);
                      let allowedRolesEntries = Object
                        .entries(accessLevelTranslationValueMap);
                      if (canEditLowerRoles && !canEditAnyRoles) {
                        allowedRolesEntries = allowedRolesEntries
                          .filter(([, value]) => value !== AdminUserRole.PaymentAdmin);
                      }
                      const allowedRoles = allowedRolesEntries.map(([key]) => key);
                      return (
                        <AppSelect
                          disabled={disabled}
                          className="app-form-input"
                          readOnly
                          placeholder={t('SelectLevel')}
                          onBlur={() => { onBlur(); }}
                          incomingValue={t(getUserRoleTranslationKey(adminRole))}
                          options={allowedRoles.map((role) => ({ label: role, value: role }))}
                          onOptionSelected={(accessLevelTranslationKey) => {
                            onChange(accessLevelTranslationValueMap[accessLevelTranslationKey.value]);
                          }}
                        />
                      );
                    }}
                  />
                  <AppTooltip
                    className="app-ml-18 app-post-input-content"
                    position={AppTooltipPosition.Bottom}
                  >
                    <div
                      className="app-font-20 app-weight-600 app-color-primary-green app-whitespace-no-wrap"
                    >
                      {t('Tooltip_AccessLevel_Title')}
                    </div>
                    <div className="app-mt-28">
                      <span
                        className="app-tooltip-bold-heading"
                      >
                        {t('Tooltip_AccessLevel_PaymentAdmin_Heading')}
                      </span>
                      <br />
                      <span>{t('Tooltip_AccessLevel_PaymentAdmin_Body')}</span>
                    </div>
                    <div className="app-mt-28">
                      <span
                        className="app-tooltip-bold-heading"
                      >
                        {t('Tooltip_AccessLevel_SystemAdmin_Heading')}
                      </span>
                      <br />
                      <span>{t('Tooltip_AccessLevel_SystemAdmin_Body')}</span>
                    </div>
                    <div className="app-mt-28 app-pb-33">
                      <span
                        className="app-tooltip-bold-heading"
                      >
                        {t('Tooltip_AccessLevel_SiteAdmin_Heading')}
                      </span>
                      <br />
                      <span>{t('Tooltip_AccessLevel_SiteAdmin_Body')}</span>
                    </div>
                  </AppTooltip>
                </div>
                {!!errors.userRole && getFieldState('userRole').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {errors.userRole?.type === 'required' && t('Form_FieldRequired')}
                    {errors.userRole?.type === 'invalidRoleChange' && t('HAHHHHHHHHHHHH')}
                  </div>
                )}
              </div>
            </div>
            <div className="app-form-column app-form-column-right">
              {adminRole === AdminUserRole.SiteAdmin && (
              <>
                <div className="app-form-control">
                  <div className="app-form-control-label app-form-control-label-with-prefix">
                    {t('Systems')}
                    <span className="app-text-secondary-red-color">&nbsp;*</span>
                  </div>
                  <AdminFormSystemsSelect
                    disabled={disabled}
                    addedSystemsIds={adminSystems.map((p) => p.systemId)}
                    addSystem={addSystem}
                  />
                  {!!errors.systems && getFieldState('systems').isTouched && (
                  <div className="app-form-error app-form-error-with-prefix">
                    {t('AtLeastOneSystemShouldBeAdded')}
                  </div>
                  )}
                </div>
                <Controller
                  control={control}
                  render={() => (
                    <div className="app-gap-23 app-d-flex app-flex-column app-admin-form-site-list">
                      {adminSystems.map((system, index) => (
                        <div
                          key={system.systemName}
                          className="app-admin-form-site-item"
                        >
                          <span>{system.systemName}</span>
                          <CrossIcon onClick={() => removeSystem(index)} />
                        </div>
                      ))}
                    </div>
                  )}
                  rules={{
                    validate: (systems) => systems && systems.length > 0,
                  }}
                  name="systems"
                />
              </>
              )}
            </div>
          </div>
          <div className="app-d-flex app-justify-content-end app-form-bottom-action-bar">
            <button
              onClick={goToAdminPeople}
              className="app-secondary-button app-mr-9"
              type="button"
            >
              {t('Form_Cancel')}
            </button>
            <button
              disabled={!isFormValid || !isDirty || isValidatingEmail}
              className="app-primary-button"
              type="submit"
            >
              {t('Form_Save')}
            </button>
          </div>
        </form>
      </AppShowLoading>
    </div>
  );
}
