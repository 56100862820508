import { KeyedMutator, useSWRConfig } from 'swr';
import { ISite, ISiteInfo } from '../../../routes/systems/models/ISiteInfo';
import { IGridQueryParams } from '../../../routes/systems/models/IGridQueryParams';
import {
  getUrlWithQueryParams, httpDelete, httpGetJson, httpPostJson, httpPut,
} from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { useCompany } from './useCompany';
import { wsGet } from '../../backend/websockets/websockets';

function getBodyFromSiteInfo(siteInfo: ISiteInfo, companyId: string) {
  return {
    ...siteInfo,
    companyId,
    siteContact: {
      phoneNumber: siteInfo.siteContact?.phoneNumber || '',
      email: siteInfo.siteContact?.email || '',
      diallingCode: siteInfo.siteContact?.diallingCode || '',
      details: siteInfo.siteContact?.details || '',
      phoneNumber2: siteInfo.siteContact?.phoneNumber2 || '',
      diallingCodeCountryCode: !!siteInfo?.siteContact?.phoneNumber
        && !!siteInfo?.siteContact?.diallingCode
        ? siteInfo.siteContact?.diallingCodeCountryCode
        : '',
    },
  };
}

export async function updateSite(site: ISiteInfo, companyId?: string): Promise<void> {
  if (!companyId) {
    return;
  }
  await httpPut(Urls.Sites, getBodyFromSiteInfo(site, companyId));
}

export async function createSite(site: ISiteInfo, companyId?: string): Promise<{ entityId: string } | undefined> {
  if (!companyId) {
    return undefined;
  }

  return httpPostJson<{ entityId: string }>(Urls.Sites, getBodyFromSiteInfo(site, companyId));
}

export function deleteSite(siteId: string, companyId?: string): Promise<any> {
  if (!companyId) {
    throw new TypeError('companyId is required');
  }

  const url = Urls.Site(siteId, companyId);
  if (url) {
    return wsGet(
      async () => { await httpDelete(url); },
      async () => {
        const { uri } = await httpGetJson(Urls.SitesNegotiate);

        return uri;
      },
    );
  }
  return Promise.resolve(undefined);
}

export async function isSiteNameUnique(siteName: string, companyId: string, siteId?: string): Promise<boolean> {
  const url = Urls.IsSiteNameUnique(companyId, siteName, siteId);
  return httpGetJson<boolean>(url!);
}

export function getSites(companyId: string, queryParams: IGridQueryParams): Promise<ISite[]> {
  const url = getUrlWithQueryParams(Urls.SitesWithCompanyId(companyId)!, queryParams);
  return httpGetJson(url);
}

export function useSites(queryParams: IGridQueryParams): { sites: ISite[] | undefined, mutate: KeyedMutator<ISite[]>, clearCache: () => void } {
  const company = useCompany();
  const { cache } = useSWRConfig();
  const url = Urls.SitesWithCompanyId(company?.id)
    ? getUrlWithQueryParams(Urls.SitesWithCompanyId(company?.id)!, queryParams)
    : undefined;
  const { data, mutate } = useSWRAndHandleErrors<ISite[]>(url, httpGetJson);

  return { sites: data, mutate, clearCache: () => cache.delete(url) };
}

export function useSiteSubset(): ISite[] {
  // TODO: implement later if needed
  return [];
}
