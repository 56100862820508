import { useTranslation } from 'react-i18next';
import styles from '../../../routes/payment/PaymentOverview.module.scss';
import CostsIcon from '../../../assets/icons/Homescreen icons/Entry user app icon.svg';
import { formatMoney } from '../formatters/formatMoney';
import { CurrencyCodeString } from '../appBackend/useInvoices';
import { TypeInstallerStatusTranslationKey } from '../../../routes/admin/AdminCompanyDetails';

export default function VatRegisteredResellerCosts({
  costs,
  currencyCode,
  rrp,
  discountPercent,
  partnerTranslationKey: partner,
}
  : {
    costs: number,
    currencyCode?: CurrencyCodeString,
    rrp: number,
    discountPercent: number,
    partnerTranslationKey: TypeInstallerStatusTranslationKey
  }) {
  const { t } = useTranslation();

  return (
    <div className="app-h-100pcnt app-min-w-200">
      <div className="app-paper-title">
        {t('ViewSiteCosts_Title')}
      </div>
      <div className="app-paper-body app-h-100pcnt">
        <div className={styles.costsContainer}>
          <div className="app-position-relative">
            <img src={CostsIcon} alt="costs icon" />
            <div className={styles.costsTextAmount}>{currencyCode ? formatMoney(currencyCode, costs) : costs}</div>
            <span className={styles.vatRegisterCostsHighlight}>
              {t('RRP')}
              &nbsp;
              {currencyCode ? formatMoney(currencyCode, rrp) : rrp}
            </span>
          </div>
          <div className="app-paper-footer app-pt-20">
            <span className="app-paper-footer-text app-weight-600 app-uppercase">
              {t('PerAppUser')}
            </span>
            <span className={styles.vatRegisterCostsHighlight}>
              {t('PartnerDiscount', {
                percent: discountPercent,
                partner: t(partner),
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
