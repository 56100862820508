import { useTranslation } from 'react-i18next';
import { ReactComponent as PlainIcon } from '../../../assets/EmailSent.svg';
import { resetUnsavedChanges } from '../../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import { B2CLangCode } from '../../../shared/appUIFramework/constants/B2CLangCode';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { goToLoginPage } from '../../../shared/backend/auth/auth';
import './SignUpVatInstallerSuccessPopup.scss';
import { SignUpVatInstallerUnsavedChangesKey } from '../hooks/useCancelVatInstallerSignUp';

export default function SignUpVatInstallerSuccessPopup() {
  const { t, i18n } = useTranslation();
  const goToLogin = () => {
    resetUnsavedChanges(SignUpVatInstallerUnsavedChangesKey);
    setTimeout(() => {
      goToLoginPage(i18n.language as B2CLangCode, true);
    }, 0);
  };

  return (
    <AppPopupLayout
      extraClass="app-popup-without-title"
      popupBody={(
        <div className="app-d-flex app-flex-column app-align-items-center app-justify-content-center app-signup-success-popup">
          <PlainIcon />
          <span>{t('ThankYouForSignInUp')}</span>
        </div>
  )}
      buttons={(
        <>
          <button onClick={goToLogin} type="button" className="app-popup-right-button app-primary-button">{t('BackToLogin')}</button>
        </>
                    )}
    />
  );
}
