import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { IAppNotification } from '../../appBackend/useAppNotifications';
import BaseNotificationContent from './BaseNotificationContent';

export default function OverdueInvoicesContent({ notification }:{notification: IAppNotification}): ReactElement {
  const { t } = useTranslation();
  const { invoiceNumber } = JSON.parse(notification.jsonPayload) as { invoiceNumber: string };
  return (
    <BaseNotificationContent
      title={t('Toolbar_OverdueInvoice')}
      description={t('YourInvoiceIsNowOverdue')}
      linkPrefix={t('NoDotInvoice', { invoiceNumber })}
      linkText={t('PayInvoice')}
      createdAt={notification.createdAt}
    />
  );
}
