import { initPermissions } from '../../appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../appUIFramework/appPermisions/userPermission';
import { IAutoPaymentEventData } from '../../appUIFramework/appBackend/useWorldpay';
import { showPopup } from '../../appUIFramework/popup/AppPopup';
import { handleInvoiceOneTimePaymentSuccess } from '../../appUIFramework/appBackend/useInvoices';
import OneTimePaymentPopup from '../../../routes/payment/components/OneTimePaymentPopup';
import ProcessingManualPaymentPopup from '../../../routes/payment/components/ProcessingManualPaymentPopup';
import { AdminUserRole } from '../../appUIFramework/appBackend/useProfileDetails';

export enum AccountStatus {
    Ok = 'Active',
    Limited = 'Overdue',
    Blocked = 'Blocked',
    Unknown = 'Unknown'
}
export interface IApplicationClaims {
    companyId: string;
    customerReference: string;
    permissions: UserPermission[];
    branchOfficeId: string;
    locale: string;
    userId: string;
    userRole: AdminUserRole;
    siteIds: string[];
    accountStatus: AccountStatus;
}

let applicationClaims: IApplicationClaims | undefined;

export function setClaims(claims: IApplicationClaims) {
  applicationClaims = claims;
  initPermissions(claims.permissions, claims.siteIds);
}

export function useClaims(): IApplicationClaims {
  return applicationClaims!;
}

export function getCompanyIdFromClaims() {
  return applicationClaims!.companyId;
}

export function getUserIdFromClaims() {
  return applicationClaims?.userId;
}

export async function Pay(invoiceId: number) {
  const setupResponse: boolean | IAutoPaymentEventData = await showPopup(<OneTimePaymentPopup invoiceId={invoiceId} />);
  if (setupResponse && typeof setupResponse === 'object') {
    await handleInvoiceOneTimePaymentSuccess(invoiceId, applicationClaims!.customerReference);
    await showPopup(<ProcessingManualPaymentPopup />);
    return true;
  }
  return false;
}
